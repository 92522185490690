import React from 'react'
import PropTypes from 'prop-types'
import { Api } from '../Api/Api'
import { ModalActions } from '../Utility/Modal/Modal'

const CancelSettlement = (props) => {
    return <div>
        <p>Are you sure you want to cancel this settlement? Please ensure you have not
            sent remittance for this settlement.</p>
        <button className="btn btn-danger" onClick={() => {
            Api.delete('/agency/settlement/' + props.settlement.id).then((response) => {
                ModalActions.hideModal()
                document.location.href = '/settlements'
            })
        }} style={{ width: '100%' }}>Yes i'm sure
        </button>
    </div>
}

CancelSettlement.propTypes = {
    settlement: PropTypes.object.isRequired,
}
export default CancelSettlement
import React, {Component} from 'react';
import withAuth from "../../Components/Higher/Authentication/Authentication";
import FormFilter from "../Form/Filter";
import _ from 'lodash';
import Pagination from "../Utility/Pagination/Pagination";
import {Api, CurrencyFormatter} from "../Api/Api";
import {ModalActions} from "../Utility/Modal/Modal";
import PayInvoice from "./PayInvoice";
import Loading from "../Utility/Loading/Loading";
import {EventDispatcher} from "../../Events/Dispatcher";
import LayoutWrapper from "../../Utility/LayoutWrapper";
import SettlementRow from "./SettlementRow";

class Settlements extends Component {
    constructor(props) {
        super(props);
        this.state = {
            settlements: [],
            initial_load: false,
            filter: {
                search: '',
                start_date: '',
                end_date: '',
                page: 1,
                status: '1'
            },
            pagination: {},
            outstanding: {},
            statuses: []
        };
    }

    componentDidMount() {
        this.updateResults();
        this.update_listener = EventDispatcher.subscribe('reload-invoices', () => this.updateResults());
    }

    componentWillUnmount() {
        EventDispatcher.unsubscribe(this.update_listener);
    }

    openPaymentModal() {
        ModalActions.openModal('Create Settlement', <PayInvoice/>);
    }

    handleFilterChange(filter) {
        this.setState({filter: filter}, this.updateResults);
    }

    updateResults() {
        this.setState({loading: true});
        Api.get('/agency/settlements', this.state.filter).then((response) => {
            if (response.ok) {
                this.setState({
                    loading: false,
                    settlements: response.data.data,
                    pagination: response.data.meta.pagination,
                    outstanding: response.data.meta.outstanding,
                    statuses: response.data.meta.statuses,
                    initial_load: true
                });
            }
        });
    }

    handlePageChange(page) {
        let filter = _.clone(this.state.filter);
        filter.page = page;
        this.handleFilterChange(filter);
    }

    render() {
        if (this.state.initial_load === false) {
            return <Loading/>
        }
        let filters = [
            {
                name: 'search',
                label: 'Search',
                value: this.state.search,
                type: 'text'
            },
            {
                name: 'start_date',
                type: 'date',
                value: this.state.filter.start_date,
                label: 'Start Date'
            },
            {
                name: 'end_date',
                type: 'date',
                value: this.state.filter.end_date,
                label: 'End Date'
            },
            {
                name: 'status',
                type: 'select',
                values: this.state.statuses,
                value: this.state.filter.status,
                include_blank_option: true,
                label: 'Status'
            }
        ];
        return <LayoutWrapper {...this.props} title="Settlements">
            <FormFilter filters={filters} filter={this.state.filter} handleChange={(f) => this.handleFilterChange(f)}/>
            <div className="alert alert-warning">
                There are currently <strong>{this.state.outstanding.invoices}</strong> invoices
                across <strong>{this.state.outstanding.bookings}</strong> bookings requiring payment
                totalling <strong>{CurrencyFormatter.format(this.state.outstanding.balance)}</strong>. <button
                className="btn btn-success" onClick={(e) => this.openPaymentModal(e)}> Create Settlement Reference
                Now</button>
            </div>
            <Pagination pagination={this.state.pagination} changePage={(p) => this.handlePageChange(p)}/>
            <table className="table table-bordered table-striped">
                <thead>
                <tr>
                    <th>Ref</th>
                    <th>Date Created</th>
                    <th>Total Due</th>
                    <th>Total Paid</th>
                    <th>Invoices</th>
                    <th>Status</th>
                    <th/>
                </tr>
                </thead>
                <tbody>
                {this.state.settlements.length === 0 ? <tr>
                    <td colSpan={7} className="text-center">No Settlements Found</td>
                </tr> : null}
                {this.state.settlements.map((settlement) => {
                    return <SettlementRow settlement={settlement} key={settlement.id}/>
                })}
                </tbody>
            </table>
            <Pagination pagination={this.state.pagination} changePage={(p) => this.handlePageChange(p)}/>
        </LayoutWrapper>
    }
}

export default withAuth(Settlements);
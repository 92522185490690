import React, {Component} from 'react';
import moment from 'moment'
import _ from 'lodash';

import {Api, CurrencyFormatter} from "../Api/Api";
import Loading from "../Utility/Loading/Loading";
import {EventDispatcher} from "../../Events/Dispatcher";


class PayInvoice extends Component {
    constructor(props) {
        super(props);
        this.state = {
            available_invoices: [],
            chosen_invoices: [],
            payment: false,
            loading: true,
            total: 0,
            count: 0,
            settlement: {}
        }
    }

    componentDidMount() {
        this.updateInvoices();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevState.chosen_invoices.length !== this.state.chosen_invoices.length) {
            if (this.state.chosen_invoices.length === this.state.available_invoices.length || this.state.chosen_invoices.length === 0) {
                document.getElementById('all_checkbox').indeterminate = false;
            } else {
                document.getElementById('all_checkbox').indeterminate = true;
            }
        }
    }

    updateInvoices() {
        let params = {
            per_page: 99999,
            page: 1,
            included_in_settlement: 0,
            'status[0]': '0',
            'status[1]': '1',
            'status[2]': '3',
        }
        Api.get('agency/invoices', params).then((response) => {
            if (response.ok) {
                this.setState({available_invoices: response.data.data, loading: false});
            }
        });
    }

    checkItem(index) {
        let chosen = _.clone(this.state.chosen_invoices);
        let cindex = chosen.indexOf(index);
        if (cindex > -1) {
            chosen.splice(cindex, 1);
        } else {
            chosen.push(index);
        }
        this.setState({chosen_invoices: chosen}, this.calcTotals);
    }

    calcTotals() {
        let total = 0;
        let count = 0;
        this.state.chosen_invoices.map((i) => {
            let invoice = this.getInvoiceById(i);
            if (invoice) {
                total = total + invoice.balance_due;
                count = count + 1;
                return invoice;
            }
            return null;
        });
        this.setState({total: total, count: count});
    }


    getInvoiceById(id) {
        return _.find(this.state.available_invoices, (i) => {
            return i.invoice_id === id;
        });
    }

    handleSelectAll(e) {
        if (this.state.chosen_invoices.length === this.state.available_invoices.length) {
            this.setState({chosen_invoices: []}, this.calcTotals);
            return;
        }

        this.setState({
            chosen_invoices: this.state.available_invoices.map((invoice) => {
                return invoice.invoice_id;
            })
        }, this.calcTotals);

    }

    handleCreate(e) {
        if (this.state.chosen_invoices.length === 0) {
            Api.showError('Error', 'At least 1 invoice must be selected');
            return;
        }
        Api.post('/agency/settlement', {invoices: this.state.chosen_invoices}).then((response) => {
            if (response.ok) {
                EventDispatcher.dispatch('reload-invoices');
                this.setState({settlement: response.data.data});
            }
        });
    }

    render() {
        if (this.state.loading === true) {
            return <Loading/>
        }
        if (this.state.settlement.id) {
            return <div>
                <div className="alert alert-success">Your payment reference
                    is <strong>{this.state.settlement.reference}</strong>. Please
                    transfer {Api.currencyFormatter.format(this.state.settlement.total)} to 01-00-39 12019909 (Inventive
                    Service Company).
                    <a href="#download">Click here</a> to download your consolidated invoice for these bookings.
                </div>
            </div>
        }
        return <div>
            <div className="holder-div">
                {this.state.count > 0 ?
                    <button className="btn btn-success" style={{marginBottom: '10px'}}
                            disabled={this.state.chosen_invoices.length === 0}
                            onClick={(e) => this.handleCreate(e)}><i
                        className="fa fa-money"/> Create settlement
                        for {this.state.count} invoice{this.state.count === 1 ? '' : 's'} totalling {CurrencyFormatter.format(this.state.total)}
                    </button> : null}
            </div>
            <table className="table table-bordered table-striped">
                <thead>
                <tr>
                    <th>
                        <input type="checkbox" id="all_checkbox"
                               checked={this.state.chosen_invoices.length === this.state.available_invoices.length}
                               onChange={(e) => this.handleSelectAll(e)}/>
                    </th>
                    <th>Due Date</th>
                    <th>Invoice Ref</th>
                    <th>Booking Reference</th>
                    <th>Lead Booker Name</th>
                    <th>Invoice Amount</th>
                    <th>Status</th>
                </tr>
                </thead>
                <tbody>
                {this.state.available_invoices.map(function (invoice) {
                    return <tr key={invoice.invoice_id} onClick={(e) => this.checkItem(invoice.invoice_id)}>
                        <td><input type="checkbox" checked={this.state.chosen_invoices.indexOf(invoice.invoice_id) > -1}
                                   onChange={() => this.checkItem(invoice.invoice_id)}/></td>
                        <td>{moment(invoice.due_date).format('DD/MM/YYYY')} {moment(invoice.due_date).fromNow()}</td>
                        <td>{invoice.invoice_ref}</td>
                        <td>{invoice.booking_ref}</td>
                        <td>{invoice.forename} {invoice.surname}</td>
                        <td>{CurrencyFormatter.format(invoice.balance)}</td>
                        <td>{invoice.status_name}</td>
                    </tr>
                }.bind(this))}
                </tbody>
            </table>
            <div className="holder-div">
                {this.state.count > 0 ?
                    <button className="btn btn-success" style={{marginBottom: '10px'}}
                            disabled={this.state.chosen_invoices.length === 0}
                            onClick={(e) => this.handleCreate(e)}><i
                        className="fa fa-money"/> Create settlement
                        for {this.state.count} invoice{this.state.count === 1 ? '' : 's'} totalling {CurrencyFormatter.format(this.state.total)}
                    </button> : null}
            </div>
        </div>
    }
}

PayInvoice.propTypes = {};

export default PayInvoice;
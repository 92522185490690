import 'intl';
import {store} from 'react-notifications-component';
import {EventDispatcher} from '../../Events/Dispatcher';
import moment from 'moment'

export const Api = {
  setAuthToken(token) {
    this.auth_token = token;
  },
  getHeaders() {
    let headers = {
      ApiKey: 'MDg5ZjM0NWZkZmExMjg2ZmI2N2YyYjUzNGYxZWExNzg4NWQzYTFiNWRkY2RhNzgwMzE0ZWM0NzNjZTUzZTFmNQ==',
    };
    if (this.auth_token) {
      headers.AgencyUserToken = this.auth_token;
    }
    return headers;
  },
  async get(url, params) {
    var endpoint = url;
    if (process.env.REACT_APP_API_ENDPOINT) {
      endpoint = `${process.env.REACT_APP_API_ENDPOINT}/${url}`;
    }
    if (params) {
      endpoint = endpoint + '?' + this.serialize(params);
    }
    var request = new Request(endpoint, {
      headers: new Headers(this.getHeaders()),
    });
    return await fetch(request).then(this.handleResponse);
  },
  generateCacheKey: function (url, parameters) {
    return this.hashCode(url).toString() + this.hashCode(JSON.stringify(parameters)).toString();
  },
  clearCache: function (cacheKey) {
    this.localStorage.remove(cacheKey);
  },
  getCached(url, parameters, callback) {
    var cacheKey = this.generateCacheKey(url, parameters);
    var item = this.localStorage.load(cacheKey);
    if (item) {
      if (moment(item.expires).isBefore(moment())) {
        this.clearCache(cacheKey);
        item = null;
      } else {
        return callback(item.data);
      }
    }
    var keyLength = item ? item.data.length : 0;
    if (typeof item === 'object' && item !== null) {
      keyLength = Object.keys(item.data).length;
    }
    if (!item || !keyLength) {
      this.get(url, parameters).then((response) => {
        if (response.ok) {
          var cacheObject = {
            expires: moment().add(1800, 'seconds').format(),
            data: response
          };
          this.localStorage.save(cacheKey, cacheObject);
          return callback(response);
        }
      });
    }
  },
  async delete(url) {
    var endpoint = url;
    if (process.env.REACT_APP_API_ENDPOINT) {
      endpoint = `${process.env.REACT_APP_API_ENDPOINT}/${url}`;
    }
    var request = new Request(endpoint, {
      method: 'DELETE',
      headers: new Headers(this.getHeaders()),
    });
    return await fetch(request).then(this.handleResponse);
  },

  async handleResponse(r) {
    let json = await r.json();
    let error = null;
    if (json.errors && json.errors.message) {
      error = json.errors.message;
    }
    if (r.status === 401) {
      EventDispatcher.dispatch('logout');
    } else {
      if (r.status >= 400 && r.status < 600 && error) {
        Api.showError('Error', error);
      }
    }
    return {
      ok: r.ok,
      data: json,
      error: error,
      status: r.status,
      statusText: r.statusText,
    };
  },

  async getAbsolute(endpoint, params) {
    if (params) {
      endpoint = endpoint + '?' + this.serialize(params);
    }
    var request = new Request(endpoint, {
      headers: new Headers(this.getHeaders()),
    });
    return await fetch(request).then(this.handleResponse);
  },

  async post(url, params) {
    var endpoint = url;
    if (process.env.REACT_APP_API_ENDPOINT) {
      endpoint = process.env.REACT_APP_API_ENDPOINT + url;
    }
    let headers = this.getHeaders();
    headers['Content-Type'] = 'application/x-www-form-urlencoded';
    return await fetch(endpoint, {
      headers: headers,
      method: 'POST',
      body: this.serialize(params),
    }).then(this.handleResponse);
  },
  async postJson(url, params) {
    var endpoint = url;
    if (process.env.REACT_APP_API_ENDPOINT) {
      endpoint = process.env.REACT_APP_API_ENDPOINT + url;
    }
    let headers = this.getHeaders();
    headers['Content-Type'] = 'application/json';
    return await fetch(endpoint, {
      headers: headers,
      method: 'POST',
      body: JSON.stringify(params),
    }).then(this.handleResponse);
  },

  currencyFormatter: new Intl.NumberFormat('en-GB', {
    style: 'currency',
    currency: 'GBP',
    minimumFractionDigits: 2,
  }),

  serialize(obj) {
    var str = [];
    for (var p in obj)
      if (obj.hasOwnProperty(p)) {
        str.push(encodeURIComponent(p) + '=' + encodeURIComponent(obj[p]));
      }
    return str.join('&');
  },

  showNotification(title, message) {
    store.addNotification({
      title: title,
      message: message,
      type: 'success',
      insert: 'top',
      container: 'top-right',
      dismiss: {
        duration: 5000,
        onScreen: true,
      },
    });
  },

  showError(title, message) {
    store.addNotification({
      title: title,
      message: message,
      type: 'danger',
      insert: 'top',
      container: 'top-right',
      dismiss: {
        duration: 5000,
        onScreen: true,
      },
    });
  },

  nl2br(str) {
    var newlineRegex = /(\r\n|\n\r|\r|\n)/g;
    return str.replaceAll(newlineRegex, '<br />');
  },

  hashCode(string) {
    var hash = 0, i, chr;
    if (string.length === 0) return hash;
    for (i = 0; i < string.length; i++) {
      chr   = string.charCodeAt(i);
      hash  = ((hash << 5) - hash) + chr;
      hash |= 0;
    }
    return hash;
  },

  localStorage: {
    remove: function (key) {
      if (this.isLocalStorageSupported() === false) {
        return null;
      }
      localStorage.removeItem(key);
    },
    save: function (key, value) {
      if (this.isLocalStorageSupported() === false) {
        return null;
      }
      localStorage.setItem(key, JSON.stringify(value));
    },
    load: function (key, defaultValue) {
      if (this.isLocalStorageSupported() === false) {
        return null;
      }
      var value = localStorage.getItem(key);
      if (!defaultValue) {
        defaultValue = null;
      }
      if (value == null || value == 'null') {
        return defaultValue;
      }
      return JSON.parse(value);
    },
    clear: function () {
      if (this.isLocalStorageSupported() === true) {
        localStorage.clear();
      }
    },
    garbageCollection: function () {
      if (this.isLocalStorageSupported()) {
        for (var x in localStorage) {
          if (typeof localStorage[x] === 'string') {
            try {
              var item = JSON.parse(localStorage[x]);
              if (item && moment(item.expires).isBefore(moment())) {
                this.remove(x);
              }
            } catch (e) {
              this.remove(x);
            }
          }
        }
      }
    },
    isLocalStorageSupported: function () {
      var testKey = 'test', storage = localStorage;
      try {
        storage.setItem(testKey, '1');
        storage.removeItem(testKey);
        return true;
      } catch (error) {
        return false;
      }
    }
  },

  slug: function (string) {
    return string.toLowerCase().replace(' - ', ' ').replace(/ /g,'-').replace(/[^\w-]+/g,'');
  }
};

export const CurrencyFormatter = new Intl.NumberFormat('en-GB', {
  style: 'currency',
  currency: 'GBP',
  minimumFractionDigits: 2,
});
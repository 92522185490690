import React from 'react';
import FormElementSelect from "./Select";

export default function (props) {
    let times = [];
    ['09', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '00', '01', '02', '03'].map(function (hour) {
        ['00', '15', '30', '45'].map(function (minute) {
            times.push({key: hour + ':' + minute + ':00', value: hour + ':' + minute});
            return minute;
        });
        return hour;
    });
    return <FormElementSelect {...props} values={times} useSelect2={false} includeBlankOption={true}
                              selectedValue={props.value}/>
};

import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import {Integrations} from "@sentry/tracing";
import * as Sentry from "@sentry/react";

Sentry.init({
    dsn: "https://04c1bdd01a4b46609c09f4a620b62c88@sentry-relay.revbars.cloud/7",
    integrations: [new Integrations.BrowserTracing()],
    environment: `${process.env.REACT_ENVIRONMENT}`,
    tracesSampleRate: 0.2,
});

ReactDOM.render(<App/>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

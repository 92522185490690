import React, {Component} from 'react';
import DatePicker, {registerLocale} from 'react-datepicker';
import gb from 'date-fns/locale/en-GB';
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';

registerLocale('en-GB', gb)

class FormElementDatePicker extends Component {
    handleChange(e) {
        this.props.onChange({
            target: {
                name: this.props.name,
                value: e,
                append_time: this.props.append_time
            }
        })
    }

    render() {
        return <div><DatePicker onChange={e => this.handleChange(e)} placeholderText={this.props.placeholder}
                                selected={this.props.value ? moment(this.props.value).toDate() : null}
                                maxDate={this.props.maxDate}
                                locale="en-GB" dateFormat="dd/MM/yyyy" className="form-control"/>
        </div>
    }
}

export default FormElementDatePicker;

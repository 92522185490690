import React, {Component} from 'react';
import FormRow from "../Form/Row";
import FormElementTextArea from "../Form/Element/TextArea";
import {Api} from "../Api/Api";
import {ModalActions} from "../Utility/Modal/Modal";
import {EventDispatcher} from "../../Events/Dispatcher";
import InvoiceQueryRow from "./InvoiceQueryRow";

class QueryInvoice extends Component {
    constructor(props) {
        super(props);
        this.state = {
            query: '',
            show_previous: false
        }
    }

    handleSave(e) {
        Api.post('/agency/query', {
            invoice_id: this.props.invoice.invoice_id,
            query: this.state.query
        }).then((response) => {
            if (response.ok) {
                EventDispatcher.dispatch('reload-invoice', this.props.invoice.invoice_id);
                ModalActions.hideModal();
            }
        });
    }

    render() {
        let queries = this.props.invoice.queries.data;
        if (this.state.show_previous === false) {
            queries = queries.filter((q) => {
                return q.resolved === false;
            })
        }
        let has_active = false;
        queries.map((q) => {
            if (q.resolved === false) {
                has_active = true;
            }
        });
        return <div>
            <button className={this.state.show_previous ? 'btn btn-success' : 'btn btn-warning'}
                    onClick={(e) => this.setState({show_previous: !this.state.show_previous})}>
                {this.state.show_previous ? 'Show Only Active' : 'Show All Queries'}
            </button>
            {queries.length > 0 ? <div>
                <table className="table table-bordered table-striped">
                    <thead>
                    <tr>
                        <th>Date</th>
                        <th>Created By</th>
                        <th>Query</th>
                        <th>Resolved</th>
                        <th/>
                    </tr>
                    </thead>
                    <tbody>
                    {queries.map(function (query) {
                        return <InvoiceQueryRow query={query} key={query.id} open={has_active}/>
                    })}
                    </tbody>
                </table>
            </div> : null}

            {!has_active?<><FormRow label="New Query">
                <FormElementTextArea name="query" onChange={(e) => this.setState({query: e.target.value})}
                                     value={this.state.query}/>
            </FormRow>
            <button className="btn btn-success" onClick={(e) => this.handleSave(e)}><i className="fa fa-send"/> Send
                Invoice Query
            </button></>:null}
        </div>
    }
}

export default QueryInvoice;

import React, {useCallback, useContext, useEffect, useRef, useState} from 'react';
import Loading from "../../Components/Utility/Loading/Loading";
import {Api} from "../../Components/Api/Api";
import {NewBookingContext} from "./Context/NewBookingContext";
import Step1 from "./Steps/Step1";
import {EventDispatcher} from "../../Events/Dispatcher";
import Step2 from "./Steps/Step2";
import Step3 from "./Steps/Step3";

const AddBooking = (props) => {
    const [loading, setLoading] = useState(true);
    const [booking, setBooking] = useState({});
    const [state, dispatch] = useContext(NewBookingContext);
    const [sessionId, setSessionId] = useState(null);

    const bookingState = useRef(state);
    bookingState.current = state;
    const updateBooking = useCallback((data) => {
        let postData = JSON.parse(JSON.stringify(data));
        postData.session_id = bookingState.current.booking.session_id;
        dispatch({
            type: 'set_loading',
            payload: true
        });
        Api.postJson('/rest/agency/booking-session', postData).then((response) => {
            if (response.ok) {
                if (response.data.data.booking_id) {
                    document.location.href = '/bookings/' + response.data.data.booking_id;
                } else {
                    dispatch({
                        type: 'set_booking',
                        payload: response.data.data
                    });
                    setBooking(response.data.data);
                }
            }
        });
    }, [bookingState]);

    useEffect(() => {
        Api.postJson('/rest/agency/booking-session', {}).then((response) => {
            if (response.ok) {
                if (response.data.data.booking_id) {
                    document.location.href = '/bookings/' + response.data.data.booking_id;
                } else {
                    dispatch({
                        type: 'set_booking',
                        payload: response.data.data
                    })
                    setBooking(response.data.data);
                    setSessionId(response.data.data.session_id);
                    setLoading(false);
                }
            }
        });
        EventDispatcher.subscribe('update-booking', (data) => updateBooking(data))
    }, [updateBooking])
    if (loading) {
        return <Loading/>

    }
    return <>
        {booking.step === 1 ? <Step1/> : null}
        {booking.step === 2 ? <Step2/> : null}
        {booking.step === 3 ? <Step3/> : null}
    </>
}

export default AddBooking;
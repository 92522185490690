import React, {Component} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Select from 'react-select';

class FormElementSelect extends Component {
    static defaultProps = {
        blankOptionText: ' - Select -',
        updateFromProps: false,
        tags: false
    }

    constructor(props) {
        super(props);
        this.state = {
            id: Math.random().toString(36).substring(7)
        }
    }

    handleSelectChange(values) {
        if (this.props.multiple) {
            let value = values.map(v => {
                return v.value;
            });
            this.props.onChange({
                target: {
                    name: this.props.name,
                    value: value
                }
            });
            return;
        }

        let value = values.value;
        this.props.onChange({
            target: {
                name: this.props.name,
                value: value
            }
        });
    }

    handleSelect2Change(values) {
        if (this.props.multiple) {
            if (Array.isArray(values) === false) {
                values = [values];
            }
            if (!values) {
                values = [];
            }
            let value = values.map((v) => {
                if (v) {
                    return v.value;
                }
                return null;
            }).filter((v) => {
                return !!v;
            });
            this.props.onChange({
                target: {
                    name: this.props.name,
                    value: value
                }
            });
            return;
        }

        let value = values ? values.value : '';
        this.props.onChange({
            target: {
                name: this.props.name,
                value: value
            }
        });
    }

    render() {
        var blankOption = null;
        if (this.props.includeBlankOption === true) {
            blankOption = <option value="">{this.props.blankOptionText}</option>
        }
        var className = this.props.className;
        if (!className) {
            className = 'form-control';
        }
        var options = this.props.values;
        if (!options) {
            options = [];
        }
        options = _.uniq(options);
        var style = {};

        if (this.props.useSelect2) {
            let value = this.props.selectedValue;
            if (this.props.multiple) {
                if (!value) {
                    value = [];
                }
                if (Array.isArray(value) === false) {
                    value = [value];
                }
                value = value.map((v) => {
                    let label = _.find(this.props.values, (l) => {
                        return l.key == v;
                    });
                    return {
                        value: v,
                        label: label ? label.value : ''
                    }
                });
            } else {
                let label = null;
                if (value) {
                    let item = _.find(this.props.values, (l) => {
                        return l.key === value;
                    });
                    if (item) {
                        label = item.value;
                    }
                }
                if (label === null) {
                    value = null;
                } else {
                    value = {
                        value: value,
                        label: label
                    }
                }
            }
            let className = 'react-select';
            if (this.props.className) {
                className = className + ' ' + this.props.className;
            }


            const styles = {menu: styles => ({...styles, zIndex: 999})};
            return <Select value={value} isClearable={true} onChange={e => this.handleSelect2Change(e)}
                           isMulti={this.props.multiple} className={className}
                           options={this.props.values.map(function (o) {
                               return {value: o.key, label: o.value}
                           })} styles={styles}/>
        }

        var selectInput = <select name={this.props.name} id={this.state.id} value={this.props.selectedValue}
                                  onChange={this.props.onChange.bind(this)} className={className}
                                  multiple={this.props.multiple}
                                  style={style} disabled={this.props.disabled}>
            {blankOption}
            {options.map(function (option) {
                if (option.disabled) {
                    return <option value={option.key} key={option.key} disabled>{option.value}</option>
                }
                return <option value={option.key} key={option.key}>{option.value}</option>
            })}
        </select>;
        if (this.props.group) {
            var group = this.props.group;
            var optionList = {};
            var groups = [];
            this.props.values.map(function (v) {
                var valueGroup = v[group];
                if (groups.indexOf(valueGroup) === -1) {
                    groups.push(valueGroup);
                    optionList[valueGroup] = {
                        options: [],
                        name: valueGroup
                    }
                }
                optionList[valueGroup].options.push(v);
                return v;
            });
            selectInput = <select name={this.props.name} id={this.state.id} value={this.props.selectedValue}
                                  onChange={this.props.onChange} className={className}
                                  multiple={this.props.multiple}
                                  style={style} disabled={this.props.disabled}>
                {blankOption}
                {groups.map(function (g) {
                    return <optgroup label={g}>
                        {optionList[g].options.map(function (option) {
                            if (option.disabled) {
                                return <option value={option.key} key={option.key} disabled>{option.value}</option>
                            }
                            return <option value={option.key} key={option.key}>{option.value}</option>
                        })}
                    </optgroup>
                })}
            </select>;
        }
        if (this.props.append || this.props.prepend) {
            var prepend = <span/>,
                append = <span/>;
            if (this.props.append) {
                append = <div className="input-group-addon">{this.props.append}</div>
            }
            if (this.props.prepend) {
                prepend = <div className="input-group-addon">{this.props.prepend}</div>
            }

            return <div className="input-group">
                {prepend}
                {selectInput}
                {append}
            </div>
        }

        return selectInput
    }
}

FormElementSelect.propTypes = {
    values: PropTypes.array,
    name: PropTypes.string.isRequired,
    className: PropTypes.string,
    includeBlankOption: PropTypes.bool,
    selectedValue: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
        PropTypes.array,
        PropTypes.bool
    ]),
    disabled: PropTypes.bool,
    onChange: PropTypes.func,
    useSelect2: PropTypes.bool,
    select2RemoteData: PropTypes.string,
    multiple: PropTypes.bool,
    append: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    prepend: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    blankOptionText: PropTypes.string,
    updateFromProps: PropTypes.bool,
    tags: PropTypes.bool
};

export default FormElementSelect;
import React, { useEffect, useState} from 'react';
import Loading from "../../Components/Utility/Loading/Loading";
import {Api, CurrencyFormatter} from "../../Components/Api/Api";
import Card from "../../Components/Card/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

export default function InvoiceDashboard(props) {
    const [loading, setLoading] = useState(true);
    const [stats, setStats] = useState({});


    useEffect(() => {
        Api.get('agency/invoices/dashboard').then((response) => {
            if (response.ok) {
                let data = response.data;
                setStats(data.data);
                setLoading(false);
            }
        })
    }, []);
    if (loading) {
        return <Loading/>
    }
    return <div>
        <Row>
            <Col>
                <Card title="Pending Settlements"
                      onClick={() => {
                          document.location.href = '/settlements';
                      }}
                      help={<>
                          <p>Pending settlements are settlements which are not fully paid.</p>
                      </>} help_title="Pending Settlements"
                      footer_items={[
                          <>{stats.outstanding_settlements.count}</>,
                          <>
                              {CurrencyFormatter.format(stats.outstanding_settlements.outstanding_value)}
                          </>
                      ]}/>
            </Col>
            <Col>
                <Card title="Future Bookings"
                      onClick={() => {
                          if (props.user.bookings) {
                              document.location.href = '/bookings';
                          }
                      }}
                      help={<>
                          <p>Future bookings show the amount and total value of your future bookings with us. This helps
                              you ascertain your future financial position with Revolution Bars Group</p>
                      </>} help_title="Future Bookings"
                      footer_items={[
                          <>{stats.future_booking.count}</>,
                          <>
                              {CurrencyFormatter.format(stats.future_booking.value)}
                          </>
                      ]}
                />
            </Col>
            <Col>
                <Card title="Outstanding Invoices"
                      help_title="Outstanding Invoices"
                      onClick={() => {
                          document.location.href = '/booking-invoices';
                      }}
                      help={<>
                          <p>The number and value of outstanding invoices with Revolution Bars Group. Invoices which are
                              contained within a settlement are not included in these figures</p>
                      </>}
                      footer_items={[
                          <>{stats.invoices_not_in_settlements.count}</>,
                          <>
                              {CurrencyFormatter.format(stats.invoices_not_in_settlements.outstanding_value)}
                          </>
                      ]}
                />
            </Col>
            <Col>
                <Card title="Credit Position" help_title="Credit Position"
                      help={<>
                          <p>This details your credit limit and outstanding invoices with us. If your outstanding
                              invoices exceed your credit limit we may stop your ability to add new bookings.</p>
                      </>}
                      footer_items={[
                          <>
                              {CurrencyFormatter.format(stats.credit_position.outstanding_invoices)} / {CurrencyFormatter.format(stats.credit_position.credit_limit)}
                          </>
                      ]}
                />
            </Col>
        </Row>
    </div>
}
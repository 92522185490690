import React, {Component} from 'react';
import PropTypes from 'prop-types';
import './Boolean.scss';

class FormElementBoolean extends Component {
    static defaultProps = {
        trueLabel: 'Yes',
        falseLabel: 'No'
    };

    handleChange(e) {
        this.props.onChange({
            target: {
                name: this.props.name,
                value: e.target.value === '1'
            }
        })
    }

    render() {
        let className = this.props.className;
        if (!className) {
            className = 'boolean-holder form-group';
        }
        return <div className={className}>
            <label><input type="radio" name={this.props.name} onChange={(e) => this.handleChange(e)} value="1"
                          checked={this.props.value}/>{this.props.trueLabel}
            </label>
            <label><input type="radio" name={this.props.name} onChange={(e) => this.handleChange(e)} value="0"
                          checked={!this.props.value}/>{this.props.falseLabel}
            </label>
        </div>;
    }
}

FormElementBoolean.propTypes = {
    onChange: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    value: PropTypes.bool.isRequired
};

export default FormElementBoolean;

import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import Loading from "../Utility/Loading/Loading";
import Filter from "../Form/Filter";
import {Api, CurrencyFormatter} from "../Api/Api";
import moment from "moment";
import Pagination from "../Utility/Pagination/Pagination";

const SettlementInvoices = props => {
    const [loading, setLoading] = useState(true);
    const [invoices, setInvoices] = useState([]);
    const [filter, setFilter] = useState({
        page: 1,
        search: ''
    });
    const loadBookings = () => {
        setLoading(true);
        Api.get('/agency/settlement/' + props.settlement_id + '/invoices', filter).then((response) => {
            if (response.ok) {
                setInvoices(response.data.data);
                setPagination(response.data.meta.pagination);
                setLoading(false);
            }
        });
    };
    const updateFilter = (filter) => {
        setFilter(filter);
        loadBookings();
    }
    const [pagination, setPagination] = useState({});

    useEffect(() => {
        loadBookings()
    }, []);
    let filters = [
        {
            type: 'text',
            name: 'search',
            value: filter.search,
            label: 'Search'
        }
    ];
    return <div className="settlement-invoices">
        <Filter filters={filters} filter={filter} handleChange={updateFilter} disabled={loading}/>
        <Pagination pagination={pagination} changePage={(page) => {
            filter.page = page;
            updateFilter(filter);
        }}/>
        <table className="table table-bordered table-striped">
            <thead>
            <tr>
                <th>Invoice Ref</th>
                <th>Booking Ref</th>
                <th>Amount</th>
                <th>Booking Date</th>
            </tr>
            </thead>
            {loading ? <tbody>
                <tr>
                    <td colSpan={4}><Loading/></td>
                </tr>
                </tbody> :
                <tbody>
                {invoices.map((invoice) => {
                    return <tr key={invoice.invoice_id}>
                        <td><a href={'/invoice/' + invoice.invoice_id}>{invoice.invoice_ref}</a></td>
                        <td>{invoice.booking_ref}</td>
                        <td>{CurrencyFormatter.format(invoice.balance_due)}</td>
                        <td>{moment(invoice.booking_date).format('DD/MM/YYYY')}</td>
                    </tr>
                })}
                </tbody>
            }
        </table>
        <Pagination pagination={pagination} changePage={(page) => {
            filter.page = page;
            updateFilter(filter);
        }}/>
    </div>
}
SettlementInvoices.propTypes = {
    settlement_id: PropTypes.number.isRequired
}
export default SettlementInvoices;
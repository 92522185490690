import React, {useState} from 'react';
import {OverlayTrigger, Tooltip} from "react-bootstrap";
import PropTypes from 'prop-types';
import _ from "lodash";
import './InfoTip.scss';

const InfoTip = (props) => {
    const [id] = useState(_.uniqueId('button-tooltip'));
    const renderToolTip = (ttProps) => (
        <Tooltip id={id} {...ttProps}>
            {props.info}
        </Tooltip>
    );
    return <OverlayTrigger trigger={['hover', 'focus']} delay={{show: 100, hide: 400}}
                           overlay={renderToolTip} placement={"right"}>
        <button className="btn btn-default overlay-trigger"><i className="fa fa-info"/></button>
    </OverlayTrigger>
}

InfoTip.propTypes = {
    info: PropTypes.any.isRequired
};
export default InfoTip;
import React, {useContext, useEffect, useRef, useState} from 'react';
import Jumbotron from "react-bootstrap/Jumbotron";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import {EventDispatcher} from "../../../Events/Dispatcher";
import {NewBookingContext} from "../Context/NewBookingContext";
import moment from "moment";
import Modal from "react-bootstrap/Modal";
import {CurrencyFormatter} from "../../../Components/Api/Api";
import FormControl from "react-bootstrap/FormControl";
import {Button} from "react-bootstrap";
import nl2br from 'react-nl2br'


const Step2 = (props) => {
    const [bookingState] = useContext(NewBookingContext);
    const [currentQty, setCurrentQty] = useState(0);
    const [currentSingleQty, setCurrentSingleQty] = useState(1);
    const [currentVar, setCurrentVar] = useState(null);
    const [modal, setModal] = useState({
        show: false,
        content: null,
        buttons: null
    });
    const [loading, setLoading] = useState(false);
    const bookingRef = useRef(bookingState.booking);
    bookingRef.current = bookingState.booking;
    const qtyRef = useRef(currentQty);
    qtyRef.current = currentQty;
    const showVariation = (variation) => {
        setCurrentVar(variation);
        setModal({...modal, ...{show: true}});
    };
    useEffect(() => {
        setCurrentQty(bookingRef.current.packages_required);
    }, [bookingRef.current.packages_required]);
    useEffect(() => {
        setModal({...modal, ...{show: false}});
    }, [bookingState.booking]);
    let variations = bookingState.booking.available_options.data;
    return <div className="step-2">
        <Modal show={modal.show} centered>
            <Modal.Body>
                <div className="variation-modal">
                    {currentVar ? <>
                        <p>{currentVar.description}</p>
                        {currentVar.per_person ?
                            <>
                                <span>How many people would you like to assign to this package?</span>
                                <FormControl className={`mb-2`} value={currentQty}
                                             max={bookingRef.current.packages_required}
                                             min={1} type="number" onChange={(e) => {
                                    if (parseInt(e.target.value) > bookingRef.current.packages_required) {
                                        setCurrentQty(bookingRef.current.packages_required);
                                        return;
                                    }
                                    setCurrentQty(parseInt(e.target.value));
                                }}/></> : <>
                                <span>How many would you like to add to this booking?</span>
                                <FormControl className={`mb-2`} value={currentSingleQty}
                                             type="number" onChange={(e) => {
                                    setCurrentSingleQty(parseInt(e.target.value));
                                }}/>
                            </>}
                    </> : null}
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={() => {
                    let options = JSON.parse(JSON.stringify(bookingState.booking.options.data));
                    let found = false;
                    if (currentVar.per_person) {
                        options = options.map((o) => {
                            if (o.variation_id === currentVar.id) {
                                o.qty = currentQty;
                                found = true;
                            }
                            return o;
                        });
                        if (!found) {
                            options.push({
                                qty: currentQty,
                                variation: {
                                    data: currentVar
                                }
                            })
                        }
                    } else {
                        options = options.map((o) => {
                            if (o.variation_id === currentVar.id) {
                                o.qty = o.qty + currentSingleQty;
                                found = true;
                            }
                            return o;
                        });
                        if (!found) {
                            options.push({
                                qty: currentSingleQty,
                                variation: {
                                    data: currentVar
                                }
                            })
                        }
                    }
                    EventDispatcher.dispatch('update-booking', {
                        variations: options
                    });
                }}>
                    Apply
                </Button>
                <Button variant="outline-primary" onClick={() => {
                    setModal({...modal, ...{show: false}})
                }}>Cancel</Button>
            </Modal.Footer>
        </Modal>
        <>
            <Jumbotron fluid>
                <Container>
                    <Row>
                        <Col>
                            <button className={`btn btn-sm btn-outline-primary mb-2`} onClick={(e) => {
                                EventDispatcher.dispatch('update-booking', {
                                    step: 1
                                });
                            }}><i
                                className={`fa fa-arrow-left mr-1`}></i>Go back
                            </button>
                            <h2>Select and customize</h2>
                            <p className={`mb-0 bigger`}>Customize your booking with our packages below.</p>
                        </Col>
                    </Row>
                </Container>
            </Jumbotron>
            <Container>

                <Row>
                    <Col lg={8}>
                        <Row>
                            {variations.map(variant => {
                                    let selected = bookingState.booking.options.data.find((opt) => {
                                        return opt.id === variant.id;
                                    });
                                    return <Col sm={12} lg={6} className={`mb-3`} key={variant.id}>
                                        <Card>
                                            <div className={`cardImage`} style={{
                                                backgroundImage: `url(${variant.image})`
                                            }}>
                                            </div>
                                            <Card.Body>
                                                <Row>
                                                    <Col sm={8}>
                                                        <h5 className={`mb-0`}>
                                                            {variant.name}
                                                        </h5>
                                                    </Col>
                                                    <Col sm={4} className={`text-right`}>
                                                        {CurrencyFormatter.format(variant.price)}{variant.per_person ? 'pp' : ''}
                                                    </Col>
                                                    {variant.description &&
                                                    <Col sm={12}>
                                                        <hr/>
                                                        <p className={`mb-0`}>{nl2br(variant.description)}</p>
                                                    </Col>
                                                    }
                                                </Row>
                                            </Card.Body>
                                            {selected &&
                                            <div className="cardFooter">
                                                <span>
                                                    <i className={`fa fa-users mr-2`}/>
                                                    {selected.qty}
                                                </span>
                                                <span>
                                                    {CurrencyFormatter.format(selected.price * selected.qty)}
                                                </span>
                                            </div>
                                            }
                                            {!selected &&
                                            <div className="unselectedFooter" onClick={(e) => {
                                                showVariation(variant);
                                            }}>
                                                <i className="fa fa-plus mr-2"/> Select
                                            </div>
                                            }
                                        </Card>
                                    </Col>
                                }
                            )}
                        </Row>

                    </Col>
                    <Col lg={4}>
                        <h5 className={`mb-3`}>Booking Summary</h5>
                        {bookingState.booking.no_people} People<br/>
                        {bookingState.booking.bar.data.bar_name}<br/>
                        {moment(bookingState.booking.full_booking_date).format('Do MMMM YYYY HH:mm')}
                        {bookingState.booking.options.data.length === 0 &&
                        <div className={`emptySummary mb-2`}>
                            You haven't selected any packages for your booking
                        </div>
                        }
                        {variations.map(variant => {
                            return variant.selected ?
                                <Card className={`mb-2`}>
                                    <Card.Body>
                                        <Row>
                                            <Col sm={9}><h5>{variant.name}</h5></Col>
                                            <Col sm={3}
                                                 className={`text-right`}>£{variant.calculated_price * variant.quantity}<br/><i
                                                className={`fa fa-users mr-1`}></i> {variant.quantity}</Col>
                                            <Col sm={12}><span className={`text-danger cursor-pointer`}
                                                               onClick={(e) => {

                                                               }}><i
                                                className={`fa fa-times`}></i> Remove</span></Col>
                                        </Row>

                                    </Card.Body>
                                </Card> : false

                        })}

                        {bookingState.booking.options.data.map((opt) => {
                            console.log(opt);
                            return <Card className={`mb-2`} key={opt.id}>
                                <Card.Body>
                                    <Row>
                                        <Col sm={8}><h5>{opt.name}</h5></Col>
                                        <Col sm={4} className={`text-right`}>
                                            {CurrencyFormatter.format(opt.price * opt.qty)}<br/>
                                            <i className={(opt.per_person?'fa fa-users mr-1':'fa fa-times')}/> {opt.qty}
                                        </Col>
                                        <Col sm={12}>
                                            <span className={`text-danger cursor-pointer`} onClick={(e) => {
                                                let options = JSON.parse(JSON.stringify(bookingState.booking.options.data));
                                                options = options.filter((o) => {
                                                    return o.id !== opt.id;
                                                });
                                                EventDispatcher.dispatch('update-booking', {
                                                    variations: options
                                                });
                                            }}>
                                                <i className={`fa fa-times`}/> Remove</span>
                                        </Col>
                                    </Row>

                                </Card.Body>
                            </Card>
                        })}

                        <hr/>
                        <div className={`text-right mb-2`}>
                            Total
                            <h1>£{bookingState.booking.booking_total}</h1>
                        </div>
                        <button className={`btn btn-primary btn-block mb-2`}
                                disabled={!bookingState.booking.can_continue} onClick={() => {
                            EventDispatcher.dispatch('update-booking', {
                                step: 3
                            });
                        }}>
                            <i className={`fa fa-check mr-2`}/> Next step
                        </button>
                        {bookingState.booking.can_continue === false && bookingState.booking.errors.length > 0 ? <>
                            <div className="alert alert-info">{bookingState.booking.errors.map((e) => {
                                return <p key={e.field}>{e.error}</p>
                            })}</div>
                        </> : null}

                    </Col>

                </Row>


            </Container>
        </>
    </div>
}

export default Step2;
import React, {Component} from 'react';
import PropTypes from 'prop-types';

class PaginationLink extends Component {
    handlePageClick(e) {
        e.preventDefault();
        this.props.changePage(this.props.pageNumber);
    }

    render() {
        return <li className={parseInt(this.props.current_page) === parseInt(this.props.pageNumber) ? 'active page-item' : 'page-item'}>
            <a href={"#" + this.props.pageNumber} onClick={(e) => this.handlePageClick(e)} className="page-link">{this.props.pageNumber}</a>
        </li>
    }
}

PaginationLink.propTypes = {
    current_page: PropTypes.number.isRequired,
    changePage: PropTypes.func.isRequired,
    pageNumber: PropTypes.number.isRequired
};
export default PaginationLink;
import React, {Component} from 'react';
import {Api} from "../Components/Api/Api";
import Loading from "../Components/Utility/Loading/Loading";
import AdminUserRow from "../Components/MyAccount/AdminUserRow";
import {ModalActions} from "../Components/Utility/Modal/Modal";
import AddUser from "../Components/MyAccount/AddUser";
import {EventDispatcher} from "../Events/Dispatcher";

class AgencyAdmin extends Component {
    constructor(props) {
        super(props);
        this.state = {
            users: [],
            loading: true,
            include_deleted: false
        }
    }

    componentDidMount() {
        this.updateUsers();
        this.user_update_listen = EventDispatcher.subscribe('reload-users', () => {
            this.updateUsers();
        });
    }

    componentWillUnmount() {
        EventDispatcher.unsubscribe(this.user_update_listen);
    }

    updateUsers() {
        Api.get('agency/users').then((response) => {
            if (response.ok) {
                this.setState({users: response.data.data, loading: false});
            }
        });
    }

    handleAdd(e) {
        e.preventDefault();
        ModalActions.openModal('Add User', <AddUser/>);
    }

    render() {
        if (this.state.loading) {
            return <Loading/>
        }
        let users = this.state.users;
        if (this.state.include_deleted === false) {
            users = users.filter((user) => {
                return !user.deleted;
            });
        }
        return <div>
            <div className="well">
                <div className="btn-group">
                    <button className={this.state.include_deleted ? "btn btn-warning" : 'btn btn-success'}
                            onClick={(e) => this.setState({include_deleted: !this.state.include_deleted})}>{this.state.include_deleted ? 'Only Active Users' : 'Include Inactive Users'}</button>
                </div>
                <button className="btn btn-success pull-right" onClick={(e) => this.handleAdd(e)}>
                    <i className="fa fa-plus"/> Add New User
                </button>
                <div className="clearfix"/>
            </div>
            <table className="table table-bordered table-striped">
                <thead>
                <tr>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Active</th>
                    <th>Last Login</th>
                    <th>View Bookings</th>
                    <th>View Invoices</th>
                    <th>Booking Emails</th>
                    <th>Invoice Emails</th>
                    <th>Admin User</th>
                </tr>
                </thead>
                <tbody>
                {users.map(function (user) {
                    return <AdminUserRow user={user} key={user.id}/>
                })}
                </tbody>
            </table>
        </div>
    }
}

export default AgencyAdmin;
import React, { useContext, useState } from 'react'
import { NewBookingContext } from '../Context/NewBookingContext'
import Jumbotron from 'react-bootstrap/Jumbotron'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import FormControl from 'react-bootstrap/FormControl'
import Card from 'react-bootstrap/Card'
import { EventDispatcher } from '../../../Events/Dispatcher'
import { Api, CurrencyFormatter } from '../../../Components/Api/Api'
import cx from 'classnames'
import moment from 'moment'
import FormElementTextArea from "../../../Components/Form/Element/TextArea";

const Step3 = (props) => {
    const [state, dispatch] = useContext(NewBookingContext)
    const [loading, setLoading] = useState(false)
    const [clicked, setClicked] = useState(false)
    const [name, setName] = useState({
        forename: state.booking.forename ? state.booking.forename : '',
        surname: state.booking.surname ? state.booking.surname : '',
        email: state.booking.cust_email ? state.booking.cust_email : '',
        phone: state.booking.cust_phone ? state.booking.cust_phone : '',
    })
    const [additionalDetails, setAdditionalDetails] = useState({
        allergy_info: state.booking.allergy_info || '',
        allergy_status: state.booking.allergy_status || null,
        special_requirements: state.booking.special_requirements || '',
    })
    return <>
        <Jumbotron fluid>
            <Container>
                <Row>
                    <Col>
                        <button className={`btn btn-sm btn-outline-primary mb-2`} onClick={() => {
                            EventDispatcher.dispatch('update-booking', {
                                step: 2,
                            })
                        }}><i
                          className={`fa fa-arrow-left mr-1`}/> Go back
                        </button>
                        <h2>Complete your booking</h2>
                        <p className={`bigger mb-0`}>Last but not least, we just need a few details</p>
                    </Col>
                </Row>
            </Container>
        </Jumbotron>
        <Container>
            <Row className={`mb-4`}>
                <Col sm={12}>
                    <label>
                        <strong>Lead booker's details</strong><br/>
                        <span
                          className={`text-muted`}>The contact details of the leader booker who will be attending</span>
                    </label>
                </Col>
                <Col sm={6}>
                    <FormControl isInvalid={clicked === true && !state.booking.forename} onChange={e =>
                      setName({
                          ...name,
                          ...{ forename: e.target.value },
                      })
                    } onBlur={() => {
                        EventDispatcher.dispatch('update-booking', {
                            forename: name.forename,
                        })
                    }} type="text" value={name.forename} placeholder={`Lead booker first name *`}/>
                </Col>
                <Col sm={6}>
                    <FormControl isInvalid={clicked === true && !state.booking.surname} onChange={e =>
                      setName({
                          ...name,
                          ...{ surname: e.target.value },
                      })
                    } onBlur={() => {
                        EventDispatcher.dispatch('update-booking', {
                            surname: name.surname,
                        })
                    }} type="text" value={name.surname} placeholder={`Lead booker last name *`}/>
                </Col>
            </Row>
            <Row className={`mb-4`}>
                <Col sm={6}>
                    <FormControl isInvalid={clicked === true && !state.booking.cust_email} onChange={e =>
                      setName({
                          ...name,
                          ...{ email: e.target.value },
                      })
                    } onBlur={() => {
                        EventDispatcher.dispatch('update-booking', {
                            cust_email: name.email,
                        })
                    }} type="text" value={name.email} placeholder={`Lead booker email`}/>
                </Col>
                <Col sm={6}>
                    <FormControl isInvalid={clicked === true && !state.booking.cust_phone} onChange={e =>
                      setName({
                          ...name,
                          ...{ phone: e.target.value },
                      })
                    } onBlur={() => {
                        EventDispatcher.dispatch('update-booking', {
                            cust_phone: name.phone,
                        })
                    }} type="text" value={name.phone} placeholder={`Lead booker phone number`}/>
                </Col>
            </Row>
            <Row>
                <Col sm={12}>
                    <h5>
                        Additional details
                    </h5>
                </Col>
                <Col sm={12}>
                    <label>
                        <strong>Allergen info</strong><br />
                        <span className={`text-muted`}>Does any of the party have a food/drink allergy or intolerance?</span>
                    </label>
                </Col>
                <Col sm={12}>
                    <label className="mr-4" htmlFor="allergen_info_tbc">
                        <input id="allergen_info_tbc" name="allergy_status" type="radio" value={0}
                               onChange={(e) => {
                                   setAdditionalDetails({
                                       ...additionalDetails,
                                       ...{ allergy_status: parseInt(e.target.value) },
                                   })
                                   EventDispatcher.dispatch('update-booking', {
                                       allergy_status: parseInt(e.target.value),
                                   })
                               }}
                               checked={parseInt(additionalDetails.allergy_status) === 0}
                        /> Waiting on information
                    </label>
                    <label className="mr-4" htmlFor="allergen_info_none">
                        <input id="allergen_info_none" name="allergy_status" type="radio" value={1}
                               onChange={(e) => {
                                   setAdditionalDetails({
                                       ...additionalDetails,
                                       ...{ allergy_status: parseInt(e.target.value) },
                                   })
                                   EventDispatcher.dispatch('update-booking', {
                                       allergy_status: parseInt(e.target.value),
                                   })
                               }}
                               checked={parseInt(additionalDetails.allergy_status) === 1}
                        /> None
                    </label>
                    <label className="mr-4" htmlFor="allergen_info_yes">
                        <input id="allergen_info_yes" name="allergy_status" type="radio" value={2}
                               onChange={(e) => {
                                   setAdditionalDetails({
                                       ...additionalDetails,
                                       ...{ allergy_status: parseInt(e.target.value) },
                                   })
                                   EventDispatcher.dispatch('update-booking', {
                                       allergy_status: parseInt(e.target.value),
                                   })
                               }}
                               checked={parseInt(additionalDetails.allergy_status) === 2}
                        /> Yes
                    </label>
                </Col>
            </Row>
            {parseInt(state.allergy_status) === 2 ? <Row className={`mb-4`}>
                <Col sm={12}>
                    <label>
                        <strong>Guest allergen details</strong>
                    </label>
                </Col>
                <Col sm={12}>
                    <FormElementTextArea name="allergy_info"
                                         onChange={(e) => {
                                             setAdditionalDetails({
                                                 ...additionalDetails,
                                                 ...{ allergy_info: e.target.value },
                                             })
                                         }}
                                         onBlur={() =>
                                             EventDispatcher.dispatch('update-booking', {
                                                 allergy_info: additionalDetails.allergy_info,
                                             })
                                         }
                                         value={additionalDetails.allergy_info}
                    />
                </Col></Row> : '' }
            <Row className={`mb-4`}>
                <Col sm={12}>
                    <label>
                        <strong>Special requirements (optional) </strong>
                    </label>
                </Col>
                <Col sm={12}>
                    <FormElementTextArea name="special_requirements"
                                         onChange={(e) => {
                                             setAdditionalDetails({
                                                 ...additionalDetails,
                                                 ...{ special_requirements: e.target.value },
                                             })
                                         }}
                                         onBlur={() =>
                                             EventDispatcher.dispatch('update-booking', {
                                                 special_requirements: additionalDetails.special_requirements,
                                             })
                                         }
                                         value={additionalDetails.special_requirements}
                    />
                </Col>
            </Row>
            <Row>
                <Col>
                    <h5 className={`mb-4`}>Your booking summary</h5>
                </Col>
            </Row>

            <Row>
                <Col xs={12}>
                    <Card>
                        <div className="cardFooter">
                            <span><i className={`fa fa-users mr-2`}/> {state.booking.no_people}</span>
                            <span>{state.booking.bar.data.bar_name}</span>
                            <span>
                                {moment(state.booking.full_booking_date).format('Do MMMM YYYY')} at {moment(
                              state.booking.full_booking_date).format('HH:mm')}
                            </span>
                        </div>
                    </Card>
                </Col>
            </Row>
            <Row>
                {state.booking.options.data.map(variant => {
                    return <Col sm={6} className={`mb-3`} key={variant.id}>
                        <Card>
                            <div className={`cardImage`} style={{
                                backgroundImage: `url(${variant.image})`,
                            }}>

                            </div>
                            <Card.Body>
                                <Row>
                                    <Col sm={8}>
                                        <h5 className={`mb-0`}>
                                            {variant.name}
                                        </h5>
                                    </Col>
                                    <Col sm={4} className={`text-right`}>
                                        {CurrencyFormatter.format(variant.price)}{variant.per_person ? 'pp' : ''}
                                    </Col>
                                </Row>
                            </Card.Body>

                            <div className="cardFooter">
                                                <span>
                                                    {variant.per_person ? <i className={`fa fa-users mr-2`}/> :
                                                      <i className={`fa fa-times mr-2`}/>}
                                                    {variant.qty}
                                                </span>
                                <span>
                                                    {CurrencyFormatter.format(variant.full_price)}
                                                </span>
                            </div>
                        </Card>
                    </Col>
                })}
            </Row>

            <Row className={`mb-3`}>
                <Col className={`text-right`}>
                    <hr/>
                    Total
                    <h1>{CurrencyFormatter.format(state.booking.booking_total)}</h1>
                </Col>
            </Row>

            <Row>
                <Col>
                    {!loading &&
                      <div className={cx('btn btn-primary btn-block', { disabled: !state.booking.can_continue || state.booking.allergy_status === null })}
                           onClick={() => {
                               setClicked(true)
                               if (state.booking.can_continue) {
                                   setLoading(true)
                                   Api.postJson('/rest/agency/booking-session/complete',
                                     { session_id: state.booking.session_id }).then((data) => {
                                       EventDispatcher.dispatch('update-booking', {})
                                   })
                               }
                           }}><i className={`fa fa-check mr-2`}/> Complete Booking</div>
                    }
                    {loading &&
                      <div className="loading-icon">
                          <i className="fa fa-cog fa-spin" style={{ fontSize: '34px' }}></i>
                      </div>
                    }
                </Col>
            </Row>
        </Container>
    </>
}

export default Step3
import React, {useContext} from 'react';
import Layout from "../../Components/Layout/Layout";
import {InitialBookingState, NewBookingWrapper} from "./Context/NewBookingContext";
import AddBooking from "./AddBooking";

const AddBookingWrapper = (props) => {
    return <Layout {...props}>
        <NewBookingWrapper>
            <AddBooking {...props}/>
        </NewBookingWrapper>
    </Layout>
}

export default AddBookingWrapper;
import React, {Component} from 'react';
import FormElementText from "./Element/Text";
import _ from 'lodash';
import moment from 'moment';
import FormElementSelect from "./Element/Select";
import FormRow from "./Row";
import PropTypes from 'prop-types';
import FormElementDatePicker from "./Element/DatePicker";
import FormElementBoolean from "./Element/Boolean";
import FormBarSelect from "./Element/FormBarSelect";

class FormFilter extends Component {
    constructor(props) {
        super(props);
        let values = {};
        this.props.filters.map(item => {
            values[item.name] = item.value;
            return item;
        });
        this.state = {
            values: values
        };
        this.timeouts = {};
    }

    componentDidMount() {
        if (this.props.storeState && this.props.name) {
            let values = window.localStorage.getItem('filter_' + this.props.name);
            if (values) {
                this.setState({values: JSON.parse(values)}, () => {
                    this.triggerUpdate();
                });
            } else {
                this.triggerUpdate();
            }
        } else {
            this.triggerUpdate();
        }
    }

    handleFieldChange(e) {
        let values = _.clone(this.state.values);
        values[e.target.name] = e.target.value;
        let type = e.target.type;
        let name = e.target.name;
        this.setState({values: values}, function () {
            if (type === 'text' || type === 'date') {
                if (this.timeouts[name]) {
                    clearTimeout(this.timeouts[name]);
                }
                this.timeouts[name] = setTimeout(this.triggerUpdate.bind(this), 1000);
                return;
            }
            this.triggerUpdate();
        }.bind(this));
    }

    handleBlur(e) {
        if (e.target.type === 'text') {
            this.triggerUpdate();
        }
    }

    triggerUpdate() {
        let filter = _.clone(this.props.filter);
        let keys = Object.keys(filter);
        keys.map(function (key) {
            if (typeof this.state.values[key] !== 'undefined') {
                filter[key] = this.state.values[key];
            }
        }.bind(this));
        if (this.props.storeState && !this.props.name) {
            console.log('Filter name is required to store state');
        }
        if (this.props.storeState && this.props.name) {
            window.localStorage.setItem('filter_' + this.props.name, JSON.stringify(filter));
        }
        this.props.handleChange(filter);
    }

    handleDateChange(e) {
        let value = e.target.value ? moment(e.target.value, 'DD/MM/YYYY').format('YYYY-MM-DD') : null;
        if (e.target.append_time && value) {
            value = value + ' ' + e.target.append_time;
        }
        this.handleFieldChange({
            target: {
                name: e.target.name,
                type: 'date',
                value: value
            }
        })
    }

    render() {
        return <div className="well">
            <div className="row">
                {this.props.filters.map(function (item, key) {
                    let content = null;
                    if (item.type === 'text') {
                        content = <FormElementText name={item.name} value={this.state.values[item.name]}
                                                   onBlur={e => this.handleBlur(e)}
                                                   onChange={e => this.handleFieldChange(e)}
                                                   disabled={this.props.disabled}/>
                    }
                    if (item.type === 'select') {
                        content = <FormElementSelect name={item.name} selectedValue={this.state.values[item.name]}
                                                     values={item.values} onChange={e => this.handleFieldChange(e)}
                                                     includeBlankOption={item.include_blank_option}
                                                     blankOptionText={item.blank_option_text}
                                                     disabled={this.props.disabled} multiple={item.multiple}
                                                     useSelect2={item.multiple || item.use_select_2}/>
                    }
                    if (item.type === 'boolean') {
                        content = <FormElementBoolean name={item.name} value={this.state.values[item.name]}
                                                      onChange={e => this.handleFieldChange(e)}/>
                    }

                    if (item.type === 'date') {
                        content = <FormElementDatePicker name={item.name}
                                                         value={this.state.values[item.name] ? moment(this.state.values[item.name]).toDate() : null}
                                                         onChange={e => this.handleDateChange(e)}/>
                    }

                    if (item.type === 'date_range') {
                        let start_name = item.start_name ? item.start_name : item.name + '_start';
                        let end_name = item.end_name ? item.end_name : item.name + '_end';
                        content = <div className="row">
                            <div className="col-md-6">
                                <FormElementDatePicker name={start_name} placeholder="Start"
                                                       value={this.state.values[start_name] ? moment(this.state.values[start_name]).toDate() : null}
                                                       onChange={e => this.handleDateChange(e)} append_time="00:00:00"/>
                            </div>
                            <div className="col-md-6">
                                <FormElementDatePicker name={end_name} placeholder="End"
                                                       value={this.state.values[end_name] ? moment(this.state.values[end_name]).toDate() : null}
                                                       onChange={e => this.handleDateChange(e)} append_time="23:59:59"/>
                            </div>
                        </div>
                    }

                    if (item.type === 'boolean_select') {
                        content = <FormElementSelect name={item.name} selectedValue={this.state.values[item.name]}
                                                     values={[{key: 0, value: 'No'}, {key: 1, value: 'Yes'}]}
                                                     onChange={e => this.handleFieldChange(e)}
                                                     blankOptionText=" - Select - "
                                                     includeBlankOption={true}
                                                     disabled={this.props.disabled} multiple={false}/>
                    }
                    if (item.type === 'bar_select') {
                        content = <FormBarSelect name={item.name} selectedValue={this.state.values[item.name]}
                                                 onChange={e => this.handleFieldChange(e)}
                                                 blankOptionText=" - Select - "
                                                 includeBlankOption={true}
                                                 disabled={this.props.disabled} multiple={false}/>
                    }

                    return <div className="col-md-3" key={item.name}>
                        <FormRow label={item.label}>
                            {content}
                        </FormRow>
                    </div>
                }.bind(this))}
            </div>
            <div className="clearfix"/>
        </div>
    }
}

FormFilter.propTypes = {
    filters: PropTypes.array.isRequired,
    filter: PropTypes.object.isRequired,
    handleChange: PropTypes.func.isRequired,
    storeState: PropTypes.bool,
    name: PropTypes.string
};

export default FormFilter;
import React from 'react';

import withAuth from "./Components/Higher/Authentication/Authentication";

import './App.css'

import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';

import Dashboard from './Pages/Dashboard'
import Login from './Pages/Login'
import ResetPassword from './Pages/ResetPassword'
import Bookings from './Pages/Bookings'
import Booking from './Pages/Booking'
import Invoices from './Pages/Invoices'
import Invoice from './Pages/Invoice'
import MyAccount from './Pages/MyAccount'
import AgencyUser from './Pages/AgencyUser'
import PageNotFound from "./Pages/Errors/PageNotFound";
import Settlement from "./Pages/Settlement";
import BookingInvoices from "./Components/Invoices/BookingInvoices";
import Settlements from "./Components/Invoices/Settlements";
import InvoiceQueries from "./Components/Invoices/InvoiceQueries";
import ForgotPassword from "./Pages/ForgotPassword";
import AddBookingWrapper from "./Pages/AddBooking/AddBookingWrapper";
import Bars from "./Pages/Bars";

/*
window.brand = 'revolution';
if (window.location.hostname.indexOf('cuba') > -1) {
    window.brand = 'decuba';
}*/


const App = () => {

    return <Router>
        <div className="rbg-app">
            <Switch>
                <Route path="/" exact component={Dashboard}/>
                <Route path="/bookings/new" component={AddBookingWrapper} exact={true}/>
                <Route path="/bookings/*" component={Booking} exact/>
                <Route path="/bookings" exact component={Bookings}/>
                <Route path="/invoices" component={Invoices} exact/>
                <Route path="/invoice/*" component={Invoice} exact/>
                <Route path="/login" component={Login} exact/>
                <Route path="/my-account" component={MyAccount} exact/>
                <Route path="/reset-password/*" component={ResetPassword} exact/>
                <Route path="/forgot-password" component={ForgotPassword} exact/>
                <Route path="/user/*" component={AgencyUser} exact/>
                <Route path="/settlement/*" component={Settlement} exact/>
                <Route path="/settlements" component={Settlements} exact/>
                <Route path="/booking-invoices" component={BookingInvoices} exact/>
                <Route path="/invoice-queries" component={InvoiceQueries} exact/>
                <Route path="/bars/*" component={Bars} exact />
                <Route path="/bars" component={Bars} exact />
                <Route component={PageNotFound}/>
            </Switch>
        </div>
    </Router>

}

export default withAuth(App)
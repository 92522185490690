import React from 'react';

import PropTypes from 'prop-types';
import Modal from "../Utility/Modal/Modal";
import withAuth from "../../Components/Higher/Authentication/Authentication";
import ReactNotification from 'react-notifications-component'
import 'react-notifications-component/dist/theme.css'
import 'bootstrap/dist/css/bootstrap.min.css';

import Navigation from "./Navigation/Navigation";
import Footer from "./Footer/Footer";


const Layout = props =>  {

    return <div className={`agency-app`} id={`top`}>
        <Modal />
        <ReactNotification />
        <Navigation currentRoute={props.match.path} />
            {props.children}
        <Footer />
    </div>

}

Layout.propTypes = {
    match: PropTypes.object.isRequired
};


export default withAuth(Layout);
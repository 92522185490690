import React, {Component} from 'react';
import {EventDispatcher} from "../../../Events/Dispatcher";
import PropTypes from 'prop-types';
import cx from 'classnames';

class TabLink extends Component {
    constructor(props) {
        super(props);
        this.state = {
            has_changes: false
        }
    }

    componentDidMount() {
        EventDispatcher.subscribe('tab-has-changes', function (data) {
            if (data.id === this.props.id) {
                this.setState({has_changes: data.value});
            }
        }.bind(this));
    }

    handleTabClick(e) {
        e.preventDefault();
        window.location.href = '#' + this.props.id;
        this.props.changeTab(this.props.id);
    }

    handleClose(e) {
        e.preventDefault();
        if (this.props.confirm_close === true && this.state.has_changes === true) {
            if (window.confirm('Are you sure you want to close this tab? You will lose any changes you have not saved.')) {
                EventDispatcher.dispatch('close-tab', this.props.id);
            }
        } else {
            EventDispatcher.dispatch('close-tab', this.props.id);
        }
    }

    render() {
        return <>
            <a href={this.props.id}
               className={cx('nav-item', 'nav-link', {active: this.props.active_tab === this.props.id})}
               onClick={(e) => this.handleTabClick(e)}>{this.props.title}{this.state.has_changes ? ' *' : null}</a>
            {this.props.can_close ?
                <a href={"#" + this.props.id} onClick={this.handleClose}><i
                    className="fa fa-times-circle"></i></a> : null}
            {this.props.badge ? <span className="badge">{this.props.badge}</span> : null}
        </>
    }
}

TabLink.propTypes = {
    confirm_close: PropTypes.bool,
    can_close: PropTypes.bool,
    bade: PropTypes.any
};

export default TabLink;
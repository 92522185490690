import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import withAuth from "../../Components/Higher/Authentication/Authentication";
import {Api} from "../Api/Api";
import Loading from "../Utility/Loading/Loading";
import FileDownload from "../../Utility/FileDownload";

const DownloadInvoice = (props) => {
    const [loading, setLoading] = useState(true);
    const [file, setFile] = useState({});
    const [error, setError] = useState(null);
    useEffect(() => {
        Api.post('/agency/invoice/' + props.invoice_id + '/download').then((response) => {
            if (response.ok) {
                setFile(response.data.data);
                setLoading(false);
                return;
            }

            setLoading(false);
            setError(response.error);
        });
    }, []);
    if (loading) {
        return <Loading/>
    }
    if (error) {
        return <div className="alert alert-danger">{error}</div>
    }

    return <FileDownload file={file} show_filename={true}/>
}
DownloadInvoice.propTypes = {
    invoice_id: PropTypes.number.isRequired
}

export default withAuth(DownloadInvoice);
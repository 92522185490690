import React, {Component} from 'react';
import PropTypes from 'prop-types';

class Widget extends Component {
    render() {
        let buttons = null;
        if (this.props.buttons) {
            buttons = <div className="pull-right">{this.props.buttons}</div>
        }
        return <div className="card card-default">
            {this.props.title ? <div className="card-header">{this.props.title} {buttons}</div> : null}
            {this.props.include_body !== false ?
                <div className="card-body">{this.props.children}</div> : this.props.children}

            {this.props.footer ? <div className="panel-footer">{this.props.footer}</div> : null}
        </div>
    }
}

Widget.propTypes = {
    title: PropTypes.string.isRequired,
    footer: PropTypes.object,
    include_body: PropTypes.bool,
    buttons: PropTypes.arrayOf(PropTypes.object)
};

export default Widget;
import React from 'react';
import styles from './Card.module.scss';
import PropTypes from 'prop-types';
import cx from 'classnames';
import {ModalActions} from "../Utility/Modal/Modal";

const Card = props => {
    let containerStyles = [styles.card];
    if (props.onClick) {
        containerStyles.push(styles.hasLinkAction)
    }
    return <div className={cx(containerStyles)}>
        <div className={styles.cardBody} onClick={props.onClick}>
            {props.badge ? <div className={styles.cardBadge}>{props.badge}</div> : null}
            {props.help ? <button className={styles.helpButton} onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                ModalActions.openModal(props.help_title, props.help);
            }}><i className="fa fa-info"/></button> : null}
            <h3 className={`lead`}>{props.title}</h3>
            {props.body ? <p className={`mb-0`}>{props.body}</p> : null}
        </div>
        {props.footer_items && props.footer_items.length ?
            <div className={styles.cardFooter}>
                {props.footer_items.map((item, index) => {
                    return <div className={styles.cardFooterItem} key={index}>
                        {item}
                    </div>
                })}
            </div> : null}
    </div>
}

Card.propTypes = {
    title: PropTypes.string.isRequired,
    badge: PropTypes.string,
    body: PropTypes.any,
    footer_items: PropTypes.array,
    onClick: PropTypes.func,
    help: PropTypes.any,
    help_title: PropTypes.string
};

export default Card;
import React from 'react';
import PropTypes from 'prop-types';
import filesize from "filesize";

export default function FileDownload(props) {
    return <a className="btn btn-success" href={props.file.uri} target="_blank">
        <i className="fa fa-download"/>&nbsp;
        Download {props.show_filename ? props.file.original_filename : 'File'}&nbsp;
        ({filesize(props.file.filesize)})
    </a>
}

FileDownload.propTypes = {
    file: PropTypes.object.isRequired,
    show_filename: PropTypes.bool
}


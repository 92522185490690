import React from 'react'
import { Container, Col } from 'react-bootstrap'
import Layout from "../../Components/Layout/Layout"


const PageNotFound = props => {

    return <Layout {...props}>
        <section className={`p-5 text-center`}>
            <Container>
                <Col>
                    <h1>404</h1>
                    <p>Uh-oh! The page you were looking for can't be found.</p>
                    <a href="/"><button className="btn btn-primary">Go home</button></a>
                </Col>
            </Container>
        </section>
    </Layout>

}

export default PageNotFound;
import React, {Component} from 'react';
import PropTypes from 'prop-types';

class Tab extends Component {

    render() {
        return <div className="tab-content">
            {this.props.children}
        </div>
    }
}

Tab.defaultProps = {
    can_close: false,
    confirm_close: false
};
Tab.propTypes = {
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    can_close: PropTypes.bool,
    confirm_close: PropTypes.bool
};

export default Tab;
import React, {Component} from 'react';
import moment from "moment";
import FormElementTextArea from "../Form/Element/TextArea";
import FormRow from "../Form/Row";
import {Api} from "../Api/Api";
import {EventDispatcher} from "../../Events/Dispatcher";
import _ from 'lodash';

class InvoiceQueryRow extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: this.props.open,
            new_entry: '',
            history: this.props.query.history.data
        }
    }

    handleNew(e) {
        e.preventDefault();
        Api.post('/agency/query/' + this.props.query.id + '/comment', {comment: this.state.new_entry}).then((response) => {
            if (response.ok) {
                EventDispatcher.dispatch('reload-invoice', this.props.query.invoice_id);
                let history = _.clone(this.state.history);
                history.push(response.data.data);
                this.setState({new_entry: '', history: history});
            }
        });
    }

    render() {
        let query = this.props.query;
        let rows = [
            <tr>
                <td>{moment(query.date_created).format('DD/MM/YYYY HH:mm')}</td>
                {query.invoice ?
                    <td><a href={'/invoice/' + query.invoice.data.invoice_id}>{query.invoice.data.invoice_ref}</a>
                    </td> : null}
                <td>{query.created_by.data.name}</td>
                <td>{query.query}</td>
                <td>{query.resolved ? <i className="fa fa-check text-green"/> :
                    <i className="fa fa-times text-red"/>}</td>
                <td>
                    <button className="btn btn-default" onClick={(e) => this.setState({open: !this.state.open})}><i
                        className={this.state.open ? 'fa fa-arrow-up' : 'fa fa-arrow-down'}/></button>
                </td>
            </tr>
        ];
        if (this.state.open) {
            rows.push(<tr>
                <td colSpan={query.invoice ? 6 : 5}>
                    <table className="table table-bordered table-striped">
                        <thead>
                        <tr>
                            <th>Date</th>
                            <th>From</th>
                            <th>Entry</th>
                        </tr>
                        </thead>
                        <tbody>
                        {this.state.history.map((history) => {
                            let user = 'Automated Entry';
                            if (history.admin_user.data.id) {
                                user = history.admin_user.data.name;
                            }
                            if (history.user.data.id) {
                                user = history.user.data.name;
                            }
                            return <tr key={history.id}>
                                <td>{moment(history.date).format('DD/MM/YYYY HH:mm')}</td>
                                <td>{user}</td>
                                <td>{history.entry}</td>
                            </tr>
                        })}
                        </tbody>
                    </table>
                    <FormRow label="Add New Entry">
                        <FormElementTextArea name="new_entry" value={this.state.new_entry}
                                             onChange={(e) => this.setState({new_entry: e.target.value})}/>
                    </FormRow>
                    <button className="btn btn-success" onClick={(e) => this.handleNew(e)}>Save</button>
                </td>
            </tr>)
        }

        return rows;
    }
}

export default InvoiceQueryRow;
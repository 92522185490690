import React from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

const Footer = () => {

    return <section className={`footer`}>
        <Container>
            <Row>
                <Col sm={12} className={`text-center`}>
                    <img className={`mb-2`} src={`/assets/images/logos/group-black.svg`} width={`80px`} />
                    <p>&copy; Revolution Bars Group 2021</p>
                </Col>
            </Row>
        </Container>
    </section>

}
export default Footer
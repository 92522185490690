import React, {Component} from 'react';
import Layout from "../Components/Layout/Layout";
import FormFilter from "../Components/Form/Filter";
import withAuth from "../Components/Higher/Authentication/Authentication";
import {Redirect} from "react-router-dom";
import {Api, CurrencyFormatter} from "../Components/Api/Api";
import moment from 'moment';
import _ from 'lodash';
import Pagination from "../Components/Utility/Pagination/Pagination";

import Jumbotron from "react-bootstrap/Jumbotron";
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Loading from "../Components/Utility/Loading/Loading";

class Bookings extends Component {
    constructor(props) {
        super(props);
        this.state = {
            filter: {
                search: this.props.match.params[0] ? this.props.match.params[0] : '',
                start_date: moment().format('YYYY-MM-DD'),
                end_date: '',
                bar_id: '',
                status: 1,
                per_page: 25,
                page: 1
            },
            loading: true,
            bookings: [],
            pagination: {}
        }
    }

    handleFilterChange(filter) {
        this.setState({filter: filter}, this.updateBookings);
    }

    updateBookings() {
        let filter = _.clone(this.state.filter);
        this.setState({loading: true})
        Object.keys(filter).forEach((key) => (filter[key] == null || filter[key] === '') && delete filter[key]);
        Api.get('agency/agency-bookings', filter).then((response) => {
            if (response.ok) {
                this.setState({
                    bookings: response.data.data,
                    pagination: response.data.meta.pagination,
                    loading: false
                });
            }
        });
    }

    handlePageChange(page) {
        let filter = _.clone(this.state.filter);
        filter.page = page;
        this.handleFilterChange(filter);
    }

    render() {
        if (!this.props.auth.authenticated) {
            return <Redirect push to="/login"/>
        }
        let filters = [
            {
                name: 'search',
                label: 'Search',
                type: 'text',
                value: this.state.filter.search
            },
            {
                name: 'start_date',
                label: 'Start Date',
                type: 'date',
                value: this.state.filter.start_date
            },
            {
                name: 'end_date',
                label: 'End Date',
                type: 'date',
                value: this.state.filter.end_date
            },
            {
                name: 'bar_id',
                label: 'Location',
                type: 'bar_select',
                value: this.state.filter.bar_id
            },
            {
                name: 'status',
                label: 'Status',
                type: 'select',
                value: this.state.filter.status,
                include_blank_option: true,
                blank_option_text: 'Any status',
                values: [
                    {key: '1', value: 'Confirmed'},
                    {key: '3', value: 'Pending'},
                    {key: '4', value: 'Deleted'},
                ]
            }
        ];
        return <Layout {...this.props}>
            <Jumbotron fluid>
                <Container>
                    <Row>
                        <Col sm={6}>
                            <h2>Bookings</h2>
                            <p className={`mb-0 bigger`}>Search your current, and past bookings</p>
                        </Col>
                        <Col sm={6} className={`text-right`}>
                            <a href={`/bookings/new`}>
                                <button className={`btn btn-primary`}><i className={`fa fa-plus mr-2`}/>New booking
                                </button>
                            </a>
                        </Col>
                    </Row>
                </Container>
            </Jumbotron>
            <Container>
                <Row>
                    <Col>
                        <FormFilter filter={this.state.filter} filters={filters} storeState={false} name="bookings"
                                    handleChange={filter => this.handleFilterChange(filter)}
                                    disabled={this.state.loading}/>
                        <Pagination pagination={this.state.pagination} disabled={this.state.loading}
                                    changePage={(page) => this.handlePageChange(page)}/>
                        <table className="table table-bordered table-striped">
                            <thead>
                            <tr>
                                <th>Reference</th>
                                <th>Booking Date</th>
                                <th>Lead Booker Name</th>
                                <th>Location</th>
                                <th>Status</th>
                                <th>Options</th>
                                <th>Total Value</th>
                            </tr>
                            </thead>
                            {this.state.loading ? <tbody>
                                <tr>
                                    <td colSpan={7}><Loading/></td>
                                </tr>
                                </tbody> :
                                <tbody>
                                {this.state.bookings.map(function (booking) {
                                    return <tr key={booking.id}>
                                        <td><a href={"/bookings/" + booking.booking_id}>{booking.ref_code}</a></td>
                                        <td>{moment(booking.booking_date).format('DD/MM/YYYY HH:mm')} ({moment(booking.booking_date).fromNow()})</td>
                                        <td>{booking.forename} {booking.surname}</td>
                                        <td>{booking.bar.data.branded_name}</td>
                                        <td className={booking.status_class}>{booking.status_name}</td>
                                        <td>
                                            {booking.options.data.length > 0 ?
                                                <ul>
                                                    {booking.options.data.map(function (opt) {
                                                        return <li
                                                            key={opt.booking_variation_id}>{opt.head_count} x {opt.name} @ {CurrencyFormatter.format(opt.price)}</li>
                                                    })}
                                                </ul> : null}
                                        </td>
                                        <td>{CurrencyFormatter.format(booking.pbr)}</td>
                                    </tr>
                                })}
                                </tbody>
                            }
                        </table>
                        <Pagination pagination={this.state.pagination} changePage={(page) => this.handlePageChange(page)}/>
                    </Col>
                </Row>
            </Container>
        </Layout>
    }
}


export default withAuth(Bookings);
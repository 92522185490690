import React, {useState} from "react";
import Layout from "../Components/Layout/Layout.js"
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import withAuth from '../Components/Higher/Authentication/Authentication'
import {Redirect, withRouter} from "react-router-dom"
import FormRow from "../Components/Form/Row";
import FormElementText from "../Components/Form/Element/Text";
import {Api} from "../Components/Api/Api";


const Login = props => {

    const [credentials, setCredentials] = useState({
        email: '',
        password: ''
    })

    const [loading, setLoading] = useState(false);

    const [error, setError] = useState("")

    const handleLogin = (e) => {
        e.preventDefault()
        setLoading(true);
        Api.post('/agency/authenticate', {
            email: credentials.email,
            password: credentials.password
        }).then(function (data) {
            if (data.ok) {
                props.setAuth({
                    authenticated: true,
                    user: data.data
                });
                setLoading(false);
                return;
            }
            setLoading(false);
            setError(data.error);
        });
    }

    if (props.auth.authenticated) {
        return <Redirect push to={`/`}/>
    } else {
        return <Layout {...props}>
            <Container className={`mt-5`}>
                <Row>
                    <Col sm={12} md={{span: 6, offset: 3}}>
                        {error ? <div className="alert alert-danger">{error}</div> : null}
                        <form onSubmit={handleLogin}>
                            <FormRow label="E-mail Address">
                                <FormElementText name="username" value={credentials.username} email={true}
                                                 disabled={loading}
                                                 onChange={(e) => setCredentials({...credentials, ...{email: e.target.value}})}/>
                            </FormRow>
                            <FormRow label="Password">
                                <FormElementText password={true} name="password" value={credentials.password}
                                                 disabled={loading}
                                                 onChange={(e) => setCredentials({...credentials, ...{password: e.target.value}})}/>
                            </FormRow>
                            <div className="row align-items-center">
                                <div className="col-6">
                                    <a href="/forgot-password">Forgot Password</a>
                                </div>
                                <div className="col-6">
                                    <button className="btn btn-primary pull-right" disabled={loading}>
                                        {loading ? <i className="fa fa-cog fa-spin"/> : null}
                                        Login
                                    </button>
                                </div>
                            </div>
                        </form>
                    </Col>
                </Row>
            </Container>
        </Layout>
    }

}
export default withRouter(withAuth(Login))
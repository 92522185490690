import React, {useState} from 'react';
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Layout from "../Components/Layout/Layout";
import FormRow from "../Components/Form/Row";
import FormElementText from "../Components/Form/Element/Text";
import {Api} from "../Components/Api/Api";

export default function ForgotPassword(props) {
    const [email, setEmail] = useState('');
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState('');
    const [error, setError] = useState('');
    const handleLogin = (e) => {
        e.preventDefault();
        setLoading(true);
        setMessage('');
        setError('');
        Api.post('/agency/create-password-reset', {email: email}).then((response) => {
            setLoading(false);
            if (!response.ok) {
                if (response.status === 400) {
                    setError(response.data.errors.fields[0].error);
                } else {
                    setError(response.error);
                }
                return;
            }
            setMessage('An e-mail has been sent to you, click on the link contained within that message to continue');
            setEmail('');
        });
    }
    return <div>
        <Layout {...props}>
            <Container className={`mt-5`}>
                <Row>
                    <Col sm={12} md={{span: 6, offset: 3}}>
                        <div className="alert alert-info">Forgot your password? No dramas, enter the e-mail address you
                            use to login below and we'll get you back in!
                        </div>
                        {message ? <div className="alert alert-success">{message}</div> : null}
                        {error ? <div className="alert alert-danger">{error}</div> : null}
                        <form onSubmit={handleLogin}>
                            <FormRow label="E-mail Address">
                                <FormElementText name="username" value={email} email={true}
                                                 disabled={loading}
                                                 onChange={(e) => setEmail(e.target.value)}/>
                            </FormRow>
                            <button className="btn btn-primary pull-right" disabled={loading}>
                                {loading ? <i className="fa fa-cog fa-spin"/> : null}
                                Login
                            </button>
                        </form>
                    </Col>
                </Row>
            </Container>
        </Layout>
    </div>
}
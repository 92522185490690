import {Redirect} from "react-router-dom";
import React, {useEffect, useState} from "react";
import Layout from '../Components/Layout/Layout'
import withAuth from "../Components/Higher/Authentication/Authentication";
import Jumbotron from "react-bootstrap/Jumbotron";
import Container from "react-bootstrap/Container";
import Loading from "../Components/Utility/Loading/Loading";
import {Api} from "../Components/Api/Api";
import {ListGroup, ListGroupItem} from "react-bootstrap";
import moment from "moment";

const Bars = props => {

    const [name, setName] = useState('');
    const [loading, setLoading] = useState(true)
    const [barLoading, setBarLoading] = useState(false)
    const [bars, setBars] = useState({});
    const [filteredBars, setFilteredBars] = useState(bars);
    const [openBar, setOpenBar] = useState();
    const [openBarId, setOpenBarId] = useState();
    const [error, setError] = useState();
    const [barImage, setBarImage] = useState(null);
    const [lastUpdated, setLastUpdated] = useState(null);

    const filter = (e) => {
        const keyword = e.target.value;
        handleBarChange(null)
        setOpenBarId(null)
        window.history.pushState(null, 'Search: ' + keyword, '/bars');

        if (keyword !== '') {
            const results = bars.filter((bar) => {
                return bar.alias.toLowerCase().indexOf(keyword.toLowerCase()) > -1;
            });
            setFilteredBars(results);
        } else {
            setFilteredBars(bars);
        }

        setName(keyword);
    };

    const handleBarChange = (barSummary) => {
        setBarImage(null)
        if (barSummary !== null) {
            setBarLoading(true)
            window.scrollTo(0, 0)
            Api.getCached('rest/bar-agency', {bar_id: barSummary.id, include: 'images,last_updated,opening_times_new'}, function (response) {
                if (response.ok) {
                    let bar = response.data.data
                    setOpenBar(bar)
                    setOpenBarId(bar.id)
                    setBarLoading(false)
                    window.history.pushState(Api.slug(barSummary.alias), bar.bar_name, '/bars/' + Api.slug(barSummary.alias));
                    if (bar.images.data) {
                        bar.images.data.map(function (image) {
                            if (barImage === null &&
                                (image.url.endsWith('gif') || image.url.endsWith('png') || image.url.endsWith('jpg') || image.url.endsWith('jpeg'))
                            ) {
                                setBarImage(image.url)
                            }
                        })
                    }
                    setLastUpdated(bar.last_updated)
                } else {
                    setOpenBar(null)
                    setBarLoading(false)
                    setError(response.data.errors.message)
                }
            })
        } else {
            setOpenBarId(null)
            setOpenBar(null)
        }
    }

    useEffect(() => {
        Api.getAbsolute('https://rbg-public-data.s3-eu-west-1.amazonaws.com/bar-data.json').then((response) => {
            if (response.ok) {
                const bars = response.data.filter((bar) => {
                    return bar.open && !bar.central_bar;
                })
                setBars(bars)
                setFilteredBars(bars)
                setLoading(false)

                let barSlug = props.match.params[0];
                if (barSlug !== undefined) {
                    let foundBar = bars.filter((bar) => {
                        return Api.slug(bar.alias) === barSlug;
                    })
                    if (foundBar.length > 0) {
                        handleBarChange(foundBar[0])
                    }
                }
            }
        });
    }, [props.match.params[0]])

    if (loading) {
        return <Loading/>
    }

    if (!props.auth.authenticated) {
        return <Redirect push to="/login"/>
    }

    return <Layout {...props}>
        <Jumbotron fluid>
            <Container>
                <h2>{openBar ? openBar.bar_name : 'Bars'}</h2>
                <small>{openBar ? openBar.short_description : 'Information regarding each bar'}</small>
            </Container>
        </Jumbotron>
        <Container>
            <div className="row mb-5">
                <div className="col-md-12">
                    <input
                        type="search"
                        value={name}
                        onChange={filter}
                        className="form-control"
                        placeholder="Search bars..."
                    />
                </div>
            </div>
            {filteredBars && filteredBars.length > 0 ? (
                <div className="row">
                    <div className="col-md-3">
                        <ListGroup>
                            {filteredBars.map(function (bar) {
                                return <ListGroupItem
                                    key={bar.id}
                                    className={bar.id === openBarId ? 'list-group-item-warning' : ''}
                                    action
                                    onClick={() => handleBarChange(bar)}
                                >
                                    <small>{bar.name}
                                        {bar.id === openBarId ? <span className="fa fa-chevron-right pull-right"
                                                                      style={{marginTop: '8px'}}></span> : ''}</small>
                                </ListGroupItem>
                            })}
                        </ListGroup>
                    </div>
                    <div className="col-md-9">
                        <ListGroup>
                            {barLoading ? <Loading/> :
                                (openBar ?
                                        <div className="card">
                                            <img
                                                className="card-img-top img-fluid"
                                                src={barImage === null ? "https://revolution-bars.s3.amazonaws.com/www.revolution-bars.co.uk/web/images/uploads/1624524831.jpg" : barImage}
                                                style={{
                                                    'width': '100%',
                                                    'height': barImage === null ? '125px' : '250px',
                                                    'objectFit': 'cover'
                                                }}
                                            />

                                            <div className="card-body">
                                                <h4 className="card-title">{openBar ? openBar.bar_name : ''}</h4>

                                                <div className="row">
                                                    {openBar.overview.trim() !== '' ?
                                                        <div className="col-md-12 mb-4">
                                                            <p className="card-text"
                                                               dangerouslySetInnerHTML={{'__html': openBar.overview}}></p>
                                                        </div> : ''}
                                                    <div
                                                        className="col-md-12 mb-4">
                                                        <div className="card h-100">
                                                            <div className="card-header rbg-text">
                                                                <span className="fa fa-clock-o mr-2"></span>
                                                                Opening Times
                                                            </div>
                                                            <div className="card-body">
                                                                {openBar.opening_times_new ? <table
                                                                    className="table table-hover table-bordered table-condensed"
                                                                    style={{borderLeft: 'none', borderTop: 'none'}}
                                                                >
                                                                    <thead>
                                                                    <tr>
                                                                        <th style={{borderLeft: 'none', borderTop: 'none', borderBottom: 'none'}}></th>
                                                                        <th style={{backgroundColor: '#f5f5f5', borderBottom: 'none'}} colSpan={2} className="text-center">Bar</th>
                                                                        <th style={{backgroundColor: '#f5f5f5', borderBottom: 'none'}} colSpan={2} className="text-center">Food</th>
                                                                    </tr>
                                                                    <tr>
                                                                        <th style={{borderLeft: 'none', borderTop: 'none', borderBottom: 'none'}}></th>
                                                                        <th style={{backgroundColor: '#f5f5f5', borderBottom: 'none'}} className="text-center">Open</th>
                                                                        <th style={{backgroundColor: '#f5f5f5', borderBottom: 'none'}} className="text-center">Close</th>
                                                                        <th style={{backgroundColor: '#f5f5f5', borderBottom: 'none'}} className="text-center">Start</th>
                                                                        <th style={{backgroundColor: '#f5f5f5', borderBottom: 'none'}} className="text-center">Finish</th>
                                                                    </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                    {openBar.opening_times_new.data.map(function (openingTime) {
                                                                        if (openingTime.closed) {
                                                                            return <tr>
                                                                                <td style={{backgroundColor: '#f5f5f5'}} className="text-right act"><strong>{openingTime.day}</strong></td>
                                                                                <td colSpan={4}>Closed</td>
                                                                            </tr>
                                                                        }
                                                                        return <tr>
                                                                            <td style={{backgroundColor: '#f5f5f5'}} className="text-right act"><strong>{openingTime.day}</strong></td>
                                                                            <td className="text-center">{moment(openingTime.open, 'HH:mm:ss').format('h:mm a')}</td>
                                                                            <td className="text-center">{moment(openingTime.close, 'HH:mm:ss').format('h:mm a')}</td>
                                                                            <td className="text-center">{moment(openingTime.food_start, 'HH:mm:ss').format('h:mm a')}</td>
                                                                            <td className="text-center">{moment(openingTime.food_end, 'HH:mm:ss').format('h:mm a')}</td>
                                                                        </tr>
                                                                    })}
                                                                    </tbody>
                                                                </table> : (openBar.opening_times.trim().length > 0 ?
                                                                    <span
                                                                        dangerouslySetInnerHTML={{'__html': Api.nl2br(openBar.opening_times)}}></span>
                                                                    : <p className="alert alert-danger">No information
                                                                        about opening times. Please contact the bar.</p>)}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6 mb-4">
                                                        <div className="card h-100">
                                                            <div className="card-header rbg-text">
                                                                <span className="fa fa-address-card-o mr-2"></span>
                                                                Contact Information
                                                            </div>
                                                            <div className="card-body">
                                                                {openBar.address.trim().length > 0 || openBar.phone.trim().length > 0 ? <div>
                                                                        <address>
                                                                            <span
                                                                                dangerouslySetInnerHTML={{'__html': Api.nl2br(openBar.address)}}></span>
                                                                            <br/>{openBar.postcode}</address>
                                                                        <span>{openBar.phone}</span></div>
                                                                    : <p className="alert alert-danger">No details
                                                                        available. Please contact the bar for
                                                                        information.</p>}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6 mb-4">
                                                        <div className="card h-100">
                                                            <div className="card-header rbg-text">
                                                                <span className="fa fa-wheelchair mr-2"></span>
                                                                Disabled Access
                                                            </div>
                                                            <div className="card-body">
                                                                {openBar.disabled_access ?
                                                                    <span
                                                                        dangerouslySetInnerHTML={{'__html': Api.nl2br(openBar.disabled_access)}}></span>
                                                                    :
                                                                    <p className="alert alert-danger">No information regarding
                                                                        disabled access available. Please contact the bar for
                                                                        information.</p>}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12 mb-4">
                                                        <div className="card h-100">
                                                            <div className="card-header rbg-text">
                                                                <span className="fa fa-glass mr-2"></span>
                                                                Masterclass Policy
                                                            </div>
                                                            <div className="card-body">
                                                                {openBar.masterclass_policy ?
                                                                    <span
                                                                        dangerouslySetInnerHTML={{'__html': Api.nl2br(openBar.masterclass_policy)}}></span>
                                                                    :
                                                                    <p className="alert alert-danger">No details regarding masterclass policy. Please contact the bar for
                                                                        information.</p>}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12 mb-4">
                                                        <div className="card h-100">
                                                            <div className="card-header rbg-text">
                                                                <span className="fa fa-map-signs mr-2"></span>
                                                                Area Restrictions
                                                            </div>
                                                            <div className="card-body">
                                                                {openBar.area_restrictions ?
                                                                    <span
                                                                        dangerouslySetInnerHTML={{'__html': Api.nl2br(openBar.area_restrictions)}}></span>
                                                                    :
                                                                    <p className="alert alert-danger">No details regarding area restrictions available. Please contact the bar for
                                                                        information.</p>}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12 mb-4">
                                                        <div className="card h-100">
                                                            <div className="card-header rbg-text">
                                                                <span className="fa fa-ban mr-2"></span>
                                                                General Booking Restrictions
                                                            </div>
                                                            <div className="card-body">
                                                                {openBar.general_booking_restrictions ?
                                                                    <span
                                                                        dangerouslySetInnerHTML={{'__html': Api.nl2br(openBar.general_booking_restrictions)}}></span>
                                                                    :
                                                                    <p className="alert alert-warning">No further information, please contact the bar if you have questions.</p>}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6 mb-4">
                                                        <div className="card h-100">
                                                            <div className="card-header rbg-text">
                                                                <span className="fa fa-car mr-2"></span>
                                                                Car Parking
                                                            </div>
                                                            <div className="card-body">
                                                                {openBar.car_parking ?
                                                                    <span
                                                                        dangerouslySetInnerHTML={{'__html': Api.nl2br(openBar.car_parking)}}></span>
                                                                    :
                                                                    <p className="alert alert-warning">No information regarding parking, please contact the bar if you have questions.</p>}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6 mb-4">
                                                        <div className="card h-100">
                                                            <div className="card-header rbg-text">
                                                                <span className="fa fa-umbrella mr-2"></span>
                                                                Cloakroom Policy
                                                            </div>
                                                            <div className="card-body">
                                                                {openBar.cloakroom_policy ?
                                                                    <span
                                                                        dangerouslySetInnerHTML={{'__html': Api.nl2br(openBar.cloakroom_policy)}}></span>
                                                                    :
                                                                    <p className="alert alert-warning">No information regarding cloakroom, please contact the bar if you have questions.</p>}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6 mb-4">
                                                        <div className="card h-100">
                                                            <div className="card-header rbg-text">
                                                                <span className="fa fa-money mr-2"></span>
                                                                Entrance Charges
                                                            </div>
                                                            <div className="card-body">
                                                                {openBar.entrance_charges ?
                                                                    <span
                                                                        dangerouslySetInnerHTML={{'__html': Api.nl2br(openBar.entrance_charges)}}></span>
                                                                    :
                                                                    <p className="alert alert-warning">No information about entrance charges, please contact the bar if you have questions.</p>}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6 mb-4">
                                                        <div className="card h-100">
                                                            <div className="card-header rbg-text">
                                                                <span className="fa fa-child mr-2"></span>
                                                                Under 18s Policy
                                                            </div>
                                                            <div className="card-body">
                                                                {openBar.under_18_policy ?
                                                                    <span
                                                                        dangerouslySetInnerHTML={{'__html': Api.nl2br(openBar.under_18_policy)}}></span>
                                                                    :
                                                                    <p className="alert alert-warning">No information regarding under 18s, please contact the bar if you have questions.</p>}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6 mb-4">
                                                        <div className="card h-100">
                                                            <div className="card-header rbg-text">
                                                                <span className="fa fa-music mr-2"></span>
                                                                Music Policy
                                                            </div>
                                                            <div className="card-body">
                                                                {openBar.music_policy ?
                                                                    <span
                                                                        dangerouslySetInnerHTML={{'__html': Api.nl2br(openBar.music_policy)}}></span>
                                                                    :
                                                                    <p className="alert alert-warning">No information regarding music, please contact the bar if you have questions.</p>}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6 mb-4">
                                                        <div className="card h-100">
                                                            <div className="card-header rbg-text">
                                                                <span className="fa fa-black-tie mr-2"></span>
                                                                Dress Code
                                                            </div>
                                                            <div className="card-body">
                                                                {openBar.dress_code ?
                                                                    <span
                                                                        dangerouslySetInnerHTML={{'__html': Api.nl2br(openBar.dress_code)}}></span>
                                                                    :
                                                                    <p className="alert alert-warning">No information regarding a dress code, please contact the bar if you have questions.</p>}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="card-footer">
                                                <p className="card-text"><small className="text-muted">
                                                    Last updated <strong>{lastUpdated}</strong>
                                                </small></p>
                                            </div>
                                        </div> : <div>
                                            {error ? <div>
                                                <p className="alert alert-danger">There was a problem getting data for
                                                    that bar. Details: {error}</p>
                                            </div> : ''}
                                            <p className="text-center alert alert-warning">Select a bar from the list on
                                                the left to see its details.</p>
                                        </div>
                                )}
                        </ListGroup>
                    </div>
                </div>) : (
                <div className="row">
                    <div className="col-md-12">
                        <p className="alert alert-danger">No results found.</p>
                    </div>
                </div>
            )}
        </Container>
    </Layout>
}

export default withAuth(Bars)
import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import Loading from "../Utility/Loading/Loading";
import {Api} from "../Api/Api";
import filesize from 'filesize';

export default function DownloadSettlement(props) {
    const [file, setFile] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        Api.post('/agency/settlement/' + props.settlement.id + '/download').then((response) => {
            if (response.ok) {
                setFile(response.data.data);
                setLoading(false);
                return;
            }

            setError(response.error);
            setLoading(false);
        });
    }, []);

    if (error) {
        return <>
            <div className="alert alert-danger">{error}</div>
        </>
    }
    if (loading) {
        return <>
            <Loading/>
            <p style={{textAlign: 'center'}}>Generating File...</p>
        </>
    }
    return <div>
        <a href={file.uri} target="_blank">
            <i className="fa fa-download"/> Download {file.original_filename} ({filesize(file.filesize)})
        </a>
    </div>
}

DownloadSettlement.propTypes = {
    settlement: PropTypes.object.isRequired
}


import React, {Component} from 'react';
import PropTypes from 'prop-types';
import FormElementSelect from "./Select";
import Loading from "../../Utility/Loading/Loading";
import {Api} from '../../Api/Api';

class FormBarSelect extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            bars: []
        }
    }

    componentDidMount() {
        this.updateBars();
    }

    updateBars() {
        Api.getAbsolute('https://rbg-public-data.s3-eu-west-1.amazonaws.com/bar-data.json').then((response) => {
            if (response.ok) {
                this.setState({
                    bars: response.data.filter((b) => {
                        return b.open && !b.central_bar && b.frontend_visible
                    }), loading: false
                });
            }
        })
    }

    render() {
        if (this.state.loading) {
            return <Loading fontSize="34px"/>
        }
        return <FormElementSelect name={this.props.name} onChange={(e) => {
            let value = parseInt(e.target.value);
            let object = {};
            if (value) {
                object = this.state.bars.find((b) => {
                    return b.id === e.target.value;
                });
            }
            this.props.onChange({
                target: {
                    name: this.props.name,
                    value: value,
                    object: object
                }
            });
        }}
                                  useSelect2={true}
                                  values={this.state.bars.map((b) => {
                                      let id = b.id;
                                      if (this.props.returnField === 'cost_centre') {
                                          id = b.cost_centre;
                                      }
                                      return {
                                          key: id,
                                          value: `${b.brand} ${b.name.replace("Revolución de Cuba", "")}`,
                                          object: b
                                      }
                                  })} includeBlankOption={true} blankOptionText=" - Select -"
                                  selectedValue={this.props.selectedValue}/>
    }
}

FormBarSelect.propTypes = {
    onChange: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    selectedValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    returnField: PropTypes.oneOf(['bar_id', 'cost_centre'])
};

FormBarSelect.defaultProps = {
    returnField: 'bar_id'
};

export default FormBarSelect;

import React, { useEffect, useState } from 'react'
import withAuth from '../Components/Higher/Authentication/Authentication'
import Loading from '../Components/Utility/Loading/Loading'
import { Api, CurrencyFormatter } from '../Components/Api/Api'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Alert from 'react-bootstrap/Alert'
import SettlementInvoices from '../Components/Settlements/SettlementInvoices'
import LayoutWrapper from '../Utility/LayoutWrapper'
import Dropdown from 'react-bootstrap/Dropdown'
import { ModalActions } from '../Components/Utility/Modal/Modal'
import DownloadSettlement from '../Components/Settlements/DownloadSettlement'
import CancelSettlement from '../Components/Settlements/CancelSettlement'

const Settlement = (props) => {
    const [loading, setLoading] = useState(true)
    const [settlement, setSettlement] = useState({})
    useEffect(() => {
        let id = props.match.params[0]
        Api.get('/agency/settlement/' + id).then((response) => {
            if (response.ok) {
                setSettlement(response.data.data)
                setLoading(false)
            }
        })
    }, [])

    const handleCancel = (e) => {
        e.preventDefault()
        ModalActions.openModal('Download Settlement', <CancelSettlement settlement={settlement}/>)
    }
    const handleDownload = (e) => {
        e.preventDefault()
        ModalActions.openModal('Download Settlement', <DownloadSettlement settlement={settlement}/>)
    }
    if (loading) {
        return <Loading/>
    }
    return <LayoutWrapper {...props} title={'Settlement ' + settlement.reference} actions={<>
        <Dropdown className="pull-right">
            <Dropdown.Toggle className={`nav-link text-white`} size={`sm`}>
                Options
            </Dropdown.Toggle>
            <Dropdown.Menu>
                <Dropdown.Item onClick={(e) => handleDownload(e)}>
                    <i className={`fa fa-download mr-2`}/>Download Settlement Invoice
                </Dropdown.Item>
                <Dropdown.Item onClick={(e) => handleCancel(e)}>
                    <i className={`fa fa-trash mr-2`}/>Cancel Settlement
                </Dropdown.Item>
            </Dropdown.Menu>
        </Dropdown>
    </>}>
        <Row>
            <Col xs={12}>
                <Alert variant="warning">Please send remittance
                    of <strong>{CurrencyFormatter.format(settlement.total)}</strong> to bank account 12019909
                    sort code
                    01-00-39 with reference <strong>{settlement.reference}</strong></Alert>
            </Col>
        </Row>
        <Row>
            <Col xs={12}>
                <SettlementInvoices settlement_id={settlement.id}/>
            </Col>
        </Row>
    </LayoutWrapper>
}

export default withAuth(Settlement)


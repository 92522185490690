import React, {useEffect, useState} from 'react';
import Layout from "../Components/Layout/Layout";
import withAuth from "../Components/Higher/Authentication/Authentication";
import {Redirect} from "react-router-dom";
import {Api} from "../Components/Api/Api";

import BookingCard from '../Components/BookingCard/BookingCard'
import Jumbotron from "react-bootstrap/Jumbotron";
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Loading from "../Components/Utility/Loading/Loading";
import InvoiceDashboard from "./Invoices/InvoiceDashboard";


const useMountEffect = func => useEffect(func, [func])

const Dashboard = props => {

    const [loading, setLoading] = useState(true);
    const [bookings, setBookings] = useState([])
    const [invoices, setInvoices] = useState([])

    const getAgencyBookings = async () => {
        const response = await Api.get("/rest/bookings/agency");
        if (response.ok) {
            setBookings(response.data.data);
            setLoading(false);
        }
    }
    useEffect(() => {
        getAgencyBookings()
    }, [props])

    if (loading) {
        return <Loading/>
    }
    if (!props.auth.authenticated) {
        return <Redirect push to="/login"/>
    }
    let intro_text = '';
    if (props.user.bookings && props.user.invoices) {
        intro_text = 'You have ' + bookings.length + ' upcoming bookings and ' + invoices.length + ' invoices outstanding';
    } else if (props.user.bookings) {
        intro_text = 'You have ' + bookings.length + ' upcoming bookings';
    } else if (props.user.invoices) {
        intro_text = 'You have ' + invoices.length + ' outstanding invoices';
    }
    return <Layout {...props}>
        <Jumbotron fluid>
            <Container>
                <Row>
                    <Col>
                        <h2>Welcome, {props.auth.user.data.user.data.name}!</h2>
                        <p className={`mb-0 bigger`}>{intro_text}</p>
                    </Col>
                </Row>
            </Container>
        </Jumbotron>
        <Container>
            {props.user.bookings ? <>
                <Row className={`mb-3 mt-5`}>
                    <Col sm={12}>
                        <h3 className={`lead mb-3 pull-left`}>Upcoming Bookings</h3>
                        <a className="btn btn-primary pull-right" href="/bookings/new"><i className="fa fa-plus"/> Add
                            Booking</a>
                    </Col>
                </Row>
                {bookings.length === 0 &&
                <Row>
                    <Col>
                        <Jumbotron className={`text-center`}>
                            <h5>You have no upcoming bookings 😭</h5>
                            <p className={`mb-0`}>Click <a href={`/bookings/new`}>here</a> to create a booking</p>
                        </Jumbotron>
                    </Col>
                </Row>
                }
                <Row>
                    {bookings.map(booking =>
                        <Col sm={12} lg={4} key={booking.id}>
                            <BookingCard {...booking} />
                        </Col>
                    )}
                </Row>
            </> : null}

            {props.user.invoices ? <>
                <Row className={`mt-3`}>
                    <Col sm={12}>
                        <h3 className={`lead mb-3`}>Invoices</h3>
                    </Col>
                </Row>

                {invoices.length === 0 &&
                <Row>
                    <Col>
                        <Jumbotron className={`text-center`}>
                            <InvoiceDashboard {...props}/>
                        </Jumbotron>
                    </Col>
                </Row>
                }
            </> : null}

        </Container>
    </Layout>
}

export default withAuth(Dashboard)
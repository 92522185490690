import React from 'react';
import {CurrencyFormatter} from '../Api/Api';
import PropTypes from 'prop-types';
import moment from 'moment';
import {ModalActions} from '../Utility/Modal/Modal';
import DownloadInvoice from './DownloadInvoice';

const InvoiceRow = (props) => {
  let invoice = props.invoice;
  return <tr>
    <td>{moment(invoice.date_added).format('DD/MM/YYYY')}</td>
    <td>{moment(invoice.due_date).format('DD/MM/YYYY')}</td>
    <td>{moment(invoice.booking_date).format('DD/MM/YYYY HH:mm')}</td>
    <td><a href={'/invoice/' + invoice.invoice_id}>{invoice.invoice_ref}</a>
    </td>
    {props.show_booking_ref ?
        <td><a
            href={'/bookings/' + invoice.booking_id}>{invoice.booking_ref}</a>
        </td> :
        null}
    {props.show_booker_name ?
        <td>{invoice.forename} {invoice.surname}</td> :
        null}
    <td>{CurrencyFormatter.format(invoice.balance_due)}</td>
    <td className={invoice.status_class}>{invoice.status_name}</td>
    <td>
      <button className="btn btn-success btn-sm" onClick={(e) => {
        ModalActions.openModal('Download Invoice', <DownloadInvoice
            invoice_id={invoice.invoice_id}/>);
      }}>
        <i className="fa fa-download"/>
      </button>
    </td>
  </tr>;
};

InvoiceRow.propTypes = {
  invoice: PropTypes.object.isRequired,
  show_booking_ref: PropTypes.bool,
  show_booker_name: PropTypes.bool,
};

export default InvoiceRow;
import Loading from "../Utility/Loading/Loading";
import React, {useEffect, useState} from "react";
import withAuth from "../Higher/Authentication/Authentication";
import {Api} from "../Api/Api";

const EditChoices = (props) => {

    const [loading, setLoading] = useState(true)
    const [visualPackage, setVisualPackage] = useState({
        options: [],
        selected_options: [],
        total_to_select: 0,
        total_selected: 0,
        total_choices: 0,
    })
    const [optionTabs, setOptionTabs] = useState( {
        all_open: true,
        all_collapsed: false,
    })
    const [totalChoiceGroups, setTotalChoiceGroups] = useState(0);
    const [totalChoices, setTotalChoices] = useState(0);
    const [totalSelected, setTotalSelected] = useState(0);
    const [headCount, setHeadCount] = useState(0);
    const [canChangeNumber, setCanChangeNumber] = useState(true)
    const [preOrder, setPreOrder] = useState([])
    const [booking, setBooking] = useState({})
    const [postBooking, setPostBooking] = useState({})
    const [options, setOptions] = useState({})
    const [saving, setSaving] = useState(false)

    useEffect(() => {
        let head_count = props.quantity;
        let option_tabs = {
            all_open: true,
            all_collapsed: false,
        }

        setBooking(props.booking)
        setPostBooking(props.post_booking)
        setOptions(props.booking.options.data)

        let pre_order = props.option.pre_order ? props.option.pre_order.data : [];

        setHeadCount(head_count)

        let pre_order_items = [];

        pre_order.forEach((item) => {
            let identifier = item.choice_id + '_' + item.choice_item_id + '_' + item.plu;
            if (pre_order_items[identifier] !== undefined) {
                pre_order_items[identifier] = (pre_order_items[identifier] + 1);
                item.item_number = pre_order_items[identifier];
            } else {
                pre_order_items[identifier] = 1;
                item.item_number = 1;
            }
        })

        if (preOrder.length === 0) {
            setPreOrder(pre_order);
        }

        let total_choice_groups = 0, total_choices = 0, total_selected = 0;
        let vsp = JSON.parse(JSON.stringify(visualPackage));
        vsp.variation_id = props.option.variation_id;
        if (props.option.choice_array) {
            props.option.choice_array.forEach((choice_group) => {
                choice_group.choices.forEach((choice) => {
                    let option = {
                        choices: [],
                        title: choice.title,
                        total_to_select: head_count,
                        total_selected: 0,
                        has_solo_choice: false,
                        choice_id: choice_group.group_id,
                        price: props.option.price
                    }
                    option.choice_group_id = choice.id;
                    option_tabs[choice.id] = true;
                    choice.items.forEach((item) => {
                        var package_choice = {
                            title: item.title,
                            display_title: item.title + (item.premium ? ' (+' + Api.currencyFormatter.format(item.premium) + ')' : ''),
                            premium: item.premium,
                            selected: 0,
                            choice_item_id: choice.id,
                            choice_id: choice_group.group_id,
                            plu: item.plu,
                            id: item.id,
                            is_selectable: item.selectable,
                            pid: choice_group.group_id + '_' + choice.id + '_' + item.plu,
                            total_selected: 0,
                        }
                        option.choices.push(package_choice)
                        total_choices++;
                    })
                    total_choice_groups++;
                    if (option.choices.length === 1) {
                        option.has_solo_choice = true;
                        option.total_selected = head_count;
                        option.choices[0].selected = head_count;
                        total_selected += head_count;
                    }
                    vsp.options.push(option)
                })
            })
        }

        if (props.option.pre_order) {
            props.option.pre_order.data.forEach(function (pre_order_choice) {
                let choice = null;
                let pid = pre_order_choice.choice_id + '_' + pre_order_choice.choice_item_id + '_' + pre_order_choice.plu;
                choice = vsp.options.find(function (option) {
                    return option.choice_group_id === pre_order_choice.choice_item_id;
                })
                if (choice && !choice.has_solo_choice) {
                    let found_choice_item = choice.choices.find(function (choice_item) {
                        return choice_item.pid === pid;
                    })
                    if (found_choice_item) {
                        choice.total_selected = (choice.total_selected + 1);
                        found_choice_item.selected = (found_choice_item.selected + 1);
                        total_selected++;
                    }
                }
            })
        }

        // If there's a lot of choices, keep tidy by closing all tabs on initial load
        if (total_choices > 40 && total_choice_groups > 3) {
            Object.keys(option_tabs).forEach(function (tab) {
                option_tabs[tab] = false;
                option_tabs['all_open'] = false;
                option_tabs['all_collapsed'] = true;
            })
        }

        // Update our totals and we're done
        vsp.total_choices = total_choices;
        vsp.total_choice_groups = total_choice_groups;
        vsp.total_selected = total_selected;
        vsp.total_to_select = vsp.options.length * head_count;

        setTotalChoices(total_choices)
        setTotalChoiceGroups(total_choice_groups)
        setTotalSelected(total_selected)
        setVisualPackage(vsp)
        setOptionTabs(option_tabs)

        setLoading(false);

    }, [])

    const toggleTab = (choice_id) => {
        let option_tabs = JSON.parse(JSON.stringify(optionTabs));
        option_tabs[choice_id] = !optionTabs[choice_id];
        let all_open = true;
        let all_collapsed = true
        Object.keys(option_tabs).forEach(function (option) {
            if (option !== 'all_open' && option !== 'all_collapsed') {
                if (option_tabs[option] === false) {
                    all_open = false;
                }
                if (option_tabs[option] === true) {
                    all_collapsed = false;
                }
            }
        })
        option_tabs['all_open'] = all_open;
        option_tabs['all_collapsed'] = all_collapsed;
        setOptionTabs(option_tabs)
    }

    const changeQuantity = (choice, direction = 'increase', can_action) => {
        if (!can_action) {
            return;
        }
            // var pre_order = JSON.parse(JSON.stringify(this.state.pre_order))
            let vsp = JSON.parse(JSON.stringify(visualPackage));
            let pre_order = JSON.parse(JSON.stringify(preOrder))

            let found_choice = null, found_options = null;

            // Grab the choice and its group that we're adding/removing
            vsp.options.forEach(function (option) {
                let result = option.choices.find(function (c) {
                    return c.pid === choice.pid;
                })

                if (result) {
                    found_choice = result;
                    found_options = option;
                }
            })

            // Change our visually grouped state
            if (direction === 'increase') {
                found_choice.selected = (found_choice.selected + 1);
                found_options.total_selected = (found_options.total_selected + 1);
                vsp.total_selected = (vsp.total_selected + 1);
            } else if (direction === 'decrease') {
                found_choice.selected = (found_choice.selected - 1);
                found_options.total_selected = (found_options.total_selected - 1);
                vsp.total_selected = (vsp.total_selected - 1);
            }

            // Change regular pre order state required to save to booking
            pre_order = changePreOrderState(pre_order, choice.title, choice.choice_id, choice.choice_item_id, found_choice.selected, choice.plu, direction);

            updateOptions(props.option.variation_id, vsp.total_selected, found_choice.price, pre_order)
            setPreOrder(pre_order)
            setVisualPackage(vsp)
    }

    const getItem = (choice_group_id, choice_id, plu) => {
        let return_item = {
            name: '',
            premium: 0
        };
        props.option.choice_array.forEach((choice_group) => {
            choice_group.choices.forEach((choice) => {
                choice.items.forEach((item) => {
                    if (choice_group.group_id.toString() === choice_group_id.toString() &&
                        choice.id.toString() === choice_id.toString() &&
                        item.plu.toString() === plu.toString()) {
                        return_item = item;
                    }
                });
            });
        });
        return return_item;
    }

    const changePreOrderState = (op, name, choice_id, choice_item_id, item_number, plu, direction = 'increase') => {
        let pre_order = JSON.parse(JSON.stringify(op));
        let found = false;
        let po_item = getItem(choice_id, choice_item_id, plu);
        let premium = po_item.premium;
        let item_name = po_item.title;
        if (direction === 'decrease') {
            item_number = (item_number + 1);
        }
        if (!plu) {
            pre_order = pre_order.filter(function (item) {
                return !(item.choice_id.toString() === choice_id.toString() &&
                    item.choice_item_id.toString() === choice_item_id.toString() &&
                    item.item_number.toString() === item_number.toString());

            });
            found = true;
        } else {
            pre_order = pre_order.map(function (item) {
                if (item.choice_id.toString() === choice_id.toString() &&
                    item.choice_item_id.toString() === choice_item_id.toString() &&
                    item.item_number === item_number && plu.toString() === item.plu.toString()
                ) {
                    item.plu = plu;
                    item.premium = premium;
                    item.name = item_name;
                    item.plu_name = item_name;
                    found = true;
                }
                return item;
            });
        }

        if (!found) {
            if (direction === 'increase') {
                pre_order.push({
                    id: null,
                    guest_id: 0,
                    variation_id: visualPackage.variation_id,
                    choice_id: choice_id,
                    choice_item_id: choice_item_id,
                    plu_name: item_name,
                    item_number: item_number,
                    plu: plu,
                    name: item_name,
                    premium: premium
                });
            }
        } else {
            if (direction === 'decrease') {
                pre_order = pre_order.filter(function (item) {
                    return !(item.choice_id === choice_id &&
                        item.choice_item_id === choice_item_id &&
                        item.item_number === item_number && item.plu === plu
                    )
                })
            }
        }

        return pre_order;
    }

    const updateOptions = (variation_id, quantity, price, pre_order) => {
        quantity = parseInt(quantity);
        let bkng = JSON.parse(JSON.stringify(booking))

        for (let index = 0; index < bkng.options.data.length; index++) {
            if (bkng.options.data[index].variation_id === variation_id) {
                // bkng.options.data[index].head_count = quantity;
                bkng.options.data[index].price = price;
                if (pre_order !== undefined) {
                    bkng.options.data[index].pre_order = {data: pre_order};
                    let premium = 0;
                    pre_order.forEach((item) => {
                        premium = premium + item.premium;
                    });

                    bkng.options.data[index].premium = premium;
                }
            }
        }

        setBooking(bkng)
        setOptions(bkng.options.data)
    }

    const saveBooking = () => {
        if (saving) {
            return;
        }

        postBooking['variants'] = JSON.stringify(options)
        postBooking['include'] = 'pre_orders'
        postBooking['options'] = options;
        setPostBooking(postBooking)
        setSaving(true)

        Api.postJson('/rest/bookings/agency/save', postBooking).then(response => {
            if (response.ok) {
                Api.showNotification('Done', 'Booking has been saved');
                setTimeout(() => window.location.reload(), 1500);
            }
        })
    }

    if (loading) {
        return <Loading/>
    }

    return <div>
        <div className="row mb-3">
            <div className="col-md-6">

            </div>
            <div className="col-md-6 text-right">
                <strong
                    style={{marginTop: '5px'}}
                    className={"pull-right " + (visualPackage.total_selected === visualPackage.total_to_select ? 'text-success' : 'text-warning')}
                >
                    {visualPackage.total_selected}/{visualPackage.total_to_select} total choices selected
                </strong>
            </div>
        </div>
        <div className="row">
            <div className="col-md-12">
                {visualPackage.options.map(function (option, index) {
                    let all_choices_made = option.total_selected === option.total_to_select;
                    let solo_choice = option.has_solo_choice;
                    return !solo_choice && <ul className="list-group" style={{marginBottom: '25px'}} key={index}>
                        <li
                            className={"list-group-item cursor-pointer " + (all_choices_made ? 'list-group-item-success' : 'list-group-item-warning')}
                            onClick={() => toggleTab(option.choice_group_id)}
                        >
                            <h4 className="list-group-item-heading">
                                <span
                                    style={{marginRight: '10px'}}
                                      className={"fa " + (optionTabs[option.choice_group_id] ? "fa-chevron-down" : "fa-chevron-right")}
                                ></span>
                                <strong>{option.title}</strong>
                                <small style={{fontSize: '.6em'}} className={"pull-right " + (all_choices_made ? 'text-success' : 'text-danger')}>
                                                <span
                                                    className={"fa " + (all_choices_made ? 'fa-check' : 'fa-clock-o')}
                                                    style={{marginRight: '10px'}}
                                                />
                                    <span>{option.total_selected}/{option.total_to_select} choices selected</span>
                                </small>
                            </h4></li>
                        {optionTabs[option.choice_group_id] && option.choices.map(function (choice, index) {
                                return <li
                                    className={"list-group-item" + (choice.selected === 0 ? ' text-mute' : '')}
                                    style={{background: choice.selected > 0 ? '#f6fbf4' : 'none'}}
                                    key={index}
                                >
                                    <div className="row">
                                        <div className="col-md-9">
                                                    <span
                                                        className={"fa fa-circle " + (choice.selected > 0 ? "text-success" : "text-mute")}
                                                        style={{
                                                            marginRight: '8px',
                                                            color: choice.selected > 0 ? '' : 'lightgray'
                                                        }}
                                                    />
                                            {choice.display_title}
                                        </div>
                                        <div className="col-md-3">
                                            {canChangeNumber ? <span
                                                onClick={() => changeQuantity(choice, 'decrease', choice.selected > 0 && !solo_choice)}
                                                className={"fa fa-minus quantity-selector--icon"
                                                    + ((option.has_solo_choice || choice.selected === 0 || !canChangeNumber) ? ' quantity-selector--disabled' : '')
                                                }
                                            /> : <span className="fa fa-spin fa-circle-o-notch"/>}
                                            <span
                                                className={"quantity-selector--number" + (option.has_solo_choice ? ' quantity-selector--disabled' : '')}>{choice.selected}</span>
                                            {canChangeNumber ?
                                                <span
                                                    onClick={() => changeQuantity(choice, 'increase', !all_choices_made && !solo_choice)}
                                                    className={"fa fa-plus quantity-selector--icon"
                                                        + ((option.has_solo_choice || all_choices_made || !canChangeNumber) ? ' quantity-selector--disabled' : '')
                                                    }/> : <span className="fa fa-spin fa-circle-o-notch"/>}
                                        </div>
                                    </div>
                                </li>
                        })}
                    </ul>
                })}
            </div>
            <div className="col-md-12 text-right">
                <span className="btn btn-primary pull-right" onClick={saveBooking}>
                    {saving ? <span><span className="fa fa-spin fa-circle-o-notch" style={{marginRight: '5px'}} /> Saving...</span> : <span>Save Choices</span>}
                </span>
            </div>
        </div>
    </div>
}

export default withAuth(EditChoices);
import React, {Component} from 'react';
import moment from "moment";
import {Api} from "../Api/Api";
import Dropdown from "react-bootstrap/Dropdown";

export default function SettlementRow(props){
    let settlement = props.settlement;
    return (
        <tr>
            <td><a href={'/settlement/' + settlement.id}>{settlement.reference}</a></td>
            <td>{moment(settlement.date_created).format('DD/MM/YYYY')}</td>
            <td>{Api.currencyFormatter.format(settlement.total)}</td>
            <td>{Api.currencyFormatter.format(settlement.total_paid)}</td>
            <td>{settlement.invoice_count}</td>
            <td>{settlement.status.data.name}</td>
            <td>

            </td>
        </tr>
    )
}
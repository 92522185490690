import React from 'react'
import moment from 'moment'
import PropTypes from 'prop-types';
import Card from "../Card/Card";

const BookingCard = props => {

    return <Card
        title={props.name}
        badge={moment(props.short_date).isSame(moment(), 'd') ? 'TODAY' : null}
        body={<><i className={`fa fa-map-pin mr-2`}/>{props.bar}</>}
        onClick={() => {
            document.location.href='/bookings/' + props.id;
        }}
        footer_items={[
            <><i className={`fa fa-users mr-2`}/> {props.party_size}</>,
            <><i className={`fa fa-calendar mr-2`}/> {props.short_date}</>
        ]}
    />
}
BookingCard.propTypes = {
    name: PropTypes.string.isRequired,
    short_date: PropTypes.string.isRequired,
    bar: PropTypes.string.isRequired,
    party_size: PropTypes.number.isRequired,
    id: PropTypes.number.isRequired
}
export default BookingCard
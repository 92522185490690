import React, {Component} from 'react';
import FormFilter from "../Form/Filter";
import Pagination from "../Utility/Pagination/Pagination";
import {Api, CurrencyFormatter} from "../Api/Api";
import _ from "lodash";
import {ModalActions} from "../Utility/Modal/Modal";
import PayInvoice from "./PayInvoice";
import InvoiceRow from "./InvoiceRow";
import {EventDispatcher} from "../../Events/Dispatcher";
import LayoutWrapper from "../../Utility/LayoutWrapper";

class BookingInvoices extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            initial_load: false,
            filter: {
                search: this.props.search ? this.props.search : '',
                created_date_start: null,
                created_date_end: null,
                due_date_start: null,
                due_date_end: null,
                booking_date_start: null,
                booking_date_end: null,
                status: [0, 1, 3],
                page: 1
            },
            outstanding: {
                balance: 0,
                bookings: 0,
                invoices: 0
            },
            invoices: [],
            pagination: {}
        }
    }

    componentDidMount() {
        this.event_sub = EventDispatcher.subscribe('reload-invoices', () => this.updateInvoices);
    }

    componentWillUnmount() {
        EventDispatcher.unsubscribe(this.event_sub);
    }

    changePage(page) {
        let filter = _.clone(this.state.filter);
        filter.page = page;
        this.handleFilterChange(filter);
    }

    handleFilterChange(filter) {
        let do_update = JSON.stringify(this.state.filter) !== JSON.stringify(filter) || this.state.initial_load === false;
        this.setState({filter: filter, initial_load: true}, () => {
            if (do_update) {
                this.updateInvoices();
            }
        });
    }

    updateInvoices() {
        this.setState({loading: true});
        Api.get('agency/invoices', this.state.filter).then((response) => {
            if (response.ok) {
                this.setState({
                    invoices: response.data.data,
                    pagination: response.data.meta.pagination,
                    outstanding: response.data.meta.outstanding,
                    loading: false
                });
            }
        })
    }

    openPaymentModal() {
        ModalActions.openModal('Create Settlement', <PayInvoice/>);
    }

    render() {
        let filters = [
            {
                name: 'search',
                label: 'Search',
                type: 'text',
                value: this.state.filter.search
            },
            {
                name: 'created_date',
                label: 'Created Date',
                type: 'date_range',
                value: this.state.filter.created_date_start
            },
            {
                name: 'due_date',
                label: 'Due Date',
                type: 'date_range',
                value: this.state.filter.due_date
            },
            {
                name: 'booking_date',
                label: 'Booking Date',
                type: 'date_range',
                value: this.state.filter.booking_date
            },
            {
                name: 'status',
                label: 'Status',
                type: 'select',
                values: [{
                    key: 2,
                    value: 'Paid'
                }, {
                    key: 0,
                    value: 'Live'
                }, {
                    key: 1,
                    value: 'Part Paid'
                }, {
                    key: 3,
                    value: 'Overdue'
                }],
                multiple: true,
                value: this.state.filter.status
            }
        ];
        return <LayoutWrapper {...this.props} title="Booking Invoices">
            <FormFilter filter={this.state.filter} filters={filters} name="invoice_filter"
                        handleChange={filter => this.handleFilterChange(filter)}/>
            {this.state.outstanding.invoices > 0 ? <div className="alert alert-warning">
                There are currently <strong>{this.state.outstanding.invoices}</strong> invoices
                across <strong>{this.state.outstanding.bookings}</strong> bookings requiring payment
                totalling <strong>{CurrencyFormatter.format(this.state.outstanding.balance)}</strong>. <button
                className="btn btn-success" onClick={(e) => this.openPaymentModal(e)}>
                Create Settlement Reference Now
            </button>
            </div> : null}
            <Pagination pagination={this.state.pagination}
                        changePage={(page) => this.changePage(page)}/>
            <table className="table table-bordered table-striped">
                <thead>
                <tr>
                    <th>Invoice Date</th>
                    <th>Due Date</th>
                    <th>Booking Date</th>
                    <th>Invoice Ref</th>
                    <th>Booking Reference</th>
                    <th>Lead Booker Name</th>
                    <th>Invoice Amount</th>
                    <th>Status</th>
                    <th/>
                </tr>
                </thead>
                <tbody>
                {this.state.invoices.length === 0 ? <tr>
                    <td colSpan={7}>No invoices to view</td>
                </tr> : null}
                {this.state.invoices.map(function (invoice) {
                    return <InvoiceRow invoice={invoice} key={invoice.invoice_id}
                                       show_booking_ref={true}
                                       show_booker_name={true}/>
                })}
                </tbody>
            </table>
            <Pagination pagination={this.state.pagination}
                        changePage={(page) => this.changePage(page)}/>
        </LayoutWrapper>
    }
}

export default BookingInvoices;
import React, {useEffect, useState} from 'react';
import Layout from "../Components/Layout/Layout";
import {Api} from "../Components/Api/Api";
import Loading from "../Components/Utility/Loading/Loading";
import FormRow from "../Components/Form/Row";
import FormElementText from "../Components/Form/Element/Text";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

export default function ResetPassword(props) {
    const [token, setToken] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const [password, setPassword] = useState({
        new_password: '',
        new_password_check: ''
    });

    const handleReset = (e) => {
        e.preventDefault();
        setLoading(true);
        Api.post('/agency/reset-password', {
            new_password: password.new_password,
            new_password_check: password.new_password_check,
            token: token.token
        }).then(function (response) {
            setLoading(false);
            if (response.ok) {
                setPassword({
                    new_password: '',
                    new_password_check: ''
                });
                Api.showNotification('Done', 'Password successfully changed... Redirecting to login....');
                setTimeout(() => {
                    window.location.href = '/login';

                }, 1000);
                return;
            }
            setError(response.error);
        }.bind(this))
    }

    const checkToken = () => {
        let token = props.match.params[0];
        Api.get('agency/reset-password', {token: token}).then(function (response) {
            setLoading(false);
            if (response.ok === false) {
                setError(response.error);
                return;
            }
            setToken(response.data.data);
        }.bind(this)).catch();
    }

    useEffect(() => {
        checkToken();
    }, []);

    if (loading) {
        return <Loading/>
    }
    return <Layout {...props}>
        <Container className={`mt-5`}>
            <Row>
                <Col sm={12} md={{span: 6, offset: 3}}>
                    {error ? <div className="alert alert-danger">{error}</div> : null}
                    {token ? <div>
                        <div className="alert alert-warning">
                            Enter your new password below
                        </div>
                        <form onSubmit={(e) => this.handleReset(e)}>
                            <FormRow label="New Password">
                                <FormElementText name="new_password" password={true}
                                                 onChange={(e) => setPassword({...password, ...{new_password: e.target.value}})}
                                                 value={password.new_password}/>
                            </FormRow>
                            <FormRow label="New Password (again)">
                                <FormElementText name="new_password_check" password={true}
                                                 onChange={(e) => setPassword({...password, ...{new_password_check: e.target.value}})}
                                                 value={password.new_password_check}/>
                            </FormRow>
                            <button className="btn btn-success" type="submit" onClick={(e) => handleReset(e)}>
                                Reset Password
                            </button>
                        </form>
                    </div> : null}
                </Col>
            </Row>
        </Container>
    </Layout>

}
import React, {Component} from 'react';
import '../../../App.css'
import {EventDispatcher} from "../../../Events/Dispatcher";

class Modal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            show: false,
            title: '',
            content: ''
        }
    }

    componentDidMount() {
        this.show_listen = EventDispatcher.subscribe('show-modal', (e) => {
            document.querySelector('body').classList.add('modal-open')
            this.setState({title: e.title, content: e.content, show: true}, () => {
                console.log(this.state);
            });
        });

        this.hide_listen = EventDispatcher.subscribe('hide-modal', (e) => {
            document.querySelector('body').classList.remove('modal-open')
            this.setState({title: '', content: '', show: false});
        })
    }

    render() {
        if (this.state.show === false) {
            return null;
        }
        return <>
            <div className="modal fade show rbg-modal" role="dialog" id="partypro_modal" style={{display: 'block'}}>
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">{this.state.title}</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close"
                                    onClick={(e) => this.setState({show: false, title: '', content: ''})}><span
                                aria-hidden="true">&times;</span></button>
                        </div>
                        <div className="modal-body">
                            {this.state.content}
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-default" data-dismiss="modal"
                                    onClick={(e) => {
                                        document.querySelector('body').classList.remove('modal-open')
                                        this.setState({show: false, title: '', content: ''})
                                    }}>Close
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal-backdrop fade show"/>
        </>
    }
}

export default Modal;

export const ModalActions = {
    openModal: function (title, content) {
        EventDispatcher.dispatch('show-modal', {title: title, content: content});
    },
    hideModal: function () {
        EventDispatcher.dispatch('hide-modal');
    }
};
import React from 'react';
import Jumbotron from "react-bootstrap/Jumbotron";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Layout from "../Components/Layout/Layout";

import PropTypes from 'prop-types';

export default function LayoutWrapper(props) {
    return <Layout {...props}>
        <Jumbotron fluid>
            <Container>
                <Row>
                    <Col sm={props.actions ? 6 : 12}>
                        <h2>{props.title}</h2>
                        {props.sub_title ? <p className={`mb-0 bigger`}>{props.sub_title}</p> : null}
                    </Col>
                    {props.actions ? <Col sm={6} className={`text-right`}>
                        {props.actions}
                    </Col> : null}
                </Row>
            </Container>
        </Jumbotron>
        <Container>
            <Row>
                <Col>
                    {props.children}
                </Col>
            </Row>
        </Container>
    </Layout>
}

LayoutWrapper.propTypes = {
    title: PropTypes.string.isRequired,
    sub_title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    actions: PropTypes.any
}
import React from 'react';
import _ from 'lodash';
import {Api} from "../../Api/Api";
import {EventDispatcher} from "../../../Events/Dispatcher";


export default function withAuth(Component) {
    return class extends React.Component {
        constructor(props) {
            super(props);
            let auth = window.localStorage.getItem('authentication');
            if (!auth) {
                auth = {
                    authenticated: false,
                    user: {}
                };
            } else {
                auth = JSON.parse(auth);
                Api.setAuthToken(auth.user.data.token);
            }
            this.state = {
                auth: auth
            }
        }

        componentDidMount() {
            this.checkForAuth();
            EventDispatcher.subscribe('logout', this.logout.bind(this));
        }

        checkForAuth() {
        }

        setAuth(auth) {
            window.localStorage.setItem('authentication', JSON.stringify(auth));
            Api.setAuthToken(auth.user.data.token);
            this.setState({auth: auth});
        }

        logout() {
            this.setState({
                auth: {
                    authenticated: false,
                    user: {}
                }
            });
            window.localStorage.removeItem('authentication');
            document.location.href = '/login';
        }

        render() {
            let props = _.clone(this.props);
            props.auth = this.state.auth;
            props.setAuth = this.setAuth.bind(this);
            props.logout = this.logout.bind(this);
            if (this.state.auth.authenticated) {
                props.user = this.state.auth.user.data.user.data;
                props.agency = props.user.agency.data;
            } else {
                props.user = null;
                props.agency = null;
            }
            return <Component {...props}/>
        }
    }
};
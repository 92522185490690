import React, { useContext, useEffect, useState } from 'react'
import { NewBookingContext } from '../Context/NewBookingContext'
import Jumbotron from 'react-bootstrap/Jumbotron'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import FormControl from 'react-bootstrap/FormControl'
import FormBarSelect from '../../../Components/Form/Element/FormBarSelect'
import { EventDispatcher } from '../../../Events/Dispatcher'
import FormElementDatePicker from '../../../Components/Form/Element/DatePicker'
import moment from 'moment'
import TimePicker from '../../../Components/Form/Element/TimePicker'
import FormElementSelect from '../../../Components/Form/Element/Select'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import './Step1.scss'

const Step1 = (props) => {
    const [bookingState] = useContext(NewBookingContext)
    const [state, setState] = useState({
        no_people: bookingState.booking.no_people ? bookingState.booking.no_people : 0,
        type: bookingState.booking.type,
        bar: bookingState.booking.bar,
        date: bookingState.booking.date ? bookingState.booking.date : '',
        time: bookingState.booking.time ? bookingState.booking.time : '',
        occasion: bookingState.booking.occasion,
        can_submit: bookingState.booking.can_continue,
    })
    const [modal, setModal] = useState({
        show: false,
        content: null,
        buttons: null,
    })
    const [error, setError] = useState(null)
    const checkSubmit = (e) => {
        e.preventDefault()
        if (bookingState.loading) {
            return
        }
        if (bookingState.booking.slot_available === true) {
            EventDispatcher.dispatch('update-booking', {
                step: 2,
            })
            return
        }
        if (bookingState.booking.slot_available === false && bookingState.booking.bookable_slots.data.length === 0) {
            let reason = <div className="alert alert-info">There are no slots available on the day</div>
            if (bookingState.booking.slots.data.length > 0) {
                let reasons = {}
                bookingState.booking.slots.data.map(function (s) {
                    let parts = s.time.split(' ')[1].split(':')
                    let time = parts[0] + ':' + parts[1]
                    let reason = s.unavailable_reason
                    if (!reasons[reason]) {
                        reasons[reason] = {
                            reason: reason,
                            times: [],
                        }
                    }
                    reasons[reason].times.push(time)
                })
                reason = <div className="alert alert-info">
                    <ul style={{ listStyleType: 'none', margin: 0, padding: 0 }}>{Object.keys(reasons).map((key) => {
                        let keyReason = reasons[key]
                        return <li style={{ listStyleType: 'none', margin: 0, padding: 0 }} key={key}>
                            <strong>{key}</strong><br/>{keyReason.times.join(', ')}</li>
                    })}</ul>
                </div>
            }
            setModal({
                show: true,
                content: <div>
                    <div className="alert alert-danger">There isn't any availability
                        for {bookingState.booking.bar.data.bar_name} on {moment(bookingState.booking.date).
                          format('DD/MM/YYYY')}</div>
                    {reason}
                </div>,
            })
        }
        if (bookingState.booking.slot_available === false && bookingState.booking.bookable_slots.data.length > 0) {
            setModal({
                show: true,
                content: <div>
                    <div className="alert alert-info">We can't
                        do {moment(bookingState.booking.full_booking_date).format('HH:mm')} but we can
                        do...
                    </div>
                    <div className="slot-grid">
                        {bookingState.booking.bookable_slots.data.map((s) => {
                            return <Button variant="primary" key={s.time} onClick={() => {
                                EventDispatcher.dispatch('update-booking', {
                                    time: moment(s.time).format('HH:mm:ss'),
                                    step: 2,
                                })
                            }}>
                                {moment(s.time).format('HH:mm')}
                            </Button>
                        })}
                    </div>
                </div>,
            })
        }
    }
    useEffect(() => {
        setState(bookingState.booking)
    }, [bookingState.booking])
    return <div>
        <Modal show={modal.show} centered>
            <Modal.Body>
                {modal.content}
            </Modal.Body>
            <Modal.Footer>
                {modal.buttons}
                <Button variant="primary"
                        onClick={() => setModal({ ...modal, ...{ show: false, content: null, buttons: null } })}>
                    Cancel
                </Button>
            </Modal.Footer>
        </Modal>
        <Jumbotron fluid>
            <Container>
                <Row>
                    <Col>
                        <h2>Create Booking</h2>
                        <p className={` bigger mb-0`}>Fill in your details below and we'll check what's best for
                            you.</p>
                    </Col>
                </Row>
            </Container>
        </Jumbotron>
        <form onSubmit={(e) => checkSubmit(e)}>
            <Container>
                <Row className={`mb-3`}>
                    <Col sm={12}>
                        <label>
                            <strong>Party size</strong><br/>
                            <span className={`text-muted`}>How many will be attending?</span>
                        </label>
                    </Col>
                    <Col sm={12}>
                        <FormControl
                          onChange={(e) => {
                              setState({
                                  ...state,
                                  ...{ no_people: parseInt(e.target.value) },
                              })
                          }}
                          onBlur={() => {
                              EventDispatcher.dispatch('update-booking', {
                                  no_people: state.no_people,
                              })
                          }} type="number" min={1} placeholder={`Enter party size`}
                          value={state.no_people ? state.no_people : ''}/>
                        <div className="invalid-feedback">
                            You need to enter your party size.
                        </div>

                        {state.no_people >= 100 &&
                          <span
                            className={`text-warning mt-2 d-block`}>Woah! <strong>{state.no_people}</strong> people is a lot; are you sure this is correct?</span>
                        }
                    </Col>
                </Row>
                <Row className={`mb-3`}>
                    <Col sm={12}>
                        <label>
                            <strong>Bar location</strong><br/>
                            <span className={`text-muted`}>Where do you want to book? {state.bar.id}</span>
                        </label>
                    </Col>
                    <Col sm={12}>
                        <FormBarSelect onChange={e => {
                            setState({
                                ...state,
                                ...{ bar: { data: e.target.object } },
                            })
                            EventDispatcher.dispatch('update-booking', {
                                bar: e.target.object,
                            })
                        }} selectedValue={state.bar.data.id ? state.bar.data.id : ''}
                                       name="bar_id"/>
                        <div className="invalid-feedback">
                            You need to select the bar location.
                        </div>
                    </Col>
                </Row>
                <Row className={`mb-3`}>
                    <Col sm={12}>
                        <label>
                            <strong>Booking date & time</strong><br/>
                            <span className={`text-muted`}>When do you want to book?</span>
                        </label>
                    </Col>
                    {state.bar.data.close_date && <Col sm={12} className={`mb-2 mb-md-0`}>
                        <div className="alert alert-danger">
                            {state.bar.data.bar_name} is closing on {moment(state.bar.data.close_date).format('DD/MM/YYYY')}
                        </div>
                    </Col>}
                    <Col sm={12} md={6} className={`mb-2 mb-md-0`}>
                        <FormElementDatePicker
                          name="date"
                          maxDate={state.bar.data.close_date?new Date(state.bar.data.close_date):null}
                          onChange={e => {
                              EventDispatcher.dispatch('update-booking', {
                                  date: moment(e.target.value).format('YYYY-MM-DD'),
                              })
                          }}
                          value={state.date}/>
                    </Col>
                    <Col sm={12} md={6}>
                        <TimePicker name="time"
                                    onChange={e => {
                                        EventDispatcher.dispatch('update-booking', {
                                            time: e.target.value,
                                        })
                                    }} value={state.time}/>
                    </Col>
                    {bookingState.booking.cutoff_reached && <Col sm={12} md={12}>
                        <div className="alert alert-danger">Sorry it is too late to make this booking. The cutoff date
                            for this booking was {moment(bookingState.booking.cutoff).format('DD/MM/YYYY')}</div>
                    </Col>}
                </Row>
                <Row className={`mb-3`}>
                    <Col sm={12}>
                        <label>
                            <strong>Booking type</strong><br/>
                            <span className={`text-muted`}>What type of package are you booking?</span>
                        </label>
                    </Col>
                    <Col sm={12}>
                        <FormElementSelect name="type" onChange={(e) => {
                            let object = {}
                            if (e.target.value) {
                                object = bookingState.booking.types.data.find((s) => {
                                    return s.id === parseInt(e.target.value)
                                })
                            }
                            setState({
                                ...state,
                                ...{ type: { data: object } },
                            })
                            EventDispatcher.dispatch('update-booking', {
                                type: object,
                            })
                        }}
                                           useSelect2={true}
                                           values={bookingState.booking.types.data.map((s) => {
                                               return {
                                                   key: s.id,
                                                   value: s.name.replace('Agency ', ''),
                                               }
                                           })} includeBlankOption={true} blankOptionText=" - Select -"
                                           selectedValue={state.type.data.id}/>
                    </Col>
                </Row>
                {state.slot_allow_standing && <Row className={`mb-3`}>
                    <Col sm={12}>
                        <label>
                            <strong>Allow Standing Space</strong><br/>
                            <span className={`text-muted`}>If the party is OK with having a mixture of standing/seated space, set this to Yes</span>
                        </label>
                    </Col>
                    <Col sm={12}>
                        <FormElementSelect name="standing_space" onChange={(e) => {
                            setState({
                                ...state,
                                ...{ allow_standing: e.target.value },
                            })
                            EventDispatcher.dispatch('update-booking', {
                                allow_standing: e.target.value,
                            })
                        }}
                                           useSelect2={true}
                                           values={[
                                               { key: '0', value: 'No' },
                                               { key: '1', value: 'Yes' },
                                           ]} includeBlankOption={false}
                                           selectedValue={state.allow_standing === true ? '1' : '0'}/>
                    </Col>
                </Row>}
                <Row className={`mb-3`}>
                    <Col sm={12}>
                        <label>
                            <strong>Booking occasion (optional)</strong><br/>
                            <span className={`text-muted`}>What's the occasion?</span>
                        </label>
                    </Col>
                    <Col sm={12}>
                        <FormElementSelect name="occasion" onChange={(e) => {
                            let object = {}
                            if (e.target.value) {
                                object = bookingState.booking.occasions.data.find((s) => {
                                    return s.id === parseInt(e.target.value)
                                })
                            }
                            setState({
                                ...state,
                                ...{ type: { data: object } },
                            })
                            EventDispatcher.dispatch('update-booking', {
                                occasion: object,
                            })
                        }}
                                           useSelect2={true}
                                           values={bookingState.booking.occasions.data.map((s) => {
                                               return {
                                                   key: s.id,
                                                   value: s.name,
                                               }
                                           })} includeBlankOption={true} blankOptionText=" - Select -"
                                           selectedValue={state.occasion.data.id}/>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Button block={true}
                                disabled={bookingState.booking.errors.length > 0 || bookingState.loading}
                                type={`submit`}><i
                          className={`fa fa-search`}/> Check
                            availability
                            {bookingState.loading && <i className="fa fa-spin fa-cog "/>}
                        </Button>
                    </Col>
                </Row>
            </Container>
        </form>
    </div>
}

export default Step1

import React, {useState} from 'react';
import withAuth from "../../../Components/Higher/Authentication/Authentication"
import Nav from 'react-bootstrap/Nav'
import Navbar from 'react-bootstrap/Navbar'
import Container from 'react-bootstrap/Container'
import Dropdown from 'react-bootstrap/Dropdown'
import './Navigation.scss';

const Navigation = props => {

    const [searchQuery, setSearchQuery] = useState("")
    const [search, setSearch] = useState(false)
    return <Navbar bg="light" expand={`lg`}>
        <Container>
            <Navbar.Brand href="/" className={`${(!props.auth.authenticated) ? 'mr-auto ml-auto' : ''}`}><img
                src="/assets/images/logos/group-black.svg" height={35} loading="lazy"/></Navbar.Brand>
            {props.auth.authenticated &&
            <>
                <Navbar.Toggle aria-controls="main-nav"/>
                <Navbar.Collapse id="main-nav">
                    <Nav className="ml-auto">
                        <Nav.Link href="/">Dashboard</Nav.Link>
                        {props.user.bookings ? <Nav.Link href="/bookings">Bookings</Nav.Link> : null}
                        {props.user.invoices ?
                            <Dropdown className="invoicesDropdown">
                                <Dropdown.Toggle bsPrefix="">
                                    Invoices
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <Dropdown.Item href="/booking-invoices">Invoices</Dropdown.Item>
                                    <Dropdown.Item href="/invoice-queries">Invoice Queries</Dropdown.Item>
                                    <Dropdown.Item href="/settlements">Settlements</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                            : null}
                        <Nav.Link href="/bars">Bars</Nav.Link>
                        <Dropdown>
                            <Dropdown.Toggle className={`nav-link text-white`} size={`sm`}>
                                <i className={`fa fa-user mr-2 ml-2`}></i><span
                                className={`mr-1`}>{props.auth.user.data.user.data.name}</span>
                            </Dropdown.Toggle>
                            <Dropdown.Menu className="navDropdown">
                                <Dropdown.Item className="dropItem" href="/my-account"><i
                                    className={`fa fa-cog mr-2`}></i>Settings</Dropdown.Item>
                                <Dropdown.Item onClick={() => props.logout()} className="dropItem" href="#"><i
                                    className={`fa fa-sign-out mr-2`}></i>Logout</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>

                    </Nav>
                </Navbar.Collapse>
            </>

            }
        </Container>
    </Navbar>

}

export default withAuth(Navigation);
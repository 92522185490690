import React, {Component} from 'react';
import Layout from "../Components/Layout/Layout";
import {ModalActions} from "../Components/Utility/Modal/Modal";
import PayInvoice from "../Components/Invoices/PayInvoice";
import Tabs from "../Components/Utility/Tabs/Tabs";
import Tab from "../Components/Utility/Tabs/Tab";
import {Redirect} from "react-router-dom";
import withAuth from "../Components/Higher/Authentication/Authentication";
import Settlements from "../Components/Invoices/Settlements";
import BookingInvoices from "../Components/Invoices/BookingInvoices";
import InvoiceQueries from "../Components/Invoices/InvoiceQueries";
import Jumbotron from "react-bootstrap/Jumbotron";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import InvoiceDashboard from "./Invoices/InvoiceDashboard";

class Invoices extends Component {
    openPaymentModal() {
        ModalActions.openModal('Create Settlement', <PayInvoice/>);
    }

    render() {
        if (!this.props.auth.authenticated) {
            return <Redirect push to="/login"/>
        }
        return <Layout {...this.props}>
            <Jumbotron fluid>
                <Container>
                    <Row>
                        <Col sm={6}>
                            <h2>Invoices</h2>
                            <p className={`mb-0 bigger`}>View Current Invoice & Settlement Data</p>
                        </Col>
                        <Col sm={6} className={`text-right`}>

                        </Col>
                    </Row>
                </Container>
            </Jumbotron>
            <Container>
                <Row>
                    <Col>
                        <Tabs initial_tab="payment_invoices" lazy_load={true} group_id="invoice">
                            <Tab id="payment_invoices" title="Dashboard">
                                <InvoiceDashboard />
                            </Tab>
                            <Tab id="booking_invoices" title="Booking Invoices">
                                <BookingInvoices search={this.props.match.params[0] ? this.props.match.params[0] : ''}/>
                            </Tab>
                            <Tab id="invoice_queries" title="Invoice Queries">
                                <InvoiceQueries/>
                            </Tab>
                            <Tab title="Settlements" id="settlements">
                                <Settlements/>
                            </Tab>
                        </Tabs>
                    </Col>
                </Row>
            </Container>
        </Layout>
    }
}

export default withAuth(Invoices);
import React, {Component} from 'react';
import moment from 'moment';
import {ModalActions} from "../Utility/Modal/Modal";
import AgencyUser from "../../Pages/AgencyUser";

class AdminUserRow extends Component {
    openUser(e) {
        e.preventDefault();
        ModalActions.openModal('Edit User', <AgencyUser user_id={this.props.user.id}/>);
    }

    render() {
        let user = this.props.user;
        return <tr>
            <td><a href={'/user/' + user.id} onClick={(e) => this.openUser(e)}>{user.name}</a></td>
            <td>{user.email}</td>
            <td>{!user.deleted ? <i className="fa fa-check"/> : <i className="fa fa-times"/>}</td>
            <td>{user.last_login ?
                <span>{moment(user.last_login).format('DD/MM/YYYY HH:mm')} ({moment(user.last_login).fromNow()})</span> : 'Never'}</td>
            <td>{user.bookings ? <i className="fa fa-check"/> : <i className="fa fa-times"/>}</td>
            <td>{user.invoices ? <i className="fa fa-check"/> : <i className="fa fa-times"/>}</td>
            <td>{user.booking_notifications ? <i className="fa fa-check"/> : <i className="fa fa-times"/>}</td>
            <td>{user.invoice_notifications ? <i className="fa fa-check"/> : <i className="fa fa-times"/>}</td>
            <td>{user.admin ? <i className="fa fa-check"/> : <i className="fa fa-times"/>}</td>
        </tr>
    }
}

export default AdminUserRow;
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import TabLink from "./TabLink";
import Loading from "../Loading/Loading";
import {EventDispatcher} from "../../../Events/Dispatcher";

class Tabs extends Component {
    constructor(props) {
        super(props);
        this.state = {
            active_tab: this.props.initial_tab,
            loaded_tabs: this.props.initial_tab ? [this.props.initial_tab] : []
        };
    }

    componentDidMount() {
        let hash = window.location.hash;
        if (hash) {
            this.changeTab(hash.substr(1));
        }
        this.tab_change_listener = EventDispatcher.subscribe('trigger-tab-change', (data) => {
            if (data.group_id) {
                if (this.props.group_id === data.group_id) {
                    this.changeTab(data.tab);
                }
                return;
            }

            this.changeTab(data.tab);
        })
    }

    changeTab(value) {
        this.setState({active_tab: value});
        if (this.state.loaded_tabs.indexOf(value) === -1) {
            var tabs = this.state.loaded_tabs;
            tabs.push(value);
            this.setState({loaded_tabs: tabs});
        }
        if (this.props.handleTabChange) {
            this.props.handleTabChange(value);
        }
    }

    render() {
        let tabs = [];
        React.Children.forEach(this.props.children, function (child) {
            tabs.push(child);
        });
        return <div className="rbg-tabs">
            <nav className="nav nav-tabs hidden-print">
                {tabs.map(function (child) {
                    if (child) {
                        return <TabLink key={child.props.id} id={child.props.id} title={child.props.title}
                                        active_tab={this.state.active_tab} changeTab={(e) => this.changeTab(e)}
                                        can_close={child.props.can_close} badge={child.props.badge}
                                        confirm_close={child.props.confirm_close}/>
                    }
                }.bind(this))}
                {this.props.handleSave ? <li className="pull-right">
                    <button className="btn btn-xs btn-success" onClick={this.props.handleSave}>
                        <i className="fa fa-save"/> {this.props.save_text}
                    </button>
                </li> : null}
                {this.props.actions.length > 0 ? <li className="pull-right">
                    <div className="btn-group">
                        <button type="button" className="btn btn-default btn-xs dropdown-toggle" data-toggle="dropdown"
                                aria-haspopup="true" aria-expanded="false">
                            <span className="hidden-xs">{this.props.action_label}</span>
                            <span className="caret"/>
                            <span className="sr-only">Toggle Dropdown</span>
                        </button>
                        <ul className="dropdown-menu dropdown-menu-right">
                            {this.props.actions}
                        </ul>
                    </div>
                </li> : null}
            </nav>
            <div className="clearfix"/>
            {tabs.map(function (item) {
                if (item && item.props) {
                    return <div className="tab tab-content clearfix" id={item.props.id} key={item.props.id}
                                style={{display: this.state.active_tab === item.props.id ? '' : 'none'}}>
                        <div className="fade tabContent tab-pane active show">
                            {this.props.lazy_load ?
                                (this.state.loaded_tabs.indexOf(item.props.id) === -1 ?
                                    <Loading/> : item) : item}
                        </div>
                    </div>
                }
            }.bind(this))}
        </div>
    }
}

Tabs.propTypes = {
    initial_tab: PropTypes.string,
    actions: PropTypes.array,
    group_id: PropTypes.string,
    handleTabChange: PropTypes.func,
    lazy_load: PropTypes.bool
};

Tabs.defaultProps = {
    actions: []
};
export default Tabs;
import React, {Component} from 'react';
import Loading from "../Components/Utility/Loading/Loading";
import Layout from "../Components/Layout/Layout";
import {Api} from "../Components/Api/Api";
import _ from 'lodash';
import FormRow from "../Components/Form/Row";
import FormElementText from "../Components/Form/Element/Text";
import FormElementBoolean from "../Components/Form/Element/Boolean";

import AgencyAdmin from "./AgencyAdmin";

import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tab'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import LayoutWrapper from "../Utility/LayoutWrapper";

class MyAccount extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            user: {}
        }
    }

    handleFieldChange(e) {
        let user = _.clone(this.state.user);
        user[e.target.name] = e.target.value;
        this.setState({user: user});
    }

    componentDidMount() {
        this.updateDetails();
    }

    handleSave(e) {
        e.preventDefault();
        if (!this.state.user.current_password) {
            Api.showError('Error', 'Please enter your current password to update your details');
            return;
        }
        Api.post('/agency/me', this.state.user).then((response) => {
            if (response.ok) {
                Api.showNotification('Done', 'Your details have been updated');
            }
        });
    }

    updateDetails() {
        Api.get('/agency/me').then(function (response) {
            if (response.ok) {
                let data = response.data;
                data.data.current_password = '';
                data.data.new_password = '';
                delete data.data.agency;
                delete data.data.deleted;
                data.data.new_password_check = '';
                this.setState({loading: false, user: data.data});
            }
        }.bind(this)).catch(function (error) {
            console.log(error);
        });
    }

    render() {
        if (this.state.loading) {
            return <Layout {...this.props}>
                <Loading/>
            </Layout>
        }

        return <LayoutWrapper {...this.props} title="My Account">
            <Row>
                <Col>
                    <Tabs defaultActiveKey="profile" id="uncontrolled-tab-example">
                        <Tab className={`tabContent`} eventKey="profile" title="My Account">
                            <form onSubmit={(e) => this.handleSave(e)}>
                                <Row>
                                    <Col sm={12} md={6}>
                                        <FormRow label="My Name">
                                            <FormElementText name="name" value={this.state.user.name}
                                                             onChange={(e) => this.handleFieldChange(e)}/>
                                        </FormRow>
                                        <FormRow label="Email Address">
                                            <FormElementText name="email" value={this.state.user.email}
                                                             onChange={(e) => this.handleFieldChange(e)}/>
                                        </FormRow>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <FormRow label="Booking Emails">
                                                    <FormElementBoolean onChange={(e) => this.handleFieldChange(e)}
                                                                        name="booking_notifications"
                                                                        value={this.state.user.booking_notifications}/>
                                                </FormRow>
                                            </div>
                                            <div className="col-md-6">
                                                <FormRow label="Invoice Emails">
                                                    <FormElementBoolean onChange={(e) => this.handleFieldChange(e)}
                                                                        name="invoice_notifications"
                                                                        value={this.state.user.invoice_notifications}/>
                                                </FormRow>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col sm={12} md={6}>
                                        <FormRow label="Current Password">
                                            <FormElementText password={true} name="current_password"
                                                             value={this.state.user.current_password}
                                                             onChange={(e) => this.handleFieldChange(e)}/>
                                        </FormRow>

                                        <FormRow label="New Password">
                                            <FormElementText password={true} name="new_password"
                                                             value={this.state.user.new_password}
                                                             onChange={(e) => this.handleFieldChange((e))}/>
                                        </FormRow>
                                        <FormRow label="New Password (Again)">
                                            <FormElementText password={true} name="new_password_check"
                                                             value={this.state.user.new_password_check}
                                                             onChange={(e) => this.handleFieldChange((e))}/>
                                        </FormRow>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm={12} className={`text-right`}>
                                        <button className="btn btn-primary" type="submit">
                                            <i className="fa fa-save mr-2"></i>Save My Details
                                        </button>
                                    </Col>
                                </Row>
                            </form>
                        </Tab>
                        {this.state.user.admin &&
                        <Tab className={`tabContent`} eventKey="users" title="Other Users">
                            <AgencyAdmin/>
                        </Tab>
                        }
                    </Tabs>

                </Col>
            </Row>
        </LayoutWrapper>
    }
}

export default MyAccount;
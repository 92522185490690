import React, { useEffect, useState } from 'react'
import Layout from '../Components/Layout/Layout'
import Widget from '../Components/Layout/Element/Widget'
import withAuth from '../Components/Higher/Authentication/Authentication'
import { Api, CurrencyFormatter } from '../Components/Api/Api'
import moment from 'moment'
import InvoiceRow from '../Components/Invoices/InvoiceRow'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Jumbotron from 'react-bootstrap/Jumbotron'
import Badge from 'react-bootstrap/Badge'
import FormControl from 'react-bootstrap/FormControl'
import FormElementDatePicker from '../Components/Form/Element/DatePicker'
import FormElementSelect from "../Components/Form/Element/Select";
import FormRow from "../Components/Form/Row";
import TimePicker from '../Components/Form/Element/TimePicker'
import Loading from '../Components/Utility/Loading/Loading'
import Modal from 'react-bootstrap/Modal'
import Alert from 'react-bootstrap/Alert'
import copy from 'copy-to-clipboard'
import InfoTip from '../Components/Layout/Element/InfoTip'
import {ModalActions} from "../Components/Utility/Modal/Modal";
import EditChoices from "../Components/Bookings/EditChoices";

const Booking = props => {

    const [loading, setLoading] = useState(true)
    const [booking, setBooking] = useState({})
    const [postBooking, setPostBooking] = useState({})
    const [bar, setBar] = useState({})
    const [invoices, setInvoices] = useState([])
    const [options, setOptions] = useState([])
    const [fof, setFof] = useState(false)
    const [editing, setEditing] = useState(false)
    const [editingMeta, setEditingMeta] = useState(false)
    const [saving, setSaving] = useState(false)
    const [savingMeta, setSavingMeta] = useState(false)
    const [showCancel, setShowCancel] = useState(false)
    const [submitError, setSubmitError] = useState('')

    useEffect(() => {
        const getBooking = async () => {
            const response = await Api.get(`agency/booking/${props.match.params[0]}`, { include: 'pre_orders' })

            if (response.ok) {

                setBooking(response.data.data)
                setBar(response.data.data.bar.data)
                setInvoices(response.data.data.invoices.data)
                setOptions(response.data.data.options.data)
                setLoading(false)
                setPostBooking({
                    bookingId: response.data.data.id,
                    bookingFirstName: response.data.data.forename,
                    bookingLastName: response.data.data.surname,
                    bookingEmail: response.data.data.agency_cust_email,
                    bookingPhone: response.data.data.agency_cust_phone,
                    bar: response.data.data.bar.data.bar_id,
                    partySize: response.data.data.no_people,
                    allergyInfo: response.data.data.allergy_info,
                    allergyStatus: response.data.data.allergy_status,
                    specialRequirements: response.data.data.special_requirements,
                    bookingDate: moment(response.data.data.booking_date).format('YYYY-MM-DD'),
                    bookingTime: moment(response.data.data.booking_date).format('HH:mm:ss'),
                })

            }
            else {
                setFof(true)
            }
            return response
        }

        getBooking()
    }, [props.match.params[0]])
    if (loading) {
        return <Loading/>
    }

    const editBooking = async () => {
        const response = await Api.post('/rest/bookings/agency/can_edit', {
            bookingId: props.match.params[0],
        })

        if (response.ok) {
            if (response.data.edit) {
                setEditing(true)
            }
            else {
                setSubmitError('You cannot edit this bookings of this type.')
            }
        }
        else {
            setSubmitError('An error occurred. Please try again later')
        }
    }

    const saveBooking = () => {
        postBooking['variants'] = JSON.stringify(options)
        postBooking['include'] = 'pre_orders'
        postBooking['options'] = options;
        setPostBooking(postBooking)
        setSaving(true)

        Api.postJson('/rest/bookings/agency/save', postBooking).then(response => {
            if (response.ok) {
                Api.get(`agency/booking/${props.match.params[0]}`, { include: 'pre_orders' }).then(response => {
                    setBooking(response.data.data)
                    setBar(response.data.data.bar.data)
                    setOptions(response.data.data.options.data)
                    setEditing(false)
                    setSaving(false)
                    Api.showNotification('Done', 'Booking has been saved');
                })
            }
        })
    }

    const saveMetaData = () => {
        setSavingMeta(true)
        Api.post('/rest/bookings/agency/save-metadata', {
            booking_id: booking.id,
            first_name: postBooking.bookingFirstName,
            last_name: postBooking.bookingLastName,
            email: postBooking.bookingEmail,
            phone: postBooking.bookingPhone
        }).then(response => {
            if (response.ok) {
                Api.get(`agency/booking/${props.match.params[0]}`, { include: 'pre_orders' }).then(response => {
                    setBooking(response.data.data)
                    setEditingMeta(false)
                    setSavingMeta(false)
                    Api.showNotification('Done', 'Booker\'s contact information saved.');
                })
            } else {
                setSavingMeta(false)
            }
        }).catch(error => {
            setSavingMeta(false)
        })
    }

    const updateOptionHeadCount = (value, index) => {
        let newArray = [...options]
        newArray[index] = { ...newArray[index], head_count: value }
        setOptions(newArray)
    }

    const cancelBooking = () => {
        Api.post('/rest/bookings/agency/cancel', {
            bookingId: booking.id,
        }).then(response => {
            if (response.ok) {
                Api.get(`agency/booking/${props.match.params[0]}`, { include: 'pre_orders' }).then(response => {
                    setBooking(response.data.data)
                    setShowCancel(false)
                })
            }
        })
    }


    const editChoices = (option, quantity) => {
        ModalActions.openModal('Edit Choices', <EditChoices
            option={option}
            quantity={quantity}
            booking={booking}
            post_booking={postBooking}
        />);
    }

    if (!fof) {

        return <Layout {...props}>
            {submitError &&
              <Alert variant={`warning`} className={`mb-0`}>
                  <i className={`fa fa-warning mr-2`}></i>{submitError}
              </Alert>
            }
            <Modal centered show={showCancel}>
                {booking.can_cancel ? <>
                    <Modal.Body>Are you sure you want to cancel this booking?</Modal.Body>
                    <Modal.Footer>
                        <button className={`btn btn-danger`} onClick={() => setShowCancel(false)}>No</button>
                        <button className={`btn btn-success`} onClick={cancelBooking}>Yes</button>
                    </Modal.Footer>
                </> : <>
                    <Modal.Body>Sorry, you cannot cancel this booking as it is after the cancellation
                        date of {moment(booking.cancellation_date).format('DD/MM/YYYY')}.</Modal.Body>
                    <Modal.Footer>
                        <button className={`btn btn-danger`} onClick={() => setShowCancel(false)}>Ok</button>
                    </Modal.Footer>
                </>}
            </Modal>
            <Jumbotron fluid>
                <Container>
                    <Badge pill variant={booking.status_class} className={`mb-2`}>{booking.status_name}</Badge>
                    <h2>{booking.party_name}</h2>
                    <p className={`mb-0`}>
                        <i className={`fa fa-map-pin mr-2`}></i>{bar.branded_name}
                        <span className={`ml-2 mr-2`}>&bull;</span>
                        <i className={`fa fa-calendar mr-2`}></i>{moment(booking.booking_date).
                      format('Do MMMM YYYY @ hh:mma')}


                        {(!editing && booking.status != 4) ?
                          <>
                              <span className={`ml-2 mr-2`}>&bull;</span>
                              <button className={`btn btn-sm btn-outline-primary mr-2`} onClick={editBooking}><i
                                className={`fa fa-pencil mr-1`}></i>Edit booking
                              </button>
                              <button className={`btn btn-sm btn-outline-danger`} onClick={() => setShowCancel(true)}>
                                  <i className={`fa fa-times mr-1`}></i>Cancel booking
                              </button>
                          </> : <></>
                        }

                        {editing &&
                          <>
                              <span className={`ml-2 mr-2`}>&bull;</span>
                              <button className={`btn btn-sm btn-outline-primary mr-2`} onClick={saveBooking}>
                                  {saving ? <span><i className={"fa fa-cog fa-spin mr-1"}></i> Saving, one moment...</span> :
                                      <span><i className={`fa fa-save mr-1`}></i>Save booking</span> }
                              </button>
                              <button className={`btn btn-sm btn-outline-danger`} onClick={() => setEditing(false)}><i
                                className={`fa fa-times mr-1`}></i>Cancel editing
                              </button>
                          </>
                        }
                    </p>
                </Container>
            </Jumbotron>
            <Container>
                <Row>
                    <Col sm={12} md={6} className={`mb-3`}>

                        <Widget title="Booking Info" include_body={false}>
                            <table className="table table-bordered table-striped mb-0">
                                <tbody>
                                <tr>
                                    <th>Booking Date</th>
                                    {!editing &&

                                      <td>{moment(booking.booking_date).format('dddd Do MMMM YYYY')}</td>

                                    }
                                    {editing &&
                                      <td>
                                          <Row>
                                              <Col sm={12} md={6}>
                                                  <FormElementDatePicker
                                                    name="date"
                                                    onChange={e => setPostBooking({
                                                        ...postBooking,
                                                        ...{
                                                            bookingDate: moment(e.target.value).format('YYYY-MM-DD'),
                                                        },
                                                    })
                                                    }
                                                    value={postBooking.bookingDate}/>
                                              </Col>
                                              <Col sm={12} md={6}>
                                                  <TimePicker name="time"
                                                              onChange={e => setPostBooking({
                                                                  ...postBooking,
                                                                  ...{ bookingTime: e.target.value },
                                                              })} value={postBooking.bookingTime}/>
                                              </Col>
                                          </Row>
                                      </td>
                                    }

                                </tr>
                                <tr>
                                    <th>Booking Ref</th>
                                    <td>{booking.ref_code}</td>
                                </tr>
                                <tr>
                                    <th>Booking Status</th>
                                    <td>{booking.status_name}</td>
                                </tr>
                                <tr>
                                    <th>Location</th>
                                    <td>{bar.bar_name}</td>
                                </tr>
                                <tr>
                                    <th>Party Size</th>
                                    <td>{booking.no_people}</td>
                                </tr>
                                <tr>
                                    <th>Total Value</th>
                                    <td>{CurrencyFormatter.format(booking.pbr)}</td>
                                </tr>
                                <tr>
                                    <th>Guest Allergy Info</th>
                                    <td>
                                        {editing ?
                                            <div>
                                                <FormElementSelect
                                                    name={"allergy_status"}
                                                    selectedValue={postBooking.allergyStatus}
                                                    values={[
                                                            {'key': 0, 'value': 'Waiting on information'},
                                                            {'key': 1, 'value': 'None'},
                                                            {'key': 2, 'value': 'Yes'},
                                                    ]}
                                                    onChange={e => setPostBooking(
                                                        {
                                                            ...postBooking,
                                                            ...{ allergyStatus: e.target.value, allergyInfo: '' },
                                                        }
                                                    )}
                                                />
                                                {parseInt(postBooking.allergyStatus) === 2 ?
                                                    <FormRow>
                                                    <FormControl value={postBooking.allergyInfo} className={`mb-2`}
                                                                    onChange={e =>
                                                                        setPostBooking({
                                                                            ...postBooking,
                                                                            ...{ allergyInfo: e.target.value },
                                                                        })
                                                                    } as={"textarea"} placeholder={`Allergy info`}/>
                                                </FormRow> : '' }
                                            </div> :
                                            (booking.allergy_status === 0 ? 'Waiting on information' : (booking.allergy_info ? booking.allergy_info : 'None'))
                                        }
                                    </td>
                                </tr>
                                <tr>
                                    <th>Special Requirements</th>
                                    <td>
                                        {editing ? <FormControl value={postBooking.specialRequirements} className={`mb-2`}
                                                                onChange={e =>
                                                                    setPostBooking({
                                                                        ...postBooking,
                                                                        ...{ specialRequirements: e.target.value },
                                                                    })
                                                                } as={"textarea"} placeholder={`Special requirements`}/>
                                            : (booking.special_requirements ? booking.special_requirements : 'None')}
                                    </td>
                                </tr>
                                {booking.manage_link &&
                                  <tr>
                                      <th>
                                          Booking Manage Link
                                          <InfoTip
                                            info="To be given out to the leader booker to see all attendees pre orders"/>
                                      </th>
                                      <td>
                                          <Row>
                                              <Col xs={9}>
                                                  <FormControl type="text" readOnly={true} value={booking.manage_link}/>

                                              </Col>
                                              <Col xs={2}>
                                                  <div className="btn-group btn-group-sm">
                                                      <a href={booking.manage_link} target="_blank"
                                                         className="btn btn-warning btn-sm">
                                                          <i className="fa fa-external-link"/>
                                                      </a>
                                                      <button className="copy btn btn-success btn-sm" onClick={() => {
                                                          copy(booking.manage_link)
                                                      }}><i className="fa fa-copy"/>
                                                      </button>
                                                  </div>
                                              </Col>
                                          </Row>
                                      </td>
                                  </tr>}
                                {booking.invite_link && <tr>
                                    <th>
                                        Booking Invite Link
                                        <InfoTip
                                          info="To be given out to each individual attendee to add their own pre order link"/>
                                    </th>
                                    <td>
                                        <Row>
                                            <Col xs={9}>
                                                <FormControl type="text" readOnly={true} value={booking.invite_link}/>

                                            </Col>
                                            <Col xs={2}>
                                                <div className="btn-group btn-group-sm">
                                                    <a href={booking.invite_link} target="_blank"
                                                       className="btn btn-warning btn-sm">
                                                        <i className="fa fa-external-link"/>
                                                    </a>
                                                    <button className="copy btn btn-success btn-sm" onClick={() => {
                                                        copy(booking.invite_link)
                                                    }}><i className="fa fa-copy"/>
                                                    </button>
                                                </div>
                                            </Col>
                                        </Row>
                                    </td>
                                </tr>}
                                </tbody>
                            </table>
                        </Widget>

                    </Col>
                    <Col sm={12} md={6} className={`mb-3`}>
                        <div className="card">
                            <div className="card-header">
                                Lead Booker Details
                                <span className="text-primary pull-right btn-default cursor-pointer" onClick={() => setEditingMeta(!editingMeta)}>
                                    {editingMeta ? <>Cancel</> : <>Edit</>}
                                </span>
                            </div>
                            <div className="card-body">
                                {editingMeta ?
                                    <>
                                    <div className="form-group">
                                        <strong><label className="control-label strong" htmlFor="bookingFirstName">First name</label></strong>
                                        <FormControl value={postBooking.bookingFirstName} className={`mb-2`}
                                                     onChange={e =>
                                                         setPostBooking({
                                                             ...postBooking,
                                                             ...{ bookingFirstName: e.target.value },
                                                         })
                                                     } type="text" placeholder={`Your first name`}/>
                                    </div>
                                    <div className="form-group">
                                        <strong><label className="control-label strong" htmlFor="bookingFirstName">Last Name</label></strong>
                                        <FormControl value={postBooking.bookingLastName} onChange={e =>
                                            setPostBooking({
                                                ...postBooking,
                                                ...{ bookingLastName: e.target.value },
                                            })
                                        } type="text" placeholder={`Your last name`} className={`mb-2`}/>
                                    </div>
                                    <div className="form-group">
                                        <strong><label className="control-label strong" htmlFor="bookingFirstName">E-mail address</label></strong>
                                            <FormControl value={postBooking.bookingEmail} className={`mb-2`}
                                                     onChange={e =>
                                                         setPostBooking({
                                                             ...postBooking,
                                                             ...{ bookingEmail: e.target.value },
                                                         })
                                                     } type="text" placeholder={`E-mail address`}/>
                                    </div>
                                    <div className="form-group">
                                        <strong><label className="control-label strong" htmlFor="bookingFirstName">Phone number</label></strong>
                                        <FormControl value={postBooking.bookingPhone} className={`mb-2`}
                                                     onChange={e =>
                                                         setPostBooking({
                                                             ...postBooking,
                                                             ...{ bookingPhone: e.target.value },
                                                         })
                                                     } type="text" placeholder={`Phone number`}/>
                                    </div>
                                    <Row>
                                        <Col sm={12}>
                                            <span className="btn btn-primary pull-right" onClick={saveMetaData}>
                                                {savingMeta ? <><i className={"fa fa-cog fa-spin"}></i> Saving....</> : <>Save</>}
                                            </span>
                                        </Col>
                                    </Row>
                                    </> : <>
                                        <h5>{booking.forename} {booking.surname}</h5>
                                        {(booking.agency_cust_email || booking.agency_cust_phone) &&
                                            <p>{booking.agency_cust_email && <>{booking.agency_cust_email}<br /></>} {booking.agency_cust_phone}</p>}
                                    </>}
                            </div>
                        </div>

                        <div className="mt-3">
                            <Widget title="Invoices" include_body={invoices.length === 0}>

                                {invoices.length === 0 &&
                                  <div className="alert alert-warning mb-0">No invoices have been generated for this booking
                                      yet.<br/><br/>They will only be generated {props.agency.invoice_term} days before the
                                      booking date.</div>
                                }
                                {invoices.length > 0 &&
                                  <table className="table table-bordered table-striped">
                                      <thead>
                                      <tr>
                                          <th>Date</th>
                                          <th>Due</th>
                                          <th>Ref</th>
                                          <th>Amount</th>
                                          <th>Status</th>
                                          <th/>
                                      </tr>
                                      </thead>
                                      <tbody>
                                      {invoices.map(function (invoice) {
                                          return <InvoiceRow invoice={invoice} key={invoice.invoice_id}/>
                                      })}
                                      </tbody>
                                  </table>
                                }
                            </Widget>
                        </div>

                        <div className="mt-3">
                            <Widget title="Bar Info">
                                <a className="btn btn-link" target="_blank" rel="noopener noreferrer" href={"/bars/" + Api.slug(bar.bar_name)}>
                                    {bar.bar_name} Information <span className="fa fa-external-link"></span>
                                </a>
                            </Widget>
                        </div>
                    </Col>
                </Row>
                {options.length > 0 &&
                  <Row>
                      <Col>
                          <Widget title="Booking Options" include_body={false}>
                              <table className="table table-bordered table-striped mb-0">
                                  <thead>
                                  <tr>
                                      <th width={'20%'}>Head Count</th>
                                      <th>Option Name</th>
                                      <th>Item Price</th>
                                      <th>Total</th>
                                  </tr>
                                  </thead>
                                  <tbody>
                                  {options.map(function (opt, key) {
                                      let options_count = 0, total_qty = 0;
                                      let grouped_pre_order = [], group_plus = []
                                      if (opt.choice_array) {
                                          opt.choice_array.forEach((c) => {
                                              options_count = options_count + c.choices.length;
                                          })
                                      }
                                      options_count = options_count * opt.head_count;
                                      if (opt.pre_order) {
                                          opt.pre_order.data.forEach((item) => {
                                              var index = group_plus.indexOf(item.plu);
                                              if (index === -1) {
                                                  group_plus.push(item.plu);
                                                  grouped_pre_order.push({
                                                      name: item.plu_name,
                                                      premium: item.premium,
                                                      quantity: 1,
                                                      multiplier: item.multiplier || 1
                                                  });
                                              } else {
                                                  grouped_pre_order[index].quantity = grouped_pre_order[index].quantity + 1;
                                              }
                                          });
                                      }
                                      let canEditChoices = false;
                                      if (opt.choice_array) {
                                          opt.choice_array.forEach((choice_group) => {
                                              choice_group.choices.forEach((choice) => {
                                                  if (choice.items.length > 1) {
                                                      canEditChoices = true;
                                                  }
                                              })
                                          })
                                      }
                                      return <tr key={opt.booking_variation_id}>
                                          <td>
                                              {!editing &&
                                                opt.head_count
                                              }
                                              {editing &&
                                                <FormControl
                                                  type="number"
                                                  value={opt.head_count}
                                                  onChange={(e) => updateOptionHeadCount(e.target.value, key)}
                                                />
                                              }


                                          </td>
                                          <td>
                                              <strong>{opt.name}</strong>
                                              {opt.pre_order && opt.pre_order.data.length > 0 && <div className="pre-order"><ul>
                                                  {grouped_pre_order.map(function (item, index) {
                                                      let qty = item.quantity * item.multiplier;
                                                      total_qty += qty;
                                                      return <li><span key={index}>{qty} x {item.name} {item.premium > 0 ?
                                                          <strong>(+{CurrencyFormatter.format(item.premium)})</strong> : null}</span></li>

                                                  })}</ul></div>}
                                              <div className="row">
                                                  <div className="col-md-6">
                                                      {options_count > 0 ? (options_count === total_qty
                                                          ? <span className="badge badge-success">{total_qty}/{options_count} choices selected</span>
                                                          : <span className="badge badge-danger">{total_qty}/{options_count} choices selected</span>) : null
                                                      }
                                                  </div>
                                                  <div className="col-md-6">
                                                      {canEditChoices && <span className="btn btn-sm btn-link pull-right" onClick={() => editChoices(opt, opt.head_count)}>Edit Choices</span>}
                                                  </div>
                                              </div>
                                          </td>
                                          <td>
                                              {CurrencyFormatter.format(opt.price)}
                                            </td>
                                          <td>{CurrencyFormatter.format(opt.head_count * opt.price)}</td>
                                      </tr>
                                  })}
                                  <tr>
                                      <td colSpan={2}/>
                                      <th>Total</th>
                                      <td>{CurrencyFormatter.format(booking.pbr)}</td>
                                  </tr>
                                  </tbody>
                              </table>
                          </Widget>
                      </Col>
                  </Row>
                }
                <Row>
                    <Col>
                        {editing &&
                          <button className={`btn btn-block btn-outline-primary mt-3`} onClick={saveBooking}>
                              {saving ? <span><i className={"fa fa-cog fa-spin"}></i> Saving, one moment...</span> :
                                  <span><i className={`fa fa-save mr-1`}></i>Save booking</span> }</button>
                        }
                    </Col>
                </Row>
            </Container>
        </Layout>

    }
    else {
        return <Layout {...props}>
            <Container>
                <Row>
                    <Col className={`text-center mt-5`}>
                        <h1>404</h1>
                        <p>Uh-oh! The booking you were looking for can't be found.</p>
                        <a href="/bookings">
                            <button className="btn btn-primary">Go to bookings</button>
                        </a>
                    </Col>
                </Row>
            </Container>
        </Layout>
    }
}

export default withAuth(Booking)

/*
class Booking extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            booking: {},
            not_found: false
        };
    }

    componentDidMount() {
        let booking_id = this.props.match.params[0];
        Api.get('agency/booking/' + booking_id, {include: 'pre_orders'}).then((response) => {
            if (response.ok) {
                this.setState({booking: response.data.data, loading: false});
            } else if (response.status === 404) {
                this.setState({not_found: true, loading: false});
            }
        });
    }

    render() {
        if (this.state.loading) {
            return <Loading/>
        }
        if (!this.props.auth.authenticated) {
            return <Redirect push to="/login"/>
        }
        if (this.state.not_found === true) {
            return <Layout {...this.props}>
                <div className="alert alert-danger">Uh oh.... That booking does not exist!</div>
            </Layout>
        }
        let booking = this.state.booking;
        return <Layout {...this.props}>
            {this.state.booking.status !== 1 ?
                <div className="alert alert-warning">This booking is not yet confirmed.</div> : null}
            <Tabs initial_tab="booking">
                <Tab id="booking" title="Booking Information">
                    <div className="row">
                        <div className="col-md-6">
                            <Widget title="Booking Info" include_body={false}>
                                <table className="table table-bordered table-striped">
                                    <tbody>
                                    <tr>
                                        <th>Booking Date</th>
                                        <td>{moment(booking.booking_date).format('dddd Do MMMM YYYY')}</td>
                                    </tr>
                                    <tr>
                                        <th>Booking Status</th>
                                        <td>{booking.status_name}</td>
                                    </tr>
                                    <tr>
                                        <th>Lead Booker Name</th>
                                        <td>{booking.forename} {booking.surname}</td>
                                    </tr>
                                    <tr>
                                        <th>Location</th>
                                        <td>{booking.bar.data.bar_name}</td>
                                    </tr>
                                    <tr>
                                        <th>Party Size</th>
                                        <td>{booking.no_people}</td>
                                    </tr>
                                    <tr>
                                        <th>Total Value</th>
                                        <td>{CurrencyFormatter.format(booking.pbr)}</td>
                                    </tr>
                                    <tr>
                                        <th>Guest Allergy Info</th>
                                        <td>{booking.allergy_info ? booking.allergy_info : 'None'}</td>
                                    </tr>
                                    </tbody>
                                </table>
                            </Widget>
                        </div>
                        <div className="col-md-6">
                            <Widget title="Invoices" include_body={booking.invoices.data.length === 0}>
                                {booking.invoices.data.length === 0 ?
                                    <div className="alert alert-warning">No invoices have been generated for this
                                        booking yet.
                                        They
                                        will only be generated {this.props.agency.invoice_term} days before the booking
                                        date.
                                    </div> :
                                    <table className="table table-bordered table-striped">
                                        <thead>
                                        <tr>
                                            <th>Date</th>
                                            <th>Due</th>
                                            <th>Ref</th>
                                            <th>Amount</th>
                                            <th>Status</th>
                                            <th/>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {booking.invoices.data.map(function (invoice) {
                                            return <InvoiceRow invoice={invoice} key={invoice.invoice_id}/>
                                        })}
                                        </tbody>
                                    </table>
                                }
                            </Widget>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <Widget title="Booking Options" include_body={false}>
                                <table className="table table-bordered table-striped">
                                    <thead>
                                    <tr>
                                        <th>Head Count</th>
                                        <th>Option Name</th>
                                        <th>Item Price</th>
                                        <th>Total</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {booking.options.data.map(function (opt) {
                                        return <tr key={opt.booking_variation_id}>
                                            <td>{opt.head_count}</td>
                                            <td>{opt.name}</td>
                                            <td>{CurrencyFormatter.format(opt.price)}</td>
                                            <td>{CurrencyFormatter.format(opt.head_count * opt.price)}</td>
                                        </tr>
                                    })}
                                    <tr>
                                        <td colSpan={2}/>
                                        <th>Total</th>
                                        <td>{CurrencyFormatter.format(booking.pbr)}</td>
                                    </tr>
                                    </tbody>
                                </table>
                            </Widget>
                        </div>
                    </div>
                </Tab>
                {booking.has_pre_order ? <Tab id="pre_order" title="Pre Order">

                </Tab> : null}
            </Tabs>
        </Layout>
    }

}

export default withAuth(Booking);*/
import React, {Component} from 'react';
import {Api} from "../Api/Api";
import _ from 'lodash';
import FormFilter from "../Form/Filter";
import Pagination from "../Utility/Pagination/Pagination";
import InvoiceQueryRow from "./InvoiceQueryRow";
import Loading from "../Utility/Loading/Loading";
import {EventDispatcher} from "../../Events/Dispatcher";
import LayoutWrapper from "../../Utility/LayoutWrapper";

class InvoiceQueries extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            queries: [],
            initial_load: false,
            filter: {
                search: '',
                include: '',
                resolved: false,
                page: 1
            },
            pagination: {}
        }
    }

    componentDidMount() {
        this.event_listener = EventDispatcher.subscribe('reload-queries', () => this.updateQueries());
    }

    componentWillUnmount() {
        EventDispatcher.unsubscribe(this.event_listener);
    }

    updateQueries() {
        this.setState({loading: true});
        let filter = this.state.filter;
        filter.include = 'invoice,history';
        Api.get('agency/queries', filter).then((response) => {
            if (response.ok) {
                this.setState({
                    loading: false,
                    initial_load: true,
                    queries: response.data.data,
                    pagination: response.data.meta.pagination
                });
            }
        });
    }

    handlePageChange(page) {
        let filter = _.clone(this.state.filter);
        filter.page = page;
        this.handleFilterChange(filter);
    }

    handleFilterChange(filter) {
        this.setState({filter: filter}, () => this.updateQueries());
    }

    render() {
        let filters = [
            {
                type: 'text',
                name: 'search',
                label: 'Search',
                value: this.state.filter.search
            },
            {
                type: 'boolean',
                name: 'resolved',
                label: 'Resolved',
                value: this.state.filter.resolved
            }
        ];
        return <LayoutWrapper {...this.props} title="Invoice Queries">
            <FormFilter filters={filters} filter={this.state.filter}
                        handleChange={(filter) => this.handleFilterChange(filter)} storeState={true} name="queries"/>

            <Pagination pagination={this.state.pagination} changePage={this.handlePageChange}/>
            <table className="table table-bordered table-striped">
                <thead>
                <tr>
                    <th>Date</th>
                    <th>Invoice</th>
                    <th>Created By</th>
                    <th>Query</th>
                    <th>Resolved</th>
                    <th/>
                </tr>
                </thead>
                <tbody>
                {this.state.loading ? <tr>
                        <td colSpan={6}><Loading/></td>
                    </tr> :
                    this.state.queries.map(function (query) {
                        return <InvoiceQueryRow key={query.id} query={query} open={false}/>
                    })}
                </tbody>
            </table>
            <Pagination pagination={this.state.pagination} changePage={this.handlePageChange}/>
        </LayoutWrapper>
    }
}

export default InvoiceQueries;
import React from 'react';
import PropTypes from 'prop-types';
import PaginationLink from "./PaginationLink";

const Pagination = (props) => {
    if (!props.pagination.count) {
        return null;
    }
    let start_number = ((props.pagination.current_page - 1) * props.pagination.per_page);
    let end = start_number + props.pagination.count;
    let total = props.pagination.total;
    if (end > total) {
        end = total;
    }

    let current_page = props.pagination.current_page;
    let pages = [];
    for (let page = 1; page <= props.pagination.total_pages; page = page + 1) {
        pages.push(page);
    }
    let max_pages = props.max_pages ? props.max_pages : 10;
    if (pages.length > max_pages) {
        let start = current_page - Math.ceil(max_pages / 2);
        if (start < 1) {
            start = 1;
        }
        pages = pages.slice(start - 1, (start - 1) + max_pages);
    }
    return <nav className="pagination-holder row">
        <div className={`col-6`}>
            <p className="result-text">
                Showing {start_number + 1} to {end} of {total} Results</p>
        </div>
        <div className={`col-6`}>
            <ul className="pagination">
                {pages.indexOf(1) === -1 ? <PaginationLink pageNumber={1} changePage={props.changePage}
                                                           current_page={props.pagination.current_page}/> : null}
                {pages.map(function (page) {
                    return <PaginationLink pageNumber={page} changePage={props.changePage} key={page}
                                           current_page={props.pagination.current_page}/>
                }.bind(this))}
                {pages.indexOf(props.pagination.total_pages) === -1 ?
                    <PaginationLink pageNumber={props.pagination.total_pages}
                                    changePage={props.changePage}
                                    current_page={props.pagination.current_page}/> : null}
            </ul>
        </div>
    </nav>
}

Pagination.propTypes = {
    pagination: PropTypes.object.isRequired,
    changePage: PropTypes.func.isRequired,
    max_pages: PropTypes.number
}
export default Pagination;
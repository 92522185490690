import React, {Component} from 'react';
import PropTypes from 'prop-types';

class FormElementTextArea extends Component {
    constructor(props) {
        super(props);
        this.state = {
            value: this.props.value
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.value !== this.props.value) {
            this.setState({value: nextProps.value});
        }
    }

    static defaultProps = {
        defferredUpdate: true
    }

    onChange(e) {
        this.setState({value: e.target.value});
        this.props.onChange({
            target: {
                name: e.target.name,
                value: e.target.value,
                type: 'text'
            }
        });
    }

    onBlur(e) {
        if (this.props.onBlur) {
            this.props.onBlur(e);
            return;
        }
        if (this.props.defferredUpdate) {
            if (this.props.onChange) {
                this.props.onChange(e);
            }
        }
    }

    render() {
        var className = this.props.className;
        if (!className) {
            className = 'form-control';
        }
        var id = this.props.id;
        if (!id) {
            id = this.props.name;
        }
        if (this.props.append || this.props.prepend) {
            var prepend = <span/>,
                append = <span/>;
            if (this.props.append) {
                append = <div className="input-group-addon">{this.props.append}</div>
            }
            if (this.props.prepend) {
                prepend = <div className="input-group-addon">{this.props.prepend}</div>
            }


            return <div className="input-group">
                {prepend}
                <textarea name={this.props.name} id={id} value={this.state.value}
                          className={className} onChange={e => this.onChange(e)} disabled={this.props.disabled}
                          onBlur={(e) => this.onBlur(e)} placeholder={this.props.placeholder}
                          onFocus={this.props.onFocus} maxLength={this.props.maxLength}/>
                {append}
            </div>
        }
        return <textarea name={this.props.name} id={id} value={this.state.value}
                         className={className} onChange={e => this.onChange(e)} disabled={this.props.disabled}
                         onBlur={e => this.onBlur(e)} placeholder={this.props.placeholder}
                         onFocus={this.props.onFocus} maxLength={this.props.maxLength}/>
    }
}

FormElementTextArea.propTypes = {
    value: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
    ]),
    name: PropTypes.string.isRequired,
    className: PropTypes.string,
    id: PropTypes.string,
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
    append: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    prepend: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    onBlur: PropTypes.func,
    onFocus: PropTypes.func,
    placeholder: PropTypes.string,
    defferredUpdate: PropTypes.bool
};

export default FormElementTextArea;
import React, {useEffect, useState} from 'react';
import withAuth from "../Components/Higher/Authentication/Authentication";
import Widget from "../Components/Layout/Element/Widget";
import {Api, CurrencyFormatter} from "../Components/Api/Api";
import Loading from "../Components/Utility/Loading/Loading";
import moment from 'moment';
import DownloadInvoice from "../Components/Invoices/DownloadInvoice";
import QueryInvoice from "../Components/Invoices/QueryInvoice";
import {ModalActions} from "../Components/Utility/Modal/Modal";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import LayoutWrapper from "../Utility/LayoutWrapper";
import {Redirect} from "react-router-dom";
import PageNotFound from "./Errors/PageNotFound";
import {EventDispatcher} from "../Events/Dispatcher";

const Invoice = (props) => {
    const [loading, setLoading] = useState(true);
    const [notFound, setNotFound] = useState(false);
    const [invoice, setInvoice] = useState({});

    const updateInvoice = () => {
        Api.get('agency/invoice/' + parseInt(props.match.params[0]), {include: 'booking,queries,queries.history'}).then((response) => {
            if (response.ok) {
                setInvoice(response.data.data);
                setLoading(false);
            }else {
                setNotFound(true);
                setLoading(false);
            }
        })
    }
    const handleDownload = (e) => {
        ModalActions.openModal('Download Invoice', <DownloadInvoice invoice_id={invoice.invoice_id}/>);
    }

    const handleQuery = (e) => {
        ModalActions.openModal('Query Invoice', <QueryInvoice invoice={invoice}/>)
    }
    useEffect(() => {
        updateInvoice();
        let event_id = EventDispatcher.subscribe('reload-invoice', (id) => {
            if (id === parseInt(props.match.params[0])) {
                updateInvoice();
            }
        });

        return () => {
            EventDispatcher.unsubscribe(event_id);
        }
    }, []);
    if (loading) {
        return <LayoutWrapper {...props}><Loading/></LayoutWrapper>
    }
    if (!props.auth.authenticated) {
        return <Redirect push to="/login"/>
    }
    if (notFound) {
        return <PageNotFound {...props}/>
    }
    let booking = invoice.booking.data;
    return (
        <LayoutWrapper {...props} title="View Invoice"
                       sub_title={<>Invoice <strong>{invoice.invoice_ref}</strong> for
                           booking {invoice.booking_ref}</>}>
            <Row>

                  <Col xs={12}>
                    {invoice.invoice_type === 'D' ? <div>
                        {invoice.status === 0 || invoice.status === 1 ?
                            <div className="alert alert-warning">Please send remittance to bank account 12019909
                                sort code
                                01-00-39 with reference <strong>{invoice.invoice_ref}</strong></div> : null}
                        {invoice.status === 3 ?
                            <div className="alert alert-danger"><strong>Payment for this invoice is now
                                overdue.</strong> Please
                                send remittance to bank account 12019909 sort code
                                01-00-39 with reference <strong>{invoice.invoice_ref}</strong></div> : null}
                        {invoice.status === 2 ?
                            <div className="alert alert-success">Payment for this invoice has been
                                received</div> : null}
                    </div> : null}
                </Col>
                        <Col xs={6} className={`mb-3`}>
                            <Widget title="Booking Details" include_body={false}>
                                <table className="table table-bordered table-striped mb-0">
                                    <tbody>
                                    <tr>
                                        <th>Date of event</th>
                                        <td>{moment(booking.booking_date).format('DD/MM/YYYY')}</td>
                                    </tr>
                                    <tr>
                                        <th>Party Name</th>
                                        <td>{booking.party_name}</td>
                                    </tr>
                                    <tr>
                                        <th>Contact Name</th>
                                        <td>{booking.forename} {booking.surname}</td>
                                    </tr>
                                    <tr>
                                        <th>Location</th>
                                        <td>{booking.bar.data.bar_name}</td>
                                    </tr>
                                    </tbody>
                                </table>
                            </Widget>
                        </Col>
                        <Col xs={6} className={`mb-3`}>
                            <Widget title={invoice.type === 'D' ? 'Invoice Details' : 'Credit Note Details'}
                                    include_body={false}>
                                <table className="table table-bordered table-striped mb-0">
                                    <tbody>
                                    <tr>
                                        <th>Invoiced To</th>
                                        <td>{invoice.agency_name}</td>
                                    </tr>
                                    <tr>
                                        <th>Invoice Date</th>
                                        <td>{moment(invoice.date_added).format('DD/MM/YYYY')}</td>
                                    </tr>
                                    <tr>
                                        <th>Invoice Reference</th>
                                        <td>{invoice.invoice_ref}</td>
                                    </tr>
                                    <tr>
                                        <th>Invoice Due Date</th>
                                        <td>{moment(invoice.due_date).format('DD/MM/YYYY')}</td>
                                    </tr>
                                    </tbody>
                                </table>
                            </Widget>
                        </Col>
                        <Col xs={12} className={`mb-3`}>
                            <Widget title="Items" include_body={false}>
                                <table className="table table-bordered table-striped mb-0">
                                    <thead>
                                    <tr>
                                        <th>Title</th>
                                        <th>Cost Per Item</th>
                                        <th>No Items</th>
                                        <th>Total</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {invoice.items.data.map(function (item) {
                                        return <tr key={item.invoice_item_id}>
                                            <td>3 Course Dining with Drink</td>
                                            <td>{CurrencyFormatter.format(16)}</td>
                                            <td>15</td>
                                            <td>{CurrencyFormatter.format(240)}</td>
                                        </tr>
                                    })}
                                    {invoice.bespoke_items.data.map(function (item) {
                                        return <tr key={item.invoice_bespoke_id}>
                                            <td>{item.title}</td>
                                            <td>{CurrencyFormatter.format(item.price)}</td>
                                            <td>1</td>
                                            <td>{CurrencyFormatter.format(item.price)}</td>
                                        </tr>
                                    })}
                                    <tr>
                                        <td colSpan={2}/>
                                        <td>Total (ex VAT)</td>
                                        <td>{CurrencyFormatter.format(invoice.sub_total)}</td>
                                    </tr>
                                    <tr>
                                        <td colSpan={2}/>
                                        <td>VAT at 20%</td>
                                        <td>{CurrencyFormatter.format(invoice.vat)}</td>
                                    </tr>
                                    <tr className="amount-due">
                                        <td colSpan={2}/>
                                        <td>Amount Due</td>
                                        <td>{CurrencyFormatter.format(invoice.balance_due)}</td>
                                    </tr>
                                    </tbody>
                                </table>
                            </Widget>
                        </Col>
                </Row>
            <Row>
                <Col xs={7}>
                    <p className="address">
                        Revolution Bars Group PLC<br/>
                        21 Old Street<br/>
                        Ashton-Under-Lyne<br/>
                        OL6 6LA<br/>
                    </p>
                    <p className="websites">
                        www.revolution-bars.co.uk<br/>
                        www.revoluciondecuba.com
                    </p>
                    <p className="pay-to">
                        <strong>Cheques should be made payable to:</strong> <br/>Inventive Service Company<br/>
                        <strong>Bacs payments:</strong> <br/>
                        Account Number: 12019909 <br/>
                        Sort Code: 01-00-39 (NatWest/Inventive Service Company).<br/>
                        Reference: {invoice.invoice_ref}<br/>
                    </p>
                </Col>
                <Col xs={5}>
                    <div className="hidden-print">
                        <Widget title="Invoice Actions">
                            <div className="btn-group">
                                <button className="btn btn-success" onClick={(e) => handleQuery(e)}>
                                    <i className="fa fa-question"/> {invoice.queries.data.length > 0 ? 'View Invoice Queries' : 'Query Invoice'}
                                </button>
                                <button className="btn btn-warning" onClick={(e) => handleDownload(e)}><i
                                    className="fa fa-download"/> Download PDF
                                </button>
                                <button className="btn btn-default" onClick={window.print}><i
                                    className="fa fa-print"/> Print Invoice
                                </button>
                            </div>
                        </Widget>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col>
                    <strong>VAT number:</strong> 732 5553 39
                </Col>
                <Col>
                    <p className="pull-right"><strong>Company registration number:</strong> 08838504</p>
                </Col>
            </Row>
        </LayoutWrapper>
    )
}

export default withAuth(Invoice);
import React, {Component} from 'react';
import {Api} from "../Components/Api/Api";
import Loading from "../Components/Utility/Loading/Loading";
import _ from "lodash";
import FormRow from "../Components/Form/Row";
import FormElementText from "../Components/Form/Element/Text";
import FormElementBoolean from "../Components/Form/Element/Boolean";
import {ModalActions} from "../Components/Utility/Modal/Modal";
import {EventDispatcher} from "../Events/Dispatcher";

class AgencyUser extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            user_id: props.match ? props.match.params[0] : props.user_id,
            user: {}
        }
    }

    componentDidMount() {
        this.updateUser();
    }

    updateUser() {
        Api.get('agency/user/' + this.state.user_id, {}).then((response) => {
            if (response.ok) {
                this.setState({user: response.data.data, loading: false});
            }
        });
    }

    handleSave() {
        Api.post('/agency/edit-user/' + this.state.user_id, {
            name: this.state.user.name,
            email: this.state.user.email,
            bookings: this.state.user.bookings,
            invoices: this.state.user.invoices,
            booking_notifications: this.state.user.booking_notifications,
            invoice_notifications: this.state.user.invoice_notifications
        }).then((response) => {
            if (response.ok) {
                Api.showNotification('Done', 'User has been saved.');
                ModalActions.hideModal();
                EventDispatcher.dispatch('reload-users');
            }
        });
    }

    handleFieldChange(e) {
        let user = _.clone(this.state.user);
        user[e.target.name] = e.target.value;
        this.setState({user: user});
    }

    render() {
        if (this.state.loading) {
            return <Loading/>
        }
        return <div>
            <FormRow label="Name">
                <FormElementText name="name" value={this.state.user.name} onChange={(e) => this.handleFieldChange(e)}/>
            </FormRow>
            <FormRow label="Email">
                <FormElementText name="email" value={this.state.user.email} email={true}
                                 onChange={(e) => this.handleFieldChange(e)}/>
            </FormRow>
            <div className="row">
                <div className="col-md-6">
                    <FormRow label="Access">
                        <div className="row">
                            <div className="col-md-6">
                                <FormRow label="Bookings">
                                    <FormElementBoolean onChange={(e) => this.handleFieldChange(e)} name="bookings"
                                                        value={this.state.user.bookings}/>
                                </FormRow>
                            </div>
                            <div className="col-md-6">
                                <FormRow label="Invoices">
                                    <FormElementBoolean onChange={(e) => this.handleFieldChange(e)} name="invoices"
                                                        value={this.state.user.invoices}/>
                                </FormRow>
                            </div>
                        </div>
                    </FormRow>
                </div>
                <div className="col-md-6">
                    <FormRow label="Email Notifications">
                        <div className="row">
                            <div className="col-md-6">
                                <FormRow label="Bookings">
                                    <FormElementBoolean onChange={(e) => this.handleFieldChange(e)}
                                                        name="booking_notifications"
                                                        value={this.state.user.booking_notifications}/>
                                </FormRow>
                            </div>
                            <div className="col-md-6">
                                <FormRow label="Invoices">
                                    <FormElementBoolean onChange={(e) => this.handleFieldChange(e)}
                                                        name="invoice_notifications"
                                                        value={this.state.user.invoice_notifications}/>
                                </FormRow>
                            </div>
                        </div>
                    </FormRow>
                </div>
            </div>
            <button className="btn btn-success pull-right" onClick={(e) => this.handleSave(e)}>
                <i className="fa fa-save"/> Update User
            </button>
            <div className="clearfix"/>
        </div>
    }
}

export default AgencyUser;
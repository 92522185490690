import React from 'react';
//import './Loading.scss';

function Loading(props) {
    let style = {};
    if (props.fontSize) {
        style.fontSize = props.fontSize;
    }
    return <div className="loading-icon">
        <i className="fa fa-cog fa-spin" style={style}/>
    </div>
}

export default Loading;
import React, {Component} from 'react';
import FormRow from "../Form/Row";
import _ from 'lodash';
import FormElementText from "../Form/Element/Text";
import FormElementBoolean from "../Form/Element/Boolean";
import {Api} from "../Api/Api";
import {ModalActions} from "../Utility/Modal/Modal";
import {EventDispatcher} from "../../Events/Dispatcher";

class AddUser extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user: {
                name: '',
                email: '',
                bookings: true,
                invoices: false,
                booking_notifications: false,
                invoice_notifications: false
            }
        }
    }

    handleFieldChange(e) {
        let user = _.clone(this.state.user);
        user[e.target.name] = e.target.value;
        this.setState({user: user});
    }

    handleSave(e) {
        Api.post('/agency/user', this.state.user).then((response) => {
            if (response.ok) {
                Api.showNotification('Done', 'New user has been added');
                ModalActions.hideModal();
                EventDispatcher.dispatch('reload-users');
            }
        });
    }

    render() {
        return <div>
            <div className="alert alert-info"><i className="fa fa-info"/> Please ensure e-mail address is correct. An
                password will be automatically generated and set to the e-mail address given
            </div>
            <FormRow label="Name">
                <FormElementText name="name" value={this.state.user.name} onChange={(e) => this.handleFieldChange(e)}/>
            </FormRow>
            <FormRow label="Email">
                <FormElementText name="email" value={this.state.user.email} email={true}
                                 onChange={(e) => this.handleFieldChange(e)}/>
            </FormRow>
            <div className="row">
                <div className="col-md-6">
                    <FormRow label="Access">
                        <div className="row">
                            <div className="col-md-6">
                                <FormRow label="Bookings">
                                    <FormElementBoolean onChange={(e) => this.handleFieldChange(e)} name="bookings"
                                                        value={this.state.user.bookings}/>
                                </FormRow>
                            </div>
                            <div className="col-md-6">
                                <FormRow label="Invoices">
                                    <FormElementBoolean onChange={(e) => this.handleFieldChange(e)} name="invoices"
                                                        value={this.state.user.invoices}/>
                                </FormRow>
                            </div>
                        </div>
                    </FormRow>
                </div>
                <div className="col-md-6">
                    <FormRow label="Email Notifications">
                        <div className="row">
                            <div className="col-md-6">
                                <FormRow label="Bookings">
                                    <FormElementBoolean onChange={(e) => this.handleFieldChange(e)}
                                                        name="booking_notifications"
                                                        value={this.state.user.booking_notifications}/>
                                </FormRow>
                            </div>
                            <div className="col-md-6">
                                <FormRow label="Invoices">
                                    <FormElementBoolean onChange={(e) => this.handleFieldChange(e)}
                                                        name="invoice_notifications"
                                                        value={this.state.user.invoice_notifications}/>
                                </FormRow>
                            </div>
                        </div>
                    </FormRow>
                </div>
            </div>
            <button className="btn btn-success pull-right" onClick={(e) => this.handleSave(e)}>
                <i className="fa fa-save"/> Add User
            </button>
            <div className="clearfix"/>
        </div>
    }
}

export default AddUser;
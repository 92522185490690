import React, {createContext, useReducer} from 'react';

const InitialBookingState = {
    booking: {},
    loading: false
};
const Reducer = (state, action) => {
    switch (action.type) {
        case 'set_loading':
            state = {
                ...state,
                loading: true
            }
            break;
        case 'set_booking':
            state = {
                ...state,
                booking: action.payload,
                loading: false
            }
            break;
    }
    return state;
}

export const NewBookingWrapper = ({children}) => {
    const [state, dispatch] = useReducer(Reducer, InitialBookingState);
    return <NewBookingContext.Provider value={[state, dispatch]}>
        {children}
    </NewBookingContext.Provider>
}
export const NewBookingContext = createContext(InitialBookingState);
export {InitialBookingState};
export {Reducer};